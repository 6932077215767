import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Nav from "../../../components/navigation/Nav.js"
import Footer from "../../../components/footer/Footer.js"
import { useParams, useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import { Button,  FormControl, InputLabel, ListItemIcon, MenuItem, Select, TextField } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import CashIcon from '../../../components/icons/cash.png'; 
import CardIcon from '../../../components/icons/card.png'; 
import Bank from '../../../components/icons/bank.png';
import axios from 'axios'; 
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import robotoFont from '../../rcai/pay/roboto.js';
import 'jspdf-autotable';
import { Link } from "react-router-dom";

const getUserIpAddress = async () => { 
    try { 
      const response = await axios.get('https://api.ipify.org/?format=json'); 
      const { ip } = response.data; 
      return ip; 
    } catch (error) { 
      console.error(error); 
      return ''; 
    } 
  }; 


firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

export default function GreenCardPay() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [idnx, setIdnx] = useState("");    
    const [vehicleRegistrationCertificateNumber, setVehicleRegistrationCertificateNumber] = useState("");
    const [personIsJuridical, setPersonIsJuridical] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [price, setPrice] = useState("");
    const [deliveryPrice, setDeliveryPrice] = useState("");
    const [amount, setAmount] = useState("");
    const [period, setPeriod] = useState("");
    const [name, setName] = useState("");
    const [zone, setZone] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("online");
    const [email, setEmail] = useState("");
    const [adress, setAdress] = useState("");
    const [product_type, setProductType] = useState("");
    const [ownership, setOwnership] = useState("");
    const [inssuranceCompany, setInssuranceCompany] = useState("");
    const [startDay, setStartDay] = useState("");
    const startDate = dayjs(startDay, "DD.MM.YYYY");
    const [endDay, setEndDay] = useState("");
    const [paymentMethod, setPaymentMethod] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [bankLoading, setBankLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [cashPaymentMethod, setCashPaymentMethod] = useState(false);
    const [cashInTerminalPaymentMethod, setCashInTerminalPaymentMethod] = useState(false);
    const [bankCardPaymentMethod, setBankCardPaymentMethod] = useState(false);
    const [description, setDescription] = useState(' Front test transaction'); 
    const [language, setLanguage] = useState('ro'); 
    const [currency, setCurrency] = useState('498'); 
    const [status, setStatus] = useState(''); 
    const [transactionStatus, setTransactionStatus] = useState('');



  useEffect(() => {
    const fetchData = async () => {
      try {
        const doc = await db.collection("rcarequests2").doc(id).get();
        setData(doc.data());
        setIdnx(doc.data().idnx);
        setVehicleRegistrationCertificateNumber(doc.data().vehicleRegistrationCertificateNumber);
        setPersonIsJuridical(doc.data().personIsJuridical);
        setCompanyName(doc.data().companyName);
        setPeriod(doc.data().period);
        setPrice(doc.data().price);
        setDeliveryPrice(doc.data().deliveryPrice);
        setAmount(doc.data().amount);
        setName(doc.data().name);
        setZone(doc.data().zone);
        setPhoneNumber(doc.data().phoneNumber);
        setProductType(doc.data().product_type);
        setDeliveryMethod(doc.data().deliveryMethod);
        setEmail(doc.data().email);
        setAdress(doc.data().adress);
        setOwnership(doc.data().ownership);
        setInssuranceCompany(doc.data().inssuranceCompany);
        setStartDay(doc.data().startDay);
        setTransactionStatus(doc.data().transactionStatus);
        setLoading(false);
      } catch (error) {
        console.log(error);
        navigate('/');
      }
    };
    fetchData();
  }, [id]);

  const calculateEndDay = () => {
    if (startDay !== "" && period !== "") {
      let endDate = dayjs(startDay);
      if (period === "15") endDate = startDate.add(15, 'day');
      else if (period === "30") endDate = startDate.add(1, 'month');
      else if (period === "60") endDate = startDate.add(2, 'month');
      else if (period === "90") endDate = startDate.add(3, 'month');
      else if (period === "120") endDate = startDate.add(4, 'month');
      else if (period === "150") endDate = startDate.add(5, 'month');
      else if (period === "180") endDate = startDate.add(6, 'month');
      else if (period === "210") endDate = startDate.add(7, 'month');
      else if (period === "240") endDate = startDate.add(8, 'month');
      else if (period === "270") endDate = startDate.add(9, 'month');
      else if (period === "300") endDate = startDate.add(10, 'month');
      else if (period === "330") endDate = startDate.add(11, 'month');
      else if (period === "360") endDate = startDate.add(12, 'month');
      setEndDay(endDate.subtract(1, 'day').format('DD.MM.YYYY'));
    }
  };

useEffect(() => {
  calculateEndDay();
}, [startDay, period]);

  const handlePaymentChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleConfirmPaymentMethod = async (paymentMethod) => {
    try {
      await db.collection("rcarequests2").doc(id).update({
        paymentMethod,
      });
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  };    


  function gtag_report_conversion(url) {
    var callback = function () {
      if (typeof(url) !== 'undefined') {
        window.location = url;
      }
    };
    window.gtag('event', 'conversion', {
      'send_to': 'AW-994026003/rNJaCOTlwLMYEJPE_tkD',
      'event_callback': callback
    });
    return false;
  }

  const handleCardPay = async (e) => { 
    e.preventDefault(); 
    gtag_report_conversion();
    setBankLoading(true);
    const clientIpAddress = await getUserIpAddress(); 
   
    // Convert the decimal amount to an integer without decimal places 
    const amountToSend = Math.round(amount * 100); 
   
    try { 
      const response = await axios.post('https://asig24.md/create-transaction', { 
        description: id, 
        language: language, 
        currency: currency, 
        amount: amountToSend, 
        clientIpAddress, 
      }); 
   
      const { transactionId } = response.data; 
   
      setStatus('Transaction created. Redirecting to the payment page...'); 
      window.location.href = `https://maib.ecommerce.md:443/ecomm01/ClientHandler?trans_id=${transactionId}`; 
    } catch (error) { 
      setStatus('Error creating transaction. Please try again.'); 
    } 
  };

  function addCustomFont(doc) {
    const fontName = 'RobotoFont';
    const fontStyle = 'normal';
  
    doc.addFileToVFS(`${fontName}.ttf`, robotoFont);
    doc.addFont(`${fontName}.ttf`, fontName, fontStyle);
    doc.setFont(fontName, fontStyle);
  }

  async function addLogo(doc) {
    const logoUrl = "https://asig24.md/static/media/logo192.802850e3d14e272dc101.png"; // Replace this with your logo URL
    const img = new Image();
    img.crossOrigin = "Anonymous"; // This may be required if you are loading the image from a different domain
  
    // Load the image and add it to the PDF
    return new Promise((resolve, reject) => {
      img.onload = () => {
        const desiredHeight = 15; // Set the desired height here
        const aspectRatio = img.width / img.height;
        const calculatedWidth = desiredHeight * aspectRatio;
  
        doc.addImage(img, "PNG", 110, 10, calculatedWidth, desiredHeight);
        resolve();
      };
  
      img.onerror = () => {
        reject("Error loading image");
      };
  
      img.src = logoUrl;
    });
  }
  


  async function generatePDF() {
    const invoiceDetails = {
      clientName: name,
      idnx,
      date: dayjs().format('DD.MM.YYYY'),
      amount,
      startDate: startDay,
      endDate: endDay,
    };
  
    const doc = new jsPDF();
    addCustomFont(doc);

// Get the current date
const today = new Date();

// Add 7 days to the current date
const futureDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);

// Format the future date
const formattedFutureDate = futureDate.toLocaleDateString();
const now = new Date();
const date = now.toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).replace(/\//g, '.');
const time = now.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit' });



const data = [
    ['DESCRIERE', ' '],
    [`Prima de asigurare  ${product_type} (${startDay} - ${endDay})\nServicii livrare`, `${price}\n${deliveryPrice}`],
    [{content: 'TOTAL:', colSpan: 1, styles: {halign: 'right'}}, `${amount} MDL`],
  ];
    
    try {
        await addLogo(doc); 

        doc.setFont("RobotoFont");
  
        doc.setFontSize(24);
        doc.setFontSize(10);
        doc.text("PRIMGROUP-ASIG S.R.L.", 110, 30);
        doc.text("Broker de asigurare - reasigurare", 110, 35);
        doc.text("Adresa: MD-2005, mun. Chișinău, str. Miron Costin 14", 110, 40);
        doc.text("IDNO: 1012600036968", 110, 45);
        doc.text("BC Moldova Agroindbank SA", 110, 50);
        doc.text("IBAN: MD54AG000000022515435328", 110, 55);
        doc.text("SWIFT: AGRNMD2X437", 110, 60);
        doc.text("Tel: 0 61 111 101", 110, 65);
        doc.text("Email: support@asig24.md", 110, 70);

      doc.setFillColor(200, 200, 200);
      doc.rect(15, 75, 180, 20, 'F');
      
  
      // Add the order number
      doc.setFontSize(16);
      doc.text(`Factura nr. ${id}`, 16, 82);
      doc.setFontSize(10);
      doc.text(`Data emiterii: ${new Date().toLocaleDateString()}`, 16, 87);
      doc.text(`Data scadentă: ${formattedFutureDate}`, 16, 92);


      // Add the client details
      doc.setFontSize(10);
      doc.text("Facturat către:", 14, 105);
      doc.text(`${personIsJuridical ? companyName : name}`, 14, 110);
      doc.text(`Cod fiscal: ${idnx}`, 14, 115);

      // Add the table to the PDF
    doc.autoTable({
    head: [data.shift()], // Use the first row as the table header
    body: data, // Use the remaining rows as the table body
    startY: 125, // Set the starting Y position of the table
    tableWidth: 'auto', // Auto-adjust the table width to fit the page
  theme: 'grid',
  styles: {
    cellPadding: 1,
    minCellHeight: 5,
  },
  headStyles: {
    fillColor: [200, 200, 200], // Set header cell background color
    textColor: [0, 0, 0],
    lineWidth: 0.1,
  },
  bodyStyles: {
    fillColor: [200, 200, 200], // Set row background color
    textColor: [0, 0, 0],
  },
  alternateRowStyles: {
    fillColor: [255, 255, 255],
    textColor: [0, 0, 0],
  },
  columnStyles: {
    0: {
      halign: 'left', // Set horizontal alignment for the first column
    },
    1: {
      cellWidth: 30, // Set the second column width
      halign: 'right', // Set horizontal alignment for the second column
    },
  },
  });
  
      // Add the date, amount, start date, and end date
      doc.text(`Document PDF generat la: ${date} ${time}`, 14, 160);

      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  
      if (isMobile) {
        const blob = new Blob([doc.output("arraybuffer")], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = `Invoice ${id}.pdf`;
        link.click();
      } else {
        const blob = new Blob([doc.output("arraybuffer")], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);}
    } catch (error) {
        console.error("Error adding logo:", error);
        return;
      }
    }
  
return (
    <div>
      <Helmet>
            <title>{id}</title>
            <meta name="description" content="Calculează prețul și cumpără Cartea Verde online. Rapid și sigur cu Asig24.md obții polița Cartea Verde în câteva minute." />
            <meta name="keywords" content="Cartea Verde, Cartea Verde online, asigurări, calculator Cartea Verde, asigurare auto, Cartea Verde Moldova, asigurări online" />
            <meta name="author" content="Asig24.md" />
            <meta property="og:title" content="Cartea Verde online" />
            <meta property="og:description" content="Calculează prețul și cumpără Cartea Verde online. Rapid și sigur cu Asig24.md obții polița Cartea Verde în câteva minute." />
            <meta property="og:url" content="https://www.asig24.md/GreenCard" />
            <meta property="og:type" content="website"></meta>
            <link rel="canonical" href="https://www.asig24.md/GreenCard" />
        </Helmet>        
        <Nav />
            <> {loading ? ( <div align="center" className="loading"><CircularProgress /></div> ) : ( <>

        <div className="parent">
            <div className="forma">


                <div className="orderdetails">
                    <div align="center">
                    <b>Vă mulțumim că ați ales Asig24.md
                        <br></br>
                        În scurt timp veți fi contactat de către operatorul nostru.</b><br></br><br></br>
                        <h1>Comanda nr. {id}</h1>
                        {transactionStatus !== "OK" && ( <p>Verificați detaliile comenzii dvs. și selectați metoda de plată.</p> )} 
						            {transactionStatus === "OK" && ( <div><CheckCircleOutlineIcon className='transactionStatusIcon' sx={{ fontSize: 40 }} /><h2>Achitat</h2></div> )}
                        {personIsJuridical === "true" && ( <div align="center"><Link component="button" onClick={generatePDF} style={{ cursor: 'pointer' }}>Descarcă Invoice-Proforma</Link></div>)}
                        <br></br><br></br>
                    </div>

                    
                    {personIsJuridical === "true" && ( <div className="orderdetailsrow">Asigurat: <b>{companyName} - {idnx}</b></div> )}
                    {personIsJuridical === "true" && ( <div className="orderdetailsrow">Persoană de contact: <b>{name}</b></div> )}
                    {personIsJuridical === "false" && ( <div className="orderdetailsrow">Asigurat: <b>{name} - {idnx} </b></div> )}
                    <div className="orderdetailsrow">Certificat de înmatriculare vehicul: <b>{vehicleRegistrationCertificateNumber}</b></div>
                    {zone === "UE" && ( <div className="orderdetailsrow">Zona de deplasare: <b>UE - toate țările sistemului "CARTEA VERDE"</b></div> )}
                    {zone === "UkrBelRus" && ( <div className="orderdetailsrow">Zona de deplasare: <b>Ucraina, Belarus, Rusia</b></div> )}
                    {zone === "UkrBel" && ( <div className="orderdetailsrow">Zona de deplasare: <b>Ucraina, Belarus</b></div> )}
                    <div className="orderdetailsrow">Perioada asigurată: <b>{startDay} - {endDay}</b> {period === "15" && ('(15 zile)')}
                        {period === "30" && ('(1 lună)')}
                        {period === "60" && ('(2 luni)')}
                        {period === "90" && ('(3 luni)')}
                        {period === "120" && ('(4 luni)')}
                        {period === "150" && ('(5 luni)')}
                        {period === "180" && ('(6 luni)')}
                        {period === "210" && ('(7 luni)')}
                        {period === "240" && ('(8 luni)')}
                        {period === "270" && ('(9 luni)')}
                        {period === "300" && ('(10 luni)')}
                        {period === "330" && ('(11 luni)')}
                        {period === "360" && ('(12 luni)')}
                    </div>                    
                    <div className="orderdetailsrow">Compania de asigurare: <b>{inssuranceCompany}</b></div>
                    <div className="orderdetailsrow">Drept de posesie asupra vehiculului: {ownership === "owner" && (<b>Proprietar</b>)}{ownership === "leasing" && (<b>Leasing</b>)}{ownership === "others" && (<b>Procură și alte titluri</b>)}</div>
                    <div className="orderdetailsrow">Telefon de contact: <b>{phoneNumber}</b></div>
                    {deliveryMethod === "online" && email !== "" && ( <div className="orderdetailsrow">Email: <b>{email}</b></div> )}
                    {deliveryMethod === "deliveryOnAdress" && ( <div className="orderdetailsrow">Adresa de livrare: <b>{adress}</b></div> )}
                    {deliveryMethod === "fromOffice" && ( <div className="orderdetailsrow">Adresa de livrare: <b>or. Chișinău, str. Miron Costin 14</b></div> )}
                    <Divider  />
                    <br></br>
                    <div className="orderdetailsrow">Prețul asigurării: <b>{price} Lei</b></div>
                    {deliveryPrice > 0 && (<div className="orderdetailsrow">Prețul asigurării: <b>{deliveryPrice} Lei</b></div>)}
                    <Divider textAlign="right"><div className="orderdetailsrow"><b>TOTAL: {amount} LEI</b></div></Divider>
                </div>


                {transactionStatus !== "OK" && (
				<div>
                    <Accordion className="paymentMethod" expanded={expanded === 'panel1'} onChange={handlePaymentChange('panel1')}>
                        <AccordionSummary
                            onClick={() => {
                                setCashPaymentMethod(false);
                                setCashInTerminalPaymentMethod(false);
                                setBankCardPaymentMethod(true);
                                handleConfirmPaymentMethod("BankCard");
                            }}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            {personIsJuridical === "false" && (<img src={CardIcon} alt="Card bancar" height="65" /> )}
                            {personIsJuridical === "true" && (<img src={Bank} alt="transfer bancar" height="65" /> )}
                            </Typography>
                            <div  className="paymentMethod">
                            {personIsJuridical === "false" && ( <div align="center"><b>Plată cu cardul bancar</b></div> )}
                            {personIsJuridical === "true" && ( <div align="center"><b>Plată prin transfer bancar</b></div> )}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider  />
                                <Typography>    
                                {personIsJuridical === "false" && ( 'Efectuați plăți cu cardul bancar de tip Visa, MasterCard, Maestro în siguranță maximă. Plățile sunt procesate de către MAIB.' )}
                                </Typography>
								{transactionStatus !== "OK" && (
                            <div className="paybutton" align="center"> 
                                {personIsJuridical === "false" && (
                                  <div>
                                    <p style={{ fontSize: '13px' }}>
                                      Prin apăsarea butonului "Plătește" confirm că am citit, am înțeles și sunt de acord cu <a href="https://asig24.md/Termsofservices">Termenii și Condițiile</a> și <a href="https://asig24.md/Greencardterms">Condițiile de asigurare</a>
                                    </p><br></br>
                                    <Button onClick={handleCardPay} variant="contained" type="submit">Plătește</Button>
                                  </div>
                                )}                                
                                {personIsJuridical === "true" && (<Button onClick={generatePDF} variant="contained" type="submit">Descarcă Invoice</Button> )}
                            </div>)}
                            {bankLoading && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <CircularProgress />
                        </div>
                    )}
							
                        </AccordionDetails>
                    </Accordion>

                    
                    <Typography component="div" style={{ height: '1rem' }} />

                    <Accordion className="paymentMethod" expanded={expanded === 'panel3'} onChange={handlePaymentChange('panel3')}>
                        <AccordionSummary
                            onClick={() => {
                                setCashPaymentMethod(false);
                                setCashInTerminalPaymentMethod(false);
                                setBankCardPaymentMethod(true);
                                handleConfirmPaymentMethod("Cash");
                            }}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            <img src={CashIcon} alt="Card bancar" height="65" />
                            </Typography>
                            <div  className="paymentMethod">
                                <b>Plată în numerar</b>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider  />
                            <Typography>    
                                Puteți selecta metoda de plată în numerar dacă optați pentru ridicarea asigurării dvs. din oficiu ori achitați la livrare.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div> 
				)}                            
                                 
                        
            </div>
        </div>
        <Footer /> 
        </> )} </>
    </div>

    
  );
}