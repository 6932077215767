import React from "react";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"

export default function RCATerms() {
  return (
    <div>
      <Nav />
      <div className="parent">
                <div className="condText">
                <h1>Condiţii de asigurare obligatorie de răspundere civilă auto internă</h1>
                    <br></br>

                    <div>
<p><strong>I. OBIECTUL ASIGURĂRII</strong></p>
<p>Asigurătorul, &icirc;n baza prezentului contract, &icirc;ncheiat &icirc;n conformitate cu prevederile Legii nr.414-XVI din 22.12.2006 &ldquo;Cu privire la asigurarea obligatorie de răspundere civilă pentru pagube produse de autovehicule&rdquo;, oferă Asiguratului protecţie din asigurare pentru cazurile de răspundere civilă, ca urmare a pagubelor produse prin accidente de autovehicule.</p>
</div>
<div>
<p>&nbsp;</p>
<p><strong>II. OBIECTUL CONTRACTULUI</strong></p>
<p>Obiectul prezentului contract &icirc;l constituie asigurarea obligatorie a răspunderii civile auto a Asiguratului, care are calitatea de posesor / utilizator al autovehiculului(elor) indicat(e) &icirc;n cererea, care a servit temei pentru &icirc;ncheierea prezentului contract, pentru daunele cauzate vieţii, sănătăţii şi proprietăţii persoanelor terţe &icirc;n rezultatul accidentelor de circulaţie.</p>
</div>
<div>
<p>&nbsp;</p>
<p><strong>III. TIPUL CONTRACTULUI</strong></p>
<p>Prezentul contract este &icirc;ncheiat pe baza cererii Asiguratului, ce constituie parte integrantă a acestuia şi se extinde asupra unui număr:</p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>a) limitat de persoane conform tabelei:</li>
</ul>
</ul>
<table class="text-xxs sm:border-none border text-center w-full table-auto">
<thead>
<tr>
<th class="sm:border-none border-r">Numele şi prenumele</th>
<th class="sm:border-none border-r">Codul personal</th>
<th class="sm:border-none border-r">Data naşterii</th>
<th class="sm:border-none border-r">Domiciliul (localitatea, strada, nr. blocului, nr. apartamentului)</th>
<th class="sm:border-none border-r">Permisul de conducere (nr. şi data)</th>
</tr>
</thead>
</table>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>b) nelimitat de persoane (de bifat)</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>IV. RISCURI ASIGURATE</strong></p>
<p>Asigurătorul de răspundere civilă auto acordă despăgubiri pentru prejudiciile de care Asiguratul răspunde, &icirc;n baza legii, faţă de terţele persoane păgubite prin accident de autovehicul, precum şi pentru cheltuielile legate de cazul asigurat şi suportate de Asigurat sau persoana păgubită &icirc;n procesul civil.</p>
</div>
<div>
<p>&nbsp;</p>
<p><strong>V. &Icirc;nceputul și durata asigurării</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>5.1. Contractul de asigurare se &icirc;ncheie pentru o perioada de 12 luni de la ______ p&icirc;nă la ________</li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>5.1.1.Contractul de asigurare se &icirc;ncheie pentru perioade mai mici de 12 luni pentru autovehicule speciale echipate de uzina producătoare sau reutilate legal cu dispozitive şi instalaţii pentru lucrări sezoniere agricole, de deszăpezire sau alte lucrări similare ____________________________</li>
</ul>
<li>5.2. Contractul de asigurare &icirc;ncheiat &icirc;n condiţiile pct.5.1.1 se &icirc;ncheie pentru perioada de _____________ de la ______________ p&icirc;nă la ________________</li>
<li>5.3. Perioada de asigurare &icirc;ncepe să curgă din momentul menţionat &icirc;n poliţa de asigurare şi &icirc;ncetează la ora 24 a ultimei zile din termenul convenit pentru asigurare.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>VI. SUMA ASIGURATĂ. PRIMA DE ASIGURARE. FORMA ŞI TERMENELE DE PLATĂ</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>6.1. Suma asigurată reprezintă limita răspunderii Asigurătorului, care nu poate depăşi limitele stabilite la art.14 alin.(2) din Legea nr.414-XVI din 22.12.2006 &bdquo;Cu privire la asigurarea obligatorie de răspundere civilă pentru pagube produse de autovehicule&rdquo;.</li>
<li>6.2. Prima de asigurare reprezintă suma pe care Asiguratul se obligă să o plătească Asigurătorului pentru preluarea de către acesta a riscului &icirc;n asigurare. Conform prezentului contract, prima de asigurare constituie_______/lei, care se achită integral la data de ________</li>
<li>6.3. La data achitării primei de asigurare, Asigurătorul va elibera Asiguratului poliţa de asigurare RCA, care este anexă la prezentul contract. Poliţa de asigurare se eliberează cu condiţia achitării integrale a primei de asigurare.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>VII. PAGUBE CARE NU SE REPARĂ DE ASIGURĂTOR</strong></p>
<p>&Icirc;n baza prezentului contract Asigurătorul de răspundere civilă auto nu acordă despăgubiri pentru:</p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>- prejudiciile produse bunurilor aparţin&icirc;nd persoanelor fizice sau persoanelor juridice asigurate de răspundere civilă auto dacă au fost provocate de un autovehicul aflat &icirc;n posesia lor;</li>
<li>- partea de prejudiciu care depăşeşte limitele maxime ale despăgubirilor de asigurare prevăzute la art.14 alin.(2) din Legea nr.414-XVI &bdquo;Cu privire la asigurarea obligatorie de răspundere civilă pentru pagube produse de autovehicule" din 22.12.2006, produs &icirc;n unul şi acelaşi accident, indiferent de numărul persoanelor păgubite şi de numărul persoanelor răspunzătoare de producerea pagubei pentru daunele produse pe teritoriul Republicii Moldova;</li>
<li>- pagube pricinuite de dispariţia sau distrugerea h&icirc;rtiilor de valoare, banilor, pietrelor preţioase, obiectelor din metale preţioase şi pietre preţioase, obiectelor de artă şi altor obiecte ale proprietăţii intelectuale;</li>
<li>- sumele pe care utilizatorul răspunzător de producerea pagubei este obligat să le plătească posesorului, care i-a &icirc;ncredinţat autovehiculul asigurat, pentru avarierea ori distrugerea lui;</li>
<li>- prejudiciile produse ca urmare a transportului de produse periculoase (radioactive, inflamabile, explozive) care au determinat sau au agravat producerea pagubei;</li>
<li>- pretenţiile ca urmare a diminuării valorii bunurilor după reparaţie.</li>
<li>- pagubele produse la locul de muncă de dispozitivele sau instalaţiile montate pe autovehicul, utilizate ca utilaje sau instalaţii de lucru;</li>
<li>- pagubele produse prin accidente survenite &icirc;n timpul operaţiunilor de &icirc;ncărcare şi descărcare, aceste accidente constituind riscuri ale activităţii profesionale;</li>
<li>- prejudiciul legat de poluarea mediului din cauza unui accident;</li>
<li>- pagubele produse prin exploatarea autovehiculelor &icirc;n cadrul &icirc;ntrecerilor şi antrenamentelor sportive, organizate &icirc;n locuri special prevăzute pentru acestea;</li>
<li>- pagubele produse &icirc;n situaţiile &icirc;n care răspunderea asigurătorului nu &icirc;ncepuse ori &icirc;ncetase;</li>
<li>- sumele de recuperare a pagubei morale rezultate din accident.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>VIII. &Icirc;NCHEIEREA, MODIFICAREA, REZILIEREA ŞI &Icirc;NCETAREA CONTRACTULUI</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>8.1. &Icirc;ncheierea contractului de asigurare</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>8.1.1. Contractul de asigurare se &icirc;ncheie &icirc;n formă scrisă &icirc;n baza cererii de asigurare completată de către Asigurat.</li>
<li>8.1.2. Contractul de asigurare produce efecte din data indicată &icirc;n poliţa de asigurare.</li>
</ul>
</li>
<li>8.2. Modificarea contractului de asigurare</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>8.2.1. Modificarea contractului de asigurare se efectuează &icirc;n orice timp &icirc;n interiorul termenului său de valabilitate şi nu poate contravine prezentului contract sau legislaţiei &icirc;n vigoare. Modificarea contractului de asigurare se perfectează &icirc;n formă scrisă prin &icirc;ntocmirea unor acorduri sau addendumuri adiţionale, care, ulterior semnării de către părţi, fac parte integrantă din contractul de asigurare.</li>
<li>8.2.2. Asiguratul este &icirc;n drept să solicite modificarea contractului de asigurare &icirc;n partea ce ţine de numărul persoanelor admise să utilizeze autovehiculele. &Icirc;n acest caz, Asigurătorul va ajusta prima de asigurare &icirc;n funcţie de factorul respectiv de risc.</li>
<li>8.2.3. &Icirc;n cazul &icirc;nstrăinării autovehiculului, dob&icirc;nditorul are opţiunea de a modifica contractul de asigurare sau de a-l menţine dacă riscul său este asigurat &icirc;n temeiul acestui contract.</li>
</ul>
</li>
<li>8.3. Rezilierea contractului de asigurare</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>8.3.1. Contractul de asigurare poate fi reziliat de către părţi prin &icirc;ntocmirea &icirc;n scris a unui acord de reziliere, precum şi &icirc;n baza hotăr&icirc;rii instanţei de judecată rămasă definitivă şi irevocabilă. Drept temei de reziliere a contractului de asigurare se consideră:</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>a) nerespectarea sau respectarea necorespunzătoare a obligaţiilor contractuale;</li>
<li>b) depunerea declaraţiei de reziliere de către una dintre părţi;</li>
<li>c) omiterea menţionării de către Asigurat, la &icirc;ncheierea şi/sau pe parcursul contractului, a unor &icirc;mprejurări importante pentru determinarea de către Asigurător a gradului de risc;</li>
<li>d) efectuarea menţiunilor inexacte &icirc;n privinţa unor &icirc;mprejurări importante;</li>
</ul>
</li>
<li>8.3.2. Rezilierea contractului de asigurare &icirc;n temeiul pct.8.3.1 lit.c) şi d) este exclusă &icirc;n situaţia &icirc;n care Asigurătorul cunoştea &icirc;mprejurarea nemenţionată sau că, menţiunea este făcută inexact fără vina Asiguratului.</li>
<li>8.3.3. Părţile pot solicita rezilierea contractului doar respect&icirc;nd un termen de preaviz de o lună p&icirc;nă la data propusă spre reziliere.</li>
<li>8.3.4. Rezilierea contractului de asigurare presupune drept consecinţă &icirc;ncetarea protecţiei din asigurare de la data rezilierii şi restituirea de către Asigurător a primelor de asigurare pentru zilele complete de p&icirc;nă la expirarea contractului, cu reţinerea cheltuielilor efective de gestiune, dar nu mai mult de 20% din prima de asigurare calculată spre restituire.</li>
</ul>
</li>
<li>8.4. Asigurătorul este eliberat de obligaţia restituirii primelor de asigurare &icirc;n condiţiile pct.8.3.4 &icirc;n cazul &icirc;n care p&icirc;nă la reziliere a efectuat deja plăţi de despăgubire.</li>
<li>8.5. Contractul de asigurare &icirc;ncetează de fapt şi de drept &icirc;n cazurile prevăzute &icirc;n art.10 alin.(1) din Legea nr.414-XVI &bdquo;Cu privire la asigurarea obligatorie de răspundere civilă pentru pagube produse de autovehicule";</li>
<li>8.6. Invocarea şi declararea nulităţii contractului de asigurare, precum şi consecinţele declarării nulităţii, este reglementată prin Capitolul III, Titlul III, Cartea &Icirc;nt&icirc;i din Codul Civil (art.216-233) al Republicii Moldova.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>IX. OBLIGAŢIILE PĂRŢILOR</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>9.1. Obligaţiile Asiguratului</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>9.1.1. La producerea accidentului de autovehicul, Asiguratul sau utilizatorul de autovehicul este obligat:</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>a) să &icirc;ntreprindă toate acţiunile posibile pentru diminuarea pagubelor produse;</li>
<li>b) să notifice organele poliţiei sau alte organe de drept despre accidentul de autovehicul imediat după producerea lui, cer&icirc;nd &icirc;ntocmirea actelor referitor la cauzele şi circumstanţele producerii accidentului, precum şi la consecinţele lui;</li>
<li>c) &icirc;n 48 de ore din momentul producerii accidentului, să &icirc;nştiinţeze asigurătorul care a emis poliţa de asigurare obligatorie de răspundere civilă auto; participanţii la accident care deţin poliţe de asigurare, indiferent de rezultatele constatării vinovatului, anunţă asigurătorii respectivi şi s&icirc;nt obligaţi să nu &icirc;ntreprindă de sine stătător măsuri de reparare a pagubelor;</li>
<li>d) să notifice participanţii la accident, păgubitul sau apropiaţii lui despre faptul că posedă poliţă de asigurare şi să pună la dispoziţia acestora informaţiile necesare despre asigurător.</li>
<li>e) să permită Asigurătorului să verifice starea tehnică a autovehiculului pentru care se solicită &icirc;ncheierea contractului de asigurare obligatorie de răspundere civilă;</li>
<li>f) să prezinte Asigurătorului documentele şi dovezile necesare pentru stabilirea cauzelor şi circumstanţelor accidentului, mărimii daunei, precum şi pentru recunoaşterea dreptului la restituire;</li>
</ul>
</li>
<li>9.1.2. Nerespectarea de către Asigurat sau de către utilizatorul autovehiculului a prevederilor pct.9.1.1 lit.c) nu &icirc;l scuteşte pe Asigurător de obligaţia de a plăti despăgubirea, acesta fiind &icirc;nsă &icirc;n drept să &icirc;nainteze &icirc;mpotriva Asiguratului acţiune de regres privind cheltuielile suplimentare aferente ne&icirc;ndeplinirii prevederilor menţionate.</li>
<li>9.1.3. &Icirc;n caz de leziuni corporale grave sau deces al asiguratului sau utilizatorului de autovehicul răspunzător de producerea accidentului, persoana păgubită primeşte informaţia despre asigurătorul persoanei vinovate la Biroul Naţional al Asigurătorilor de Autovehicule.</li>
</ul>
</li>
<li>9.2. Obligaţiile Asigurătorului</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>9.2.1. După ce a luat cunoştinţă de producerea accidentului, Asigurătorul este obligat:</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>a) să deschidă dosar de daune la data informării sale despre producerea cazului asigurat;</li>
<li>b) să solicite organelor şi instituţiilor competente (poliţia, unităţile de pompieri, procuratura sau instituţiile medicale) informaţii despre accident şi consecinţele lui;</li>
<li>c) să examineze bunurile avariate şi să &icirc;ncheie, &icirc;n termen de 5 zile lucrătoare din data primirii informaţiei despre producerea accidentului de autovehicul, un proces-verbal de constatare a pagubelor;</li>
<li>d) să &icirc;nchidă dosarul de daune privind pagubele materiale &icirc;n cel mult 15 zile calendaristice din data depunerii de către păgubit a ultimului document necesar finalizării dosarului;</li>
<li>e) să &icirc;nchidă dosarul de daune privind vătămările corporale sau decesul &icirc;n cel mult 10 zile calendaristice din data depunerii de către păgubit a ultimului document de confirmare a pagubelor;</li>
<li>f) să achite despăgubirea de asigurare &icirc;n termen de 10 zile calendaristice de la data finalizării dosarului de daună.</li>
</ul>
</li>
<li>9.2.3. Asigurătorul este obligat să soluţioneze cererea şi să achite despăgubirea de asigurare &icirc;n cel mult 3 luni din data informării despre cazul asigurat.</li>
</ul>
</li>
<li>9.3. Drepturile şi obligaţiile persoanei păgubite</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>9.3.1. Drepturile persoanei păgubite prin accident produs pe teritoriul Republicii Moldova de autovehiculul aflat &icirc;n posesiunea Asiguratului se exercită faţă de Asigurătorul de răspundere civilă auto direct, la sediul acestuia, sau prin reprezentantul de despăgubiri, &icirc;n limitele răspunderii asigurătorului prevăzute &icirc;n prezenta lege.</li>
<li>9.3.2. Persoana păgubită poate să adreseze cerere de despăgubire direct asigurătorului de răspundere civilă auto sau reprezentantului de despăgubiri desemnat de acesta, indiferent de faptul că Asiguratul a executat sau nu obligaţia de &icirc;nştiinţare prevăzută la pct.9.1.1 lit.c).</li>
<li>9.3.3. Asigurătorul nu este &icirc;n drept să refuze examinarea şi soluţionarea cererii de despăgubire depuse &icirc;n condiţiile pct.9.3.2 din prezentul contract.</li>
</ul>
</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>X. DISPOZIŢII FINALE</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>10.1. Toate litigiile care derivă din prezentul contract de asigurare se soluţionează &icirc;n procedură extrajudiciară prin &icirc;ncheierea tranzacţiilor de &icirc;mpăcare sau &icirc;n procedură judiciară prin decizia instanţei de judecată sesizată, după caz.</li>
<li>10.2. Limita despăgubirilor de asigurare, modul de constatare şi evaluare a pagubelor şi plată a despăgubirilor, s&icirc;nt cele prevăzute &icirc;n Legea nr.414-XVI din 22.12.2006 &bdquo;Cu privire la asigurarea obligatorie de răspundere civilă pentru pagube produse de autovehicule&rdquo;, prevederi ce fac parte integrantă din prezentul contract de asigurare.</li>
<li>10.3. Dovada achitării primelor şi a valabilităţii contractului se face cu poliţa/poliţele de asigurare RCA şi cu documentele de plată.</li>
<li>10.4. Prezentul contract este &icirc;ncheiat &icirc;n limba de stat şi/sau &icirc;n limba rusă, semnat &icirc;n 2 exemplare, fiecare din ele av&icirc;nd aceeaşi forţă juridică din care unul a fost &icirc;nm&icirc;nat Asiguratului.</li>
<li>10.5. Am luat la cunoştinţă de dispoziţiile legale privind asigurarea obligatorie de răspundere civilă pentru pagube produse prin accidente de autovehicule.</li>
</ul>
</div>
                    

</div>
            </div>


      <Footer /> 
    </div>
  );
}