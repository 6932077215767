import React from "react";
import "./Hero.css";
import insuranceImage from "./hero.png";

function Hero() {
  return (
    <div className="hero-container">
      <div className="hero-text">
        <h1>ASIGURĂRI ONLINE</h1>
        <p>Siguranța de care ai nevoie, confortul pe care îl meriți.</p>
        

      </div>
      <div className="image-container">
        <img src={insuranceImage} alt="Insurance Company" />
      </div>
    </div>
  );
}

export default Hero;
