import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import { Button,  Typography, FormControl, InputLabel, MenuItem, Popover, ListItemIcon, OutlinedInput, Select, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alert from "@mui/material/Alert";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import Vibericon from '../../components/icons/vibericon.png';
import Whatsappicon from '../../components/icons/whatsappicon.png';
import Telegramicon from '../../components/icons/telegramicon.png';
import Messengericon from '../../components/icons/messengericon.png';



firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const generateDocId = () => {
    // Generate 5 random digits
    const randomDigits = Math.floor(1000000 + Math.random() * 9999999);
  
    // Generate 3 random letters
    const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
    const randomLetters = letters.charAt(Math.floor(Math.random() * letters.length)) +
                            letters.charAt(Math.floor(Math.random() * letters.length)) + 
                            letters.charAt(Math.floor(Math.random() * letters.length));
  
    // Combine digits and letters to form docId
    const docId = "CV" + randomDigits + randomLetters;
  
    return docId;
};

const InputField = styled(TextField)({
    marginBottom: '1rem',
    zIndex: 2,
}); 

  
export default function GreenCard() {
    const [idnx, setIdnx] = useState("");
    const [idnxChanged, setIdnxChanged] = useState("");
    const [zone, setZone] = useState(""); 
    const [zoneChanged, setZoneChanged] = useState(""); 
    const [vehicleRegistrationCertificateNumber, setVehicleRegistrationCertificateNumber] = useState("");
    const [vehicleType, setVehicleType] = useState(""); 
    const [vehicleTypeChanged, setVehicleTypeChanged] = useState(""); 
    const [startDay, setStartDay] = React.useState(null);
    const [period, setPeriod] = useState(""); 
    const [periodChanged, setPeriodChanged] = useState(""); 
    const [accept, setAccept] = useState(false); 
    const [exchangeRate, setExchangeRate] = useState(null);
    const [personIsJuridical, setPersonIsJuridical] = useState("true");
    const [companyName, setCompanyName] = useState('');
    const [uid, setUid] = useState("");
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const openicon1 = Boolean(anchorEl1);
    const idicon1 = openicon1 ? 'simple-popover-1' : undefined;
    const openicon2 = Boolean(anchorEl2);
    const idicon2 = openicon2 ? 'simple-popover-2' : undefined;
    const [open, setOpen] = useState(false);

    useEffect(() => {
        auth.signInAnonymously()
          .then((userCredential) => {
            // Store the user's UID locally
            setUid(userCredential.user?.uid);
          })
          .catch((error) => {
            console.log(error);
          });
    }, []); 

    const handleiconClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
      };
    
      const handleiconClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
      };
    
      const handleiconClose1 = () => {
        setAnchorEl1(null);
      };
    
      const handleiconClose2 = () => {
        setAnchorEl2(null);
      };

    const date = new Date();
    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
    
    const handleZoneChange = (event) => {
        setZone(event.target.value);
        setZoneChanged("true");
    };   

    const handleVehicleTypeChange = (event) => {
        setVehicleType(event.target.value);
        setVehicleTypeChanged("true");
    };

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
        setPeriodChanged("true");
    };

    const handleIdnxChange = (event) => {
        const idnxValue = event.target.value;
        setIdnxChanged("true");
        if (/^[012]\d{0,12}$/.test(idnxValue)) {
            setIdnx(idnxValue);            
            if (idnxValue.startsWith("1")) {
                setPersonIsJuridical("true");
            } else {
                setPersonIsJuridical("false");
                setCompanyName('');
                }
            } else if (idnxValue === "" || (idnxValue.length === 1 && idnxValue !== "1")) {
                setIdnx(idnxValue);
                setPersonIsJuridical("false");
                setCompanyName('');
            }    
    };  

    const handleVehicleRegistrationCertificateNumberChange = (event) => {
        setVehicleRegistrationCertificateNumber(event.target.value);
    };

    const handleDateChange = (date) => {
        setStartDay(date);
    };

    const validateIdnxLastDigit = (idnxValue) => {
        if (/^[012]\d{0,12}$/.test(idnxValue)) {
            const D = (
            (parseInt(idnxValue[0])+parseInt(idnxValue[3])+parseInt(idnxValue[6])+parseInt(idnxValue[9]))*7 +
            (parseInt(idnxValue[1])+parseInt(idnxValue[4])+parseInt(idnxValue[7])+parseInt(idnxValue[10]))*3 +
            (parseInt(idnxValue[2])+parseInt(idnxValue[5])+parseInt(idnxValue[8])+parseInt(idnxValue[11]))
            ) % 10;
            return D === parseInt(idnxValue[12]);            
            } else {
            return false;
        }
    }   

    const isIdnxValid = validateIdnxLastDigit(idnx);    

    useEffect(() => {
      async function fetchExchangeRate() {
        const response = await fetch('https://asig24.md/exchange-rate-eur');
        const data = await response.json();
        setExchangeRate(data.exchangeRate);
      }
      fetchExchangeRate();
    }, []);    

    

    const calculatePrice = () => {
        let price = 0;
        if (zone === "UE" && vehicleType === "A") {
          if (period === "15") price = 35.91 * exchangeRate;
          else if (period === "30") price = 71.82 * exchangeRate;
          else if (period === "60") price = 107.73 * exchangeRate;
          else if (period === "90") price = 143.64 * exchangeRate;
          else if (period === "120") price = 179.55 * exchangeRate;
          else if (period === "150") price = 215.46 * exchangeRate;
          else if (period === "180") price = 251.37 * exchangeRate;
          else if (period === "210") price = 287.28 * exchangeRate;
          else if (period === "240") price = 305.24 * exchangeRate;
          else if (period === "270") price = 323.19 * exchangeRate;
          else if (period === "300") price = 359.10 * exchangeRate;
          else if (period === "330") price = 359.10 * exchangeRate;
          else if (period === "360") price = 359.10 * exchangeRate;
        } else if (zone === "UE" && vehicleType === "C1") {
          if (period === "15") price = 102.60 * exchangeRate;
          else if (period === "30") price = 205.20 * exchangeRate;
          else if (period === "60") price = 307.80 * exchangeRate;
          else if (period === "90") price = 410.40 * exchangeRate;
          else if (period === "120") price = 513.00 * exchangeRate;
          else if (period === "150") price = 615.60 * exchangeRate;
          else if (period === "180") price = 718.20 * exchangeRate;
          else if (period === "210") price = 820.80 * exchangeRate;
          else if (period === "240") price = 872.10 * exchangeRate;
          else if (period === "270") price = 923.40 * exchangeRate;
          else if (period === "300") price = 1026.00 * exchangeRate;
          else if (period === "330") price = 1026.00 * exchangeRate;
          else if (period === "360") price = 1026.00 * exchangeRate;
        } else if (zone === "UE" && vehicleType === "C2") {
          if (period === "15") price = 61.56 * exchangeRate;
          else if (period === "30") price = 123.12 * exchangeRate;
          else if (period === "60") price = 184.68 * exchangeRate;
          else if (period === "90") price = 246.24 * exchangeRate;
          else if (period === "120") price = 307.80 * exchangeRate;
          else if (period === "150") price = 369.36 * exchangeRate;
          else if (period === "180") price = 430.92 * exchangeRate;
          else if (period === "210") price = 492.48 * exchangeRate;
          else if (period === "240") price = 523.26 * exchangeRate;
          else if (period === "270") price = 554.04 * exchangeRate;
          else if (period === "300") price = 615.60 * exchangeRate;
          else if (period === "330") price = 615.60 * exchangeRate;
          else if (period === "360") price = 615.60 * exchangeRate;
        } else if (zone === "UE" && vehicleType === "E1") {
          if (period === "15") price = 153.90 * exchangeRate;
          else if (period === "30") price = 307.80 * exchangeRate;
          else if (period === "60") price = 461.70 * exchangeRate;
          else if (period === "90") price = 615.60 * exchangeRate;
          else if (period === "120") price = 769.50 * exchangeRate;
          else if (period === "150") price = 923.40 * exchangeRate;
          else if (period === "180") price = 1077.30 * exchangeRate;
          else if (period === "210") price = 1231.20 * exchangeRate;
          else if (period === "240") price = 1308.15 * exchangeRate;
          else if (period === "270") price = 1385.15 * exchangeRate;
          else if (period === "300") price = 1539.00 * exchangeRate;
          else if (period === "330") price = 1539.00 * exchangeRate;
          else if (period === "360") price = 1539.00 * exchangeRate;
        } else if (zone === "UE" && vehicleType === "E2") {
          if (period === "15") price = 71.82 * exchangeRate;
          else if (period === "30") price = 143.64 * exchangeRate;
          else if (period === "60") price = 215.46 * exchangeRate;
          else if (period === "90") price = 287.28 * exchangeRate;
          else if (period === "120") price = 359.10 * exchangeRate;
          else if (period === "150") price = 430.92 * exchangeRate;
          else if (period === "180") price = 502.74 * exchangeRate;
          else if (period === "210") price = 574.56 * exchangeRate;
          else if (period === "240") price = 610.47 * exchangeRate;
          else if (period === "270") price = 646.38 * exchangeRate;
          else if (period === "300") price = 718.20 * exchangeRate;
          else if (period === "330") price = 718.20 * exchangeRate;
          else if (period === "360") price = 718.20 * exchangeRate;
        } else if (zone === "UE" && vehicleType === "B") {
          if (period === "15") price = 35.91 * exchangeRate;
          else if (period === "30") price = 71.82 * exchangeRate;
          else if (period === "60") price = 107.73 * exchangeRate;
          else if (period === "90") price = 143.64 * exchangeRate;
          else if (period === "120") price = 179.55 * exchangeRate;
          else if (period === "150") price = 215.46 * exchangeRate;
          else if (period === "180") price = 251.37 * exchangeRate;
          else if (period === "210") price = 287.28 * exchangeRate;
          else if (period === "240") price = 305.24 * exchangeRate;
          else if (period === "270") price = 323.19 * exchangeRate;
          else if (period === "300") price = 359.10 * exchangeRate;
          else if (period === "330") price = 359.10 * exchangeRate;
          else if (period === "360") price = 359.10 * exchangeRate;
        } else if (zone === "UkrBelRus" && vehicleType === "A") {
          if (period === "15") price = 23.20 * exchangeRate;
          else if (period === "30") price = 46.40 * exchangeRate;
          else if (period === "60") price = 69.60 * exchangeRate;
          else if (period === "90") price = 92.80 * exchangeRate;
          else if (period === "120") price = 116.00 * exchangeRate;
          else if (period === "150") price = 139.20 * exchangeRate;
          else if (period === "180") price = 162.40 * exchangeRate;
          else if (period === "210") price = 185.60 * exchangeRate;
          else if (period === "240") price = 197.20 * exchangeRate;
          else if (period === "270") price = 208.80 * exchangeRate;
          else if (period === "300") price = 232.00 * exchangeRate;
          else if (period === "330") price = 232.00 * exchangeRate;
          else if (period === "360") price = 232.00 * exchangeRate;
        } else if (zone === "UkrBelRus" && vehicleType === "C1") {
          if (period === "15") price = 25.52 * exchangeRate;
          else if (period === "30") price = 51.04 * exchangeRate;
          else if (period === "60") price = 76.56 * exchangeRate;
          else if (period === "90") price = 102.08 * exchangeRate;
          else if (period === "120") price = 127.60 * exchangeRate;
          else if (period === "150") price = 153.12 * exchangeRate;
          else if (period === "180") price = 178.64 * exchangeRate;
          else if (period === "210") price = 204.16 * exchangeRate;
          else if (period === "240") price = 216.92 * exchangeRate;
          else if (period === "270") price = 229.68 * exchangeRate;
          else if (period === "300") price = 255.20 * exchangeRate;
          else if (period === "330") price = 255.20 * exchangeRate;
          else if (period === "360") price = 255.20 * exchangeRate;
        } else if (zone === "UkrBelRus" && vehicleType === "C2") {
          if (period === "15") price = 23.20 * exchangeRate;
          else if (period === "30") price = 46.40 * exchangeRate;
          else if (period === "60") price = 69.60 * exchangeRate;
          else if (period === "90") price = 92.80 * exchangeRate;
          else if (period === "120") price = 116.00 * exchangeRate;
          else if (period === "150") price = 139.20 * exchangeRate;
          else if (period === "180") price = 162.40 * exchangeRate;
          else if (period === "210") price = 185.60 * exchangeRate;
          else if (period === "240") price = 197.20 * exchangeRate;
          else if (period === "270") price = 208.80 * exchangeRate;
          else if (period === "300") price = 232.00 * exchangeRate;
          else if (period === "330") price = 232.00 * exchangeRate;
          else if (period === "360") price = 232.00 * exchangeRate;
        } else if (zone === "UkrBelRus" && vehicleType === "E1") {
          if (period === "15") price = 23.20 * exchangeRate;
          else if (period === "30") price = 46.40 * exchangeRate;
          else if (period === "60") price = 69.60 * exchangeRate;
          else if (period === "90") price = 92.80 * exchangeRate;
          else if (period === "120") price = 116.00 * exchangeRate;
          else if (period === "150") price = 139.20 * exchangeRate;
          else if (period === "180") price = 162.40 * exchangeRate;
          else if (period === "210") price = 185.60 * exchangeRate;
          else if (period === "240") price = 197.20 * exchangeRate;
          else if (period === "270") price = 208.80 * exchangeRate;
          else if (period === "300") price = 232.00 * exchangeRate;
          else if (period === "330") price = 232.00 * exchangeRate;
          else if (period === "360") price = 232.00 * exchangeRate;
        } else if (zone === "UkrBelRus" && vehicleType === "E2") {
          if (period === "15") price = 20.88 * exchangeRate;
          else if (period === "30") price = 41.76 * exchangeRate;
          else if (period === "60") price = 62.64 * exchangeRate;
          else if (period === "90") price = 83.52 * exchangeRate;
          else if (period === "120") price = 104.40 * exchangeRate;
          else if (period === "150") price = 125.28 * exchangeRate;
          else if (period === "180") price = 146.16 * exchangeRate;
          else if (period === "210") price = 167.04 * exchangeRate;
          else if (period === "240") price = 177.48 * exchangeRate;
          else if (period === "270") price = 187.92 * exchangeRate;
          else if (period === "300") price = 208.80 * exchangeRate;
          else if (period === "330") price = 208.80 * exchangeRate;
          else if (period === "360") price = 208.80 * exchangeRate;
        } else if (zone === "UkrBelRus" && vehicleType === "B") {
          if (period === "15") price = 16.24 * exchangeRate;
          else if (period === "30") price = 32.48 * exchangeRate;
          else if (period === "60") price = 48.72 * exchangeRate;
          else if (period === "90") price = 64.96 * exchangeRate;
          else if (period === "120") price = 81.20 * exchangeRate;
          else if (period === "150") price = 97.44 * exchangeRate;
          else if (period === "180") price = 113.68 * exchangeRate;
          else if (period === "210") price = 129.92 * exchangeRate;
          else if (period === "240") price = 138.04 * exchangeRate;
          else if (period === "270") price = 146.16 * exchangeRate;
          else if (period === "300") price = 162.40 * exchangeRate;
          else if (period === "330") price = 162.40 * exchangeRate;
          else if (period === "360") price = 162.40 * exchangeRate;
        } else if (zone === "UkrBel" && vehicleType === "A") {
          if (period === "15") price = 3.15 * exchangeRate;
          else if (period === "30") price = 4.20 * exchangeRate;
          else if (period === "60") price = 6.30 * exchangeRate;
          else if (period === "90") price = 8.40 * exchangeRate;
          else if (period === "120") price = 10.50 * exchangeRate;
          else if (period === "150") price = 12.60 * exchangeRate;
          else if (period === "180") price = 14.70 * exchangeRate;
          else if (period === "210") price = 16.80 * exchangeRate;
          else if (period === "240") price = 17.85 * exchangeRate;
          else if (period === "270") price = 18.90 * exchangeRate;
          else if (period === "300") price = 21.00 * exchangeRate;
          else if (period === "330") price = 21.00 * exchangeRate;
          else if (period === "360") price = 21.00 * exchangeRate;
        } else if (zone === "UkrBel" && vehicleType === "C1") {
          if (period === "15") price = 8.10 * exchangeRate;
          else if (period === "30") price = 10.80 * exchangeRate;
          else if (period === "60") price = 16.20 * exchangeRate;
          else if (period === "90") price = 21.60 * exchangeRate;
          else if (period === "120") price = 27.00 * exchangeRate;
          else if (period === "150") price = 32.40 * exchangeRate;
          else if (period === "180") price = 37.80 * exchangeRate;
          else if (period === "210") price = 43.20 * exchangeRate;
          else if (period === "240") price = 45.90 * exchangeRate;
          else if (period === "270") price = 48.60 * exchangeRate;
          else if (period === "300") price = 54.00 * exchangeRate;
          else if (period === "330") price = 54.00 * exchangeRate;
          else if (period === "360") price = 54.00 * exchangeRate;
        } else if (zone === "UkrBel" && vehicleType === "C2") {
          if (period === "15") price = 13.50 * exchangeRate;
          else if (period === "30") price = 18.00 * exchangeRate;
          else if (period === "60") price = 27.00 * exchangeRate;
          else if (period === "90") price = 36.00 * exchangeRate;
          else if (period === "120") price = 45.00 * exchangeRate;
          else if (period === "150") price = 54.00 * exchangeRate;
          else if (period === "180") price = 63.00 * exchangeRate;
          else if (period === "210") price = 72.00 * exchangeRate;
          else if (period === "240") price = 76.50 * exchangeRate;
          else if (period === "270") price = 81.00 * exchangeRate;
          else if (period === "300") price = 90.00 * exchangeRate;
          else if (period === "330") price = 90.00 * exchangeRate;
          else if (period === "360") price = 90.00 * exchangeRate;
        } else if (zone === "UkrBel" && vehicleType === "E1") {
          if (period === "15") price = 7.65 * exchangeRate;
          else if (period === "30") price = 10.20 * exchangeRate;
          else if (period === "60") price = 15.30 * exchangeRate;
          else if (period === "90") price = 20.40 * exchangeRate;
          else if (period === "120") price = 25.50 * exchangeRate;
          else if (period === "150") price = 30.60 * exchangeRate;
          else if (period === "180") price = 35.70 * exchangeRate;
          else if (period === "210") price = 40.80 * exchangeRate;
          else if (period === "240") price = 43.35 * exchangeRate;
          else if (period === "270") price = 45.90 * exchangeRate;
          else if (period === "300") price = 51.00 * exchangeRate;
          else if (period === "330") price = 51.00 * exchangeRate;
          else if (period === "360") price = 51.00 * exchangeRate;
        } else if (zone === "UkrBel" && vehicleType === "E2") {
          if (period === "15") price = 13.50 * exchangeRate;
          else if (period === "30") price = 18.00 * exchangeRate;
          else if (period === "60") price = 27.00 * exchangeRate;
          else if (period === "90") price = 36.00 * exchangeRate;
          else if (period === "120") price = 45.00 * exchangeRate;
          else if (period === "150") price = 54.00 * exchangeRate;
          else if (period === "180") price = 63.00 * exchangeRate;
          else if (period === "210") price = 72.00 * exchangeRate;
          else if (period === "240") price = 76.50 * exchangeRate;
          else if (period === "270") price = 81.00 * exchangeRate;
          else if (period === "300") price = 90.00 * exchangeRate;
          else if (period === "330") price = 90.00 * exchangeRate;
          else if (period === "360") price = 90.00 * exchangeRate;
        } else if (zone === "UkrBel" && vehicleType === "B") {
          if (period === "15") price = 2.70 * exchangeRate;
          else if (period === "30") price = 3.60 * exchangeRate;
          else if (period === "60") price = 5.40 * exchangeRate;
          else if (period === "90") price = 7.20 * exchangeRate;
          else if (period === "120") price = 9.00 * exchangeRate;
          else if (period === "150") price = 10.80 * exchangeRate;
          else if (period === "180") price = 12.60 * exchangeRate;
          else if (period === "210") price = 14.40 * exchangeRate;
          else if (period === "240") price = 15.30 * exchangeRate;
          else if (period === "270") price = 16.20 * exchangeRate;
          else if (period === "300") price = 18.00 * exchangeRate;
          else if (period === "330") price = 18.00 * exchangeRate;
          else if (period === "360") price = 18.00 * exchangeRate;
        }
        console.log("price:", price);
  setPrice(price.toFixed(2));
  return price.toFixed(2);
        console.log("price2:", price);
    };
      
    const [price, setPrice] = useState("");

    const getCompanyName = async (idnx) => {
        try {
          const response = await axios.post("https://asig24.md/get-company-name", {
            idnx: idnx,
          });
          const companyName = response.data.company_name;
          console.log("company:", companyName);
          setCompanyName(companyName);
          return companyName;
        } catch (error) {
          console.error(error);
        }
      };
      
      const handleSubmit = async (event) => {
        event.preventDefault();
        setOpen(true);
        setZoneChanged("false");
        setVehicleTypeChanged("false");
        setPeriodChanged("false");
        setIdnxChanged("false");
      
        let companyName = "";
        if (personIsJuridical === 'true') {
          companyName = await getCompanyName(idnx);
        }
      
        const [calculatedPrice] = await Promise.all([calculatePrice(), companyName]);
      
        const docId = generateDocId();
              
        await db.collection("rcarequests2").doc(docId).set({
          idnx: idnx,
          vehicleRegistrationCertificateNumber: vehicleRegistrationCertificateNumber,
          vehicleType: vehicleType,
          personIsJuridical: personIsJuridical,
          companyName: companyName || "",
          startDay: dayjs(startDay).format("DD.MM.YYYY"),
          product_type: "Green Card",
          price: calculatedPrice,
          period: period,
          zone: zone,
          datetime: formattedDate,
          uid: auth.currentUser.uid,
        })
        .then((docRef) => {
          console.log("Document written with ID: ", docId);
          localStorage.setItem("docId", docId);
          localStorage.setItem("uid", uid);
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    };
  

    const handleDetails = async () => {
        try {
          // Get the document ID from the previous submission
          const docId = localStorage.getItem("docId");
      
          // Get the document data from Firestore
          const doc = await db.collection("rcarequests2").doc(docId).get();
      
      
          // Redirect to the details page, passing the encoded data as a parameter
          window.location.href = `./GreenCard/${docId}`;
        } catch (error) {
          console.log(error);
        }
    };      
      

  return (
    <div>
        <Helmet>
            <title>Cartea Verde online</title>
            <meta name="description" content="Calculează prețul și cumpără Cartea Verde online. Rapid și sigur cu Asig24.md obții polița Cartea Verde în câteva minute." />
            <meta name="keywords" content="Cartea Verde, Cartea Verde online, asigurări, calculator Cartea Verde, asigurare auto, Cartea Verde Moldova, asigurări online" />
            <meta name="author" content="Asig24.md" />
            <meta property="og:title" content="Cartea Verde online" />
            <meta property="og:description" content="Calculează prețul și cumpără Cartea Verde online. Rapid și sigur cu Asig24.md obții polița Cartea Verde în câteva minute." />
            <meta property="og:url" content="https://www.asig24.md/GreenCard" />
            <meta property="og:type" content="website"></meta>
            <link rel="canonical" href="https://www.asig24.md/GreenCard" />
        </Helmet>
        <Nav />        
            <div className="parent">
                <div className="forma">     

                <div className="card" id="cartea-verde" style={{ marginBottom: '1rem' }}>
                    <div className="icon icon-cartea-verde"></div>
                    <div className="card-content">
                    <h2>Cartea Verde</h2>
                    <p>Cartea Verde este o asigurare auto obligatorie pentru toți posesorii de autovehicule, pentru a traversa hotarele țării. <br></br>Află costul în doar câțiva pași. Preț garantat.</p>   
                </div>
    
  </div>
                    <form onSubmit={handleSubmit}>

                        <div className="mbfield">
                        <FormControl fullWidth required>
                            <InputLabel>Zona de deplasare</InputLabel>
                                <Select 
                                    label="Zona de deplasare"
                                    value={zone} onChange={handleZoneChange}>
                                        <MenuItem value={"UE"}>UE - toate țările sistemului "CARTEA VERDE"</MenuItem>
                                        <MenuItem value={"UkrBelRus"}>Ucraina, Belarus, Rusia</MenuItem>
                                        <MenuItem value={"UkrBel"}>Ucraina, Belarus</MenuItem>
                                </Select>
                        </FormControl>
                        </div>   

                        <InputField
                            required
                            label="IDNP sau IDNO asigurat"
                            value={idnx}
                            inputProps={{ minLength: 13, maxLength: 13, pattern: '[0-9]*' }}
                            InputProps={{
                                endAdornment: (            
                                    <InputAdornment disableTypography position="end" className='helpicon'>
                                        <IconButton onClick={handleiconClick1}  >            
                                            <HelpOutlineIcon />            
                                        </IconButton>
                                    </InputAdornment>  
                                ),                            
                            }}
                            onChange={handleIdnxChange}
                            placeholder="IDNP sau IDNO asigurat"
                            variant="outlined"
                            fullWidth
                            error={idnx !== '' && (!/^[012]\d{0,12}$/.test(idnx) || !validateIdnxLastDigit(idnx))}
                                helperText={idnx !== '' && !/^[012]\d{0,12}$/.test(idnx) ? "IDNO/IDNP trebuie să conțină 13 cifre și poate începe cu 0, 1, sau 2." :
                                idnx !== "" && !validateIdnxLastDigit(idnx) ? "Codul introdus nu este valid, verificați corectitudinea acestuia" :
                            ''}
                        />
                        <Popover
                            id={idicon1}
                            open={openicon1}
                            anchorEl={anchorEl1}
                            onClose={handleiconClose1}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            >
                            <div className="popovermsg">
                                Introduceți codul personal - IDNP 13 cifre, ori codul fiscal în caz că sunteți persoană juridică.
                            </div>
                        </Popover>

                        <InputField
                            required
                            label="Număr certificat de înmatriculare"
                            value={vehicleRegistrationCertificateNumber}
                            inputProps={{ minLength: 9, maxLength: 9, pattern: '[0-9]*' }}
                            onChange={handleVehicleRegistrationCertificateNumberChange}
                            placeholder="Număr certificat de înmatriculare"
                            variant="outlined"
                            fullWidth
                            error={vehicleRegistrationCertificateNumber !== "" && !/^\d{0,9}$/.test(vehicleRegistrationCertificateNumber)}
                            helperText={vehicleRegistrationCertificateNumber !== "" && !/^\d{0,9}$/.test(vehicleRegistrationCertificateNumber) && "Numărul certificatului de înmatriculare nu este corect"}
                            InputProps={{
                                endAdornment: (            
                                  <InputAdornment disableTypography position="end" className='helpicon'>
                                    <IconButton onClick={handleiconClick2}  >            
                                      <HelpOutlineIcon />            
                                    </IconButton>
                                  </InputAdornment>  
                                ),
                                
                            }}
                        />
                        <Popover
                            id={idicon2}
                            open={openicon2}
                            anchorEl={anchorEl2}
                            onClose={handleiconClose2}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            >
                            <div className="popovermsg">
                                <div><img src='./certificate-number.jpg' width="100%" maxwidth="200"></img></div>
                            </div>
                        </Popover>

                        <div className="mbfield">
                        <FormControl fullWidth required>
                            <InputLabel>Tipul autovehiculului</InputLabel>
                                <Select 
                                    label="Tipul autovehiculului"
                                    value={vehicleType} onChange={handleVehicleTypeChange}>
                                        <MenuItem value={"A"}><ListItemIcon className="inslogo"><DirectionsCarIcon /></ListItemIcon>Autoturism</MenuItem>
                                        <MenuItem value={"C1"}><ListItemIcon className="inslogo"><LocalShippingIcon /></ListItemIcon>Camion pînă la 3,5 tone</MenuItem>
                                        <MenuItem value={"C2"}><ListItemIcon className="inslogo"><LocalShippingIcon /></ListItemIcon>Camion peste 3,5 tone</MenuItem>
                                        <MenuItem value={"E1"}><ListItemIcon className="inslogo"><DirectionsBusIcon /></ListItemIcon>Autobuz pînă la 17 locuri</MenuItem>
                                        <MenuItem value={"E2"}><ListItemIcon className="inslogo"><DirectionsBusIcon /></ListItemIcon>Autobuz peste 17 locuri</MenuItem>
                                        <MenuItem value={"B"}><ListItemIcon className="inslogo"><TwoWheelerIcon /></ListItemIcon>Motocicletă</MenuItem>
                                </Select>
                        </FormControl>
                        </div>

                        <div className="mbfield">
                        <FormControl fullWidth required>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ro"} >
                                <DatePicker    
                                    label="Data începerii asigurării"
                                    disablePast={true}
                                    format="DD.MM.YYYY"
                                    value={startDay}      
                                    onChange={(newValue) => { handleDateChange(newValue); }}
                                    slotProps={{ 
                                        textField: { variant: 'outlined', required: true },
                                        toolbar: {
                                            toolbarTitle: "Selectați data",
                                            toolbarPlaceholder: "__",
                                            toolbarFormat: "DD.MM.YYYY",
                                            hidden: false,
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        </div>


                        <FormControl fullWidth required>
                            <InputLabel>Valabilitatea poliței</InputLabel>
                                <Select 
                                    label="Valabilitatea poliței"
                                    value={period} onChange={handlePeriodChange}>
                                        <MenuItem value={"15"}>15 zile</MenuItem>
                                        <MenuItem value={"30"}>1 lună</MenuItem>
                                        <MenuItem value={"60"}>2 luni</MenuItem>
                                        <MenuItem value={"90"}>3 luni</MenuItem>
                                        <MenuItem value={"120"}>4 luni</MenuItem>
                                        <MenuItem value={"150"}>5 luni</MenuItem>
                                        <MenuItem value={"180"}>6 luni</MenuItem>
                                        <MenuItem value={"210"}>7 luni</MenuItem>
                                        <MenuItem value={"240"}>8 luni</MenuItem>
                                        <MenuItem value={"270"}>9 luni</MenuItem>
                                        <MenuItem value={"300"}>10 luni</MenuItem>
                                        <MenuItem value={"330"}>11 luni</MenuItem>
                                        <MenuItem value={"360"}>12 luni</MenuItem>
                                </Select>
                        </FormControl> 

                        <FormControl>
                            <FormControlLabel          
                                control={
                                <Checkbox required  checked={accept} onChange={(event) => setAccept(event.target.checked)}  />
                                }            
                                label={
                                    <div className="checkbox">
                                        Sunt de acord cu termenii și condițiile și îmi exprim consimțământul pentru prelucrarea datelor cu caracter personal.
                                    </div>
                                }/>          
                        </FormControl>

                        {(  zoneChanged === "true" || 
                            vehicleTypeChanged === "true" ||
                            periodChanged === "true" ||
                            idnxChanged === "true" ||
                            !isIdnxValid ||
                            price === ""
                            ) && (
                            <div align="center">
                                <Button type="submit" color="error" variant="contained" sx={{ mt: '1rem' }}>
                                    Calculează prețul
                                </Button>
                            </div>
                        )}

                        {price !== '' && typeof isIdnxValid === 'boolean' && (
                        <>
                            {isIdnxValid ? (
                                <div className="boxresult">
                                    <Alert severity="success">
                                    {personIsJuridical === "true" && ( <div><b>{companyName}</b></div> )}
                                    <div>Prețul asigurării: <b>{price} LEI</b> ({((price / exchangeRate).toFixed(2))}€) </div>
                                    </Alert>
                                </div>
                            ) : (
                                <div className="boxresult">
                                    <Alert severity="error">
                                    <div align="center"><b>EROARE</b></div>
                                    </Alert>
                                </div>
                            )}
                        </>
                        )}
                                
                        {(  zoneChanged !== "true" && zoneChanged !== '' && 
                            vehicleTypeChanged !== "true" && vehicleTypeChanged !== '' &&
                            periodChanged !== "true" && periodChanged !== '' &&
                            idnxChanged !== "true" && idnxChanged !== '' &&
                            price !== "" && isIdnxValid                        
                            ) && (
                            <div align="center">                               
                                <Button  onClick={handleDetails}   variant="contained" sx={{ mt: '1rem' }}>
                                    Plasează comandă
                                </Button>                                     
                            </div>    
                        )}

                    </form>                

                    <Dialog open={open} onClose={() => setOpen(false)}>
  <DialogTitle  sx={{ textAlign: 'center' }}><h1 >{price} Lei</h1> {((price / exchangeRate).toFixed(2))}€</DialogTitle>
  <DialogContent  sx={{ textAlign: 'center' }}>
    <DialogContentText>
      
      Comandă la telefon
      <div>
      <a href="tel:+37361111101">
  <Button type="button" variant="contained" color="error" size="large">
    061 111 101
  </Button>
</a>
</div>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <IconButton>
    <Link href="viber://chat?number=%2b37361111101">
      <img src={Vibericon} alt="Viber" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://wa.me/37361111101">
      <img src={Whatsappicon} alt="WhatsApp" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://t.me/+37361111101">
      <img src={Telegramicon} alt="Telegram" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://m.me/asig24">
      <img src={Messengericon} alt="Messenger" style={{ height: '45px' }} />
    </Link>
  </IconButton>
</div>
      sau
      <div>
      <Button onClick={handleDetails}   variant="contained" size="large">
                                            COMANDĂ ONLINE
                                        </Button>
                                        </div>
      </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ pr: '8px' }}>
    <Button
      onClick={() => setOpen(false)}
      color="inherit"
      sx={{
        position: 'absolute',
        top: '1px',
        right: '1px',
      }}
    >
      X
    </Button>
  </DialogActions>
</Dialog>


                </div>   
            </div> 
       <Footer />        
    </div>
  );
}