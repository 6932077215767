import React from "react";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"

export default function Privacy() {
  return (
    <div>
      <Nav />
      <div className="parent">
                <div className="condText">
                <h1>Politica de Securitate</h1>
                    <br></br>

                    <h3>Dispoziții generale</h3>
Prezenta Politică este aprobată, inclusiv, în vederea conformării <b>BROKER DE ASIGURARE-REASIGURARE ”RIMGROUP-ASIG” S.R.L.</b> cu prevederile Hotdrării Guvernului Republicii Moldova nr.1123 din data de 14 decembrie 2010 "privind aprobarea Cerințelor față de asigurarea Securității datelor cu caracter personal la prelucrarea acestora în cadrul sistemelor informaționale de date cu caracter personal" și Legii Republicii Moldova nr.133 din 08.07 .2011 "privind protecția datelor cu caracter personal".
<br/><br/>

<h3>1. Colectarea și Utilizarea Informațiilor</h3>
Colectăm și utilizăm informațiile dvs. personale în conformitate cu Politica noastră de Confidențialitate, care poate fi accesată <a href={`${process.env.PUBLIC_URL}/Politicasecuritate.PDF`} target="_blank" rel="noopener noreferrer"><b>aici.</b></a>
<br/><br/>

<h3>2. Măsuri de Securitate Fizică și Tehnică</h3>
Am implementat o serie de măsuri de securitate pentru a proteja informațiile dvs. personale, inclusiv:<br/>
Criptarea datelor în tranzit și în repaus, utilizând protocoale și algoritmi de criptare standard în industrie, precum HTTPS și SSL/TLS.<br/>
Protecția infrastructurii noastre de servere și rețele prin firewall-uri, sistem de detecție a intruziunilor și scanări de securitate periodice.<br/>
Accesul la informațiile dvs. personale este limitat doar la angajații și furnizorii noștri care au nevoie de aceste informații pentru a vă furniza serviciile solicitate și care sunt instruiți și obligați să păstreze confidențialitatea acestor informații.<br/>
Implementarea unui sistem de autentificare cu doi factori (2FA) pentru accesul la zonele sensibile ale site-ului, cum ar fi panoul de administrare și procesarea plăților.<br/>
Redundanța și backup-ul datelor pentru a preveni pierderea accidentală sau distrugerea informațiilor dvs.
<br/><br/>

<h3>3. Educația angajaților</h3>
Instruim în mod regulat angajații noștri în domeniul securității informațiilor și conștientizării riscurilor cibernetice, astfel încât să fie pregătiți să identifice și să prevină incidente de securitate.
<br/><br/>

<h3>4. Monitorizarea și revizuirea continuă</h3>
Monitorizăm în mod activ și evaluăm în mod constant securitatea site-ului și a infrastructurii noastre, efectuând audituri de securitate și teste de penetrare periodice pentru a identifica și remedia eventualele vulnerabilități.
<br/><br/>

<h3>5. Gestionarea Incidentelor de Securitate</h3>
În cazul unui incident de securitate care afectează informațiile dvs. personale, vom acționa rapid pentru a identifica, izola și remedia problema și vom notifica autoritățile competente și pe dvs. în conformitate cu legile și reglementările aplicabile.


</div>
            </div>


      <Footer /> 
    </div>
  );
}