import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import "./style.css";
import { Button,  FormControl, InputLabel, MenuItem, Select, TextField, FormControlLabel, Checkbox, Popover, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from "@mui/material/Alert";
import CircularProgress from '@mui/material/CircularProgress';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import Vibericon from '../../components/icons/vibericon.png';
import Whatsappicon from '../../components/icons/whatsappicon.png';
import Telegramicon from '../../components/icons/telegramicon.png';
import Messengericon from '../../components/icons/messengericon.png';

firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const generateDocId = () => {
    // Generate 5 random digits
    const randomDigits = Math.floor(100000 + Math.random() * 999999);
  
    // Generate 3 random letters
    const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
    const randomLetters = letters.charAt(Math.floor(Math.random() * letters.length)) +
                            letters.charAt(Math.floor(Math.random() * letters.length)) + 
                            letters.charAt(Math.floor(Math.random() * letters.length));
  
    // Combine digits and letters to form docId
    const docId = "RC" + randomDigits + randomLetters;
  
    return docId;
};

const InputField = styled(TextField)({
    marginBottom: '1rem',
});

const InputField2 = styled(TextField)({
    marginTop: '1rem',
});



export default function RCA() {
const [idnx, setIdnx] = useState("");  
const [idnxChanged, setIdnxChanged] = useState("");
const [vehicleRegistrationCertificateNumber, setVehicleRegistrationCertificateNumber] = useState("");
const [certificateNumberChanged, setCertificateNumberChanged] = useState("");
const [unlimitedPersons, setUnlimitedPersons] = useState("true");
const [unlimitedPersonsChanged, setUnlimitedPersonsChanged] = useState("");
const [personIsJuridical, setPersonIsJuridical] = useState("true");
const [companyName, setCompanyName] = useState('');
const [companyName2, setCompanyName2] = useState('');
const [operatingModes, setOperatingModes] = useState("normal");
const [operatingModesChanged, setOperatingModesChanged] = useState("normal");   
const [result, setResult] = useState("");    
const [formSubmitted, setFormSubmitted] = useState(false);
const [formErrors, setFormErrors] = useState(false);
const [accept, setAccept] = useState(false); 
const [loading, setLoading] = useState(false);
const [idnpFields, setIdnpFields] = useState([]);
const [idnpFieldsChanged, setIdnpFieldsChanged] = useState("");
const [idnp1, idnp2] = idnpFields.map(field => field.value);
const [startDay, setStartDay] = React.useState(null);
const [uid, setUid] = useState("");
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openicon1 = Boolean(anchorEl1);
  const idicon1 = openicon1 ? 'simple-popover-1' : undefined;
  const openicon2 = Boolean(anchorEl2);
  const idicon2 = openicon2 ? 'simple-popover-2' : undefined;
  const [open, setOpen] = useState(false);





useEffect(() => {
    auth.signInAnonymously()
      .then((userCredential) => {
        // Store the user's UID locally
        setUid(userCredential.user?.uid);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); 

  const handleiconClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleiconClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleiconClose1 = () => {
    setAnchorEl1(null);
  };

  const handleiconClose2 = () => {
    setAnchorEl2(null);
  };
  

const handleOperatingModesChange = (event) => {
    setOperatingModes(event.target.value);
    setOperatingModesChanged("true");
};

const handleIdnxChange = (event) => {
const idnxValue = event.target.value;
setIdnxChanged("true");
if (/^[012]\d{0,12}$/.test(idnxValue)) {
    setIdnx(idnxValue);
    if (idnxValue.startsWith("1")) {
        setPersonIsJuridical("true");        
        setUnlimitedPersons("true");
    } else {
        setPersonIsJuridical("false");
        setOperatingModes("normal");
        setCompanyName('');
        }
    } else if (idnxValue === "" || (idnxValue.length === 1 && idnxValue !== "1")) {
        setIdnx(idnxValue);
        setPersonIsJuridical("false");
        setOperatingModes("normal");
        setCompanyName('');
    }    
};  

const handleVehicleRegistrationCertificateNumberChange = (event) => {
const vehicleRegistrationCertificateNumberValue = event.target.value;
    setCertificateNumberChanged("true");
    if (/^\d{0,10}$/.test(vehicleRegistrationCertificateNumberValue)) {
        setVehicleRegistrationCertificateNumber(vehicleRegistrationCertificateNumberValue);
    }
};  
  
const handleAddField = () => {
    if (idnpFields.length < 2) {
        const newId = idnpFields[idnpFields.length - 1]?.id + 1 || 1;
        setIdnpFields([...idnpFields, { id: newId, value: '' }]);
    }
};

useEffect(() => {
    setFormErrors(
      idnpFields.some((field) => {
        return (
          field.value !== "" &&
          (!/^[02]\d{0,12}$/.test(field.value) ||
            !validateIdnxLastDigit(field.value))
        );
      })
    );
}, [idnpFields]);
  
const handleRemoveField = (id) => {
    setIdnpFields(idnpFields.filter(field => field.id !== id));
};

const handleUnlimitedPersonsChange = (event) => {
    setUnlimitedPersons(event.target.value);
    setUnlimitedPersonsChanged("true");
    setIdnpFields([]);
};
  
const handleChange = (id, value) => {
    const updatedFields = idnpFields.map(field => {
      if (field.id === id) {
        field.value = value;
      }
      return field;
    });
    setIdnpFields(updatedFields);
    setIdnpFieldsChanged("true");    
};

const renderIDNPFields = () => {
    return idnpFields.map((field) => (
      <div key={field.id}>
        <InputField2
          required
          inputProps={{ minLength: 13, maxLength: 13, pattern: '[0-9]*' }}
          label="Cod personal asigurat (IDNP)"
          value={field.value}
          onChange={(e) => handleChange(field.id, e.target.value)}
          placeholder="Cod personal asigurat (IDNP)"
          variant="outlined"
          fullWidth
          error={
            field.value !== "" &&
            (!/^[02]\d{0,12}$/.test(field.value) ||
              !validateIdnxLastDigit(field.value))
          }
          helperText={
            field.value !== "" &&
            (!/^[02]\d{0,12}$/.test(field.value) ||
              !validateIdnxLastDigit(field.value)) &&
            "IDNP introdus nu este corect."
          }
          InputProps={{
            endAdornment: (
              <IconButton>
                <HighlightOffIcon onClick={() => handleRemoveField(field.id)} />
              </IconButton>
            ),
          }}
          
        />
      </div>
    ));
};

const validateIdnxLastDigit = (idnxValue) => {
    if (/^[012]\d{0,12}$/.test(idnxValue)) {
        const D = (
        (parseInt(idnxValue[0])+parseInt(idnxValue[3])+parseInt(idnxValue[6])+parseInt(idnxValue[9]))*7 +
        (parseInt(idnxValue[1])+parseInt(idnxValue[4])+parseInt(idnxValue[7])+parseInt(idnxValue[10]))*3 +
        (parseInt(idnxValue[2])+parseInt(idnxValue[5])+parseInt(idnxValue[8])+parseInt(idnxValue[11]))
        ) % 10;
        return D === parseInt(idnxValue[12]);
        } else {
        return false;
    }
}

const date = new Date();
const formattedDate = date.toLocaleString('en-GB', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit'
});

let operatingModesNumber;

if (operatingModes === 'normal') {
  operatingModesNumber = 1;
} else if (operatingModes === 'transport') {
  operatingModesNumber = 2;
} else if (operatingModes === 'transportinterurban') {
    operatingModesNumber = 2;
} else if (operatingModes === 'taxi') {
    operatingModesNumber = 4;
} else if (operatingModes === 'rent') {
    operatingModesNumber = 4;
} else {
  throw new Error('Invalid operating mode');
}

const getCompanyName = async (idnx) => {
    try {
      const response = await axios.post("https://asig24.md/get-company-name", {
        idnx: idnx,
      });
      const companyName = response.data.company_name;
      console.log("company:", companyName);
      return companyName;
      setCompanyName2(response.data.company_name);
    } catch (error) {
      console.error(error);
    }
  };

const handleSubmit = async (event) => {
    event.preventDefault();  
    setOpen(true);  
    setLoading(true);    
    setCertificateNumberChanged(false);
    setIdnxChanged(false);
    setUnlimitedPersonsChanged(false);
    setIdnpFieldsChanged(false);
    setOperatingModesChanged(false);
    setFormSubmitted(true);     
    if (validateIdnxLastDigit(idnx)) {
      // perform calculation
    } else {     
    } 
    
    
    try {
        let companyName;
        if (personIsJuridical ==='true') {
          companyName = await getCompanyName(idnx);
        }
        const response = await axios.post("https://asig24.md/calculate_rcai", {
          UnlimitedPersons: unlimitedPersons,
          PersonIsJuridical: personIsJuridical,
          OperatingModes: operatingModesNumber,
          IDNX: idnx,
          IDNP1: idnp1,
          IDNP2: idnp2,
          VehicleRegistrationCertificateNumber: vehicleRegistrationCertificateNumber,
        });
      
        const result = response.data;
        setResult(result);
        setLoading(false);
        
      
        const docId = generateDocId();
        
        await db.collection("rcarequests2").doc(docId).set({
          idnx: idnx,
          persons: idnpFields,    
          vehicleRegistrationCertificateNumber: vehicleRegistrationCertificateNumber,
          unlimitedPersons: unlimitedPersons,
          personIsJuridical: personIsJuridical,
          companyName: companyName || "",
          operatingModes: operatingModes,
          startDay: dayjs(startDay).format('DD.MM.YYYY'),
          product_type: "RCA",
          success: result.IsSuccess, 
          price: result.PrimeSum, 
          bonusMalus: result.BonusMalusClass,
          datetime: formattedDate,
          uid: auth.currentUser.uid,
        });
      
        console.log("Document ID:", docId);
        setCompanyName2(companyName);
        localStorage.setItem("docId", docId);
        localStorage.setItem('uid', uid);
        
      
      } catch (error) {
        console.error(error);        
      }      
};    


  

const handleDetails = async () => {
    try {
      // Get the document ID from the previous submission
      const docId = localStorage.getItem("docId");
  
      // Get the document data from Firestore
      const doc = await db.collection("rcarequests2").doc(docId).get();
  
  
      // Redirect to the details page, passing the encoded data as a parameter
      window.location.href = `./RCA/${docId}`;
    } catch (error) {
      console.log(error);
    }
};


const handleClick = () => {
  setOpen(true);
};

return (
    <div>
        <Helmet>
            <title>Asigurare RCA online</title>
            <meta name="description" content="Calculează și cumpără asigurare RCA online. Rapid și sigur cu Asig24.md obții polița ta de asigurare RCA în câteva minute." />
            <meta name="keywords" content="RCA, RCA online, asigurări, calculator RCA, asigurare auto, asigurare RCA Moldova, asigurări online" />
            <meta name="author" content="Asig24.md" />
            <meta property="og:title" content="Asigurare RCA online" />
            <meta property="og:description" content="Calculează și cumpără asigurare RCA online. Rapid și sigur cu Asig24.md obții polița ta de asigurare RCA în câteva minute." />
            <meta property="og:url" content="https://www.asig24.md/RCA" />
            <meta property="og:type" content="website"></meta>
            <link rel="canonical" href="https://www.asig24.md/RCA" />
        </Helmet>
        <Nav />
        
            <div className="parent">
                <div className="forma">

                <div className="card" id="rca" style={{ marginBottom: '1rem' }}>
            <div className="icon icon-rca"></div>
            <div className="card-content">
             <h2>RCA</h2>
              <p>Polita RCA (Răspundere Civilă Auto) este un tip de asigurare auto obligatorie în Republica Moldova. Calculează online și află prețul exact al asigurării. <b>Asigurarea RCA este valabilă și în format electronic.</b></p>  
                
              </div>
             </div>
            
                    <form onSubmit={handleSubmit}>                        
                        <InputField
                            required
                            label="IDNP sau IDNO asigurat"
                            value={idnx}
                            inputProps={{ minLength: 13, maxLength: 13, pattern: '[0-9]*' }}
                            InputProps={{
                                endAdornment: (            
                                    <InputAdornment disableTypography position="end" className='helpicon'>
                                        <IconButton onClick={handleiconClick1}  >            
                                            <HelpOutlineIcon />            
                                        </IconButton>
                                    </InputAdornment>  
                                ),                            
                            }}
                            onChange={handleIdnxChange}
                            placeholder="IDNP sau IDNO asigurat"
                            variant="outlined"
                            fullWidth
                            error={formSubmitted && (idnx !== '' && (!/^[012]\d{0,12}$/.test(idnx) || !validateIdnxLastDigit(idnx)))}
                            helperText={formSubmitted && idnx !== '' && !/^[012]\d{0,12}$/.test(idnx) ? "IDNO/IDNP trebuie să conțină 13 cifre și poate începe cu 0, 1, sau 2." :
                            formSubmitted && idnx !== "" && !validateIdnxLastDigit(idnx) ? "Codul introdus nu este valid, verificați corectitudinea acestuia" :
                            ''}
                        />
                        <Popover
                            id={idicon1}
                            open={openicon1}
                            anchorEl={anchorEl1}
                            onClose={handleiconClose1}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            >
                            <div className="popovermsg">
                                Introduceți codul personal - IDNP 13 cifre, ori codul fiscal în caz că sunteți persoană juridică.
                            </div>
                        </Popover>

                        <InputField
                            required
                            label="Număr certificat de înmatriculare"
                            value={vehicleRegistrationCertificateNumber}
                            inputProps={{ minLength: 9, maxLength: 9, pattern: '[0-9]*' }}
                            onChange={handleVehicleRegistrationCertificateNumberChange}
                            placeholder="Număr certificat de înmatriculare"
                            variant="outlined"
                            fullWidth
                            error={vehicleRegistrationCertificateNumber !== "" && !/^\d{0,9}$/.test(vehicleRegistrationCertificateNumber)}
                            helperText={vehicleRegistrationCertificateNumber !== "" && !/^\d{0,9}$/.test(vehicleRegistrationCertificateNumber) && "Numărul certificatului de înmatriculare nu este corect"}
                            InputProps={{
                                endAdornment: (            
                                  <InputAdornment disableTypography position="end" className='helpicon'>
                                    <IconButton onClick={handleiconClick2}  >            
                                      <HelpOutlineIcon />            
                                    </IconButton>
                                  </InputAdornment>  
                                ),
                                
                            }}
                        />
                        <Popover
                            id={idicon2}
                            open={openicon2}
                            anchorEl={anchorEl2}
                            onClose={handleiconClose2}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            >
                            <div className="popovermsg">
                                <div><img src='./certificate-number.jpg' width="100%" maxwidth="200"></img></div>
                            </div>
                        </Popover>

                        <div className="mbfield">
                        <FormControl fullWidth>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ro"} >
                            <DatePicker    
                            label="Data începerii asigurării"
                            disablePast={true}
                            format="DD.MM.YYYY"
                            value={startDay}      
                            onChange={(newValue) => { setStartDay(newValue); }}
                            slotProps={{ 
                                textField: { variant: 'outlined', required: true },
                                toolbar: {
                                toolbarTitle: "Selectați data",
                                toolbarPlaceholder: "__",
                                toolbarFormat: "DD.MM.YYYY",
                                hidden: false,
                                }
                            }}                            
                            />
                        </LocalizationProvider>
                        </FormControl>
                        </div>

                        {/*  LIMITAT/NELIMITAT
                        {personIsJuridical !== "true" && vehicleRegistrationCertificateNumber && (        
                        <FormControl fullWidth>
                            <InputLabel>Persoane admise la conducerea vehiculului</InputLabel>
                                <Select 
                                    label="Persoane admise la conducerea vehiculului"
                                    value={unlimitedPersons} onChange={handleUnlimitedPersonsChange}>
                                        <MenuItem value={"true"}>Nelimitat</MenuItem>
                                        <MenuItem value={"false"}>Limitat (asigurat + max 2 persoane)</MenuItem>
                                </Select>
                            </FormControl>        
                            )
                            } 
                            
                        */}

                        {personIsJuridical !== "false" && vehicleRegistrationCertificateNumber && (        
                        <FormControl fullWidth>
                            <InputLabel>Mod de exploatare</InputLabel>
                                <Select 
                                    label="Mod de exploatare"          
                                    value={operatingModes} onChange={handleOperatingModesChange}>
                                        <MenuItem value={"normal"}>Mod obișnuit</MenuItem>
                                        <MenuItem value={"transport"}>Microbuz pe rută</MenuItem>
                                        <MenuItem value={'transportinterurban'}>Transport interurban autorizat</MenuItem>
                                        <MenuItem value={"taxi"}>Taxi</MenuItem>
                                        <MenuItem value={'rent'}>Chirie auto</MenuItem>
                                </Select>
                        </FormControl>        
                        )}
            
                        {unlimitedPersons !== "true" && (
                            <div>
                                {renderIDNPFields()}
                                {idnpFields.length < 2 && (
                                <div align="center" className="idnpbutton">
                                    <Button onClick={handleAddField} size="small" type="button" variant="contained"  endIcon={<AddCircleOutlineIcon />}>Adaugă încă o persoană</Button>
                                </div>
                                )}
                
                            </div>
                        )}

                        <FormControl>
                            <FormControlLabel          
                                control={
                                <Checkbox required  checked={accept} onChange={(event) => setAccept(event.target.checked)}  />
                                }            
                                label={
                                    <div className="checkbox">
                                        Sunt de acord cu termenii și condițiile și îmi exprim consimțământul pentru prelucrarea datelor cu caracter personal.
                                    </div>
                                }/>          
                        </FormControl>  


                        {(result.IsSuccess !== 'true' || 
                        certificateNumberChanged || 
                        idnxChanged ||
                        unlimitedPersonsChanged ||
                        idnpFieldsChanged ||
                        operatingModesChanged ||
                        formErrors === true
                         ) && (
                            <div align="center">
                                <Button type="submit" color="error"  variant="contained" sx={{ mt: '1rem' }}>
                                    Calculează prețul
                                </Button>
                            </div>
                        )}

                        {loading ? (
                        <div align="center" className="loading">
                        </div>
                        ) 
                        : 
                        (                                   
                            <div>
                                {result && (
                                    <div className="boxresult">
                                        { (formErrors === false && result.IsSuccess === 'true' && result.PrimeSum !== '0' ) &&  (
                                            <>
                                                <Alert severity="success">
                                                    {personIsJuridical === "true" && ( <div><b>{companyName2}</b></div> )}
                                                    <div>Prețul asigurării: <b>{result.PrimeSum}</b></div>
                                                    <div>Clasa Bonus-Malus: <b>{result.BonusMalusClass}</b></div>
                                                </Alert>
                                            </>
                                        )}
                                        {(result.IsSuccess === false || formErrors === true || result.PrimeSum === '0' ) && (
                                            <Alert severity="error"><b>Eroare. Verificați datele introduse.</b></Alert>
                                        )}
                                    </div>
                                )}
                             </div>                                                    
                        )}
                            <div align="center">
                                {( (result.IsSuccess === 'true' && formErrors === false && 
                                    certificateNumberChanged !== 'true' && certificateNumberChanged !== '' && 
                                    idnxChanged !== 'true' && idnxChanged !== '' && 
                                    unlimitedPersonsChanged !== 'true' && unlimitedPersonsChanged !== '' && 
                                    idnpFieldsChanged !== 'true' && idnpFieldsChanged !== '' && 
                                    operatingModesChanged !== 'true' && operatingModesChanged !== '')) &&
                                    (
                                        <Button onClick={handleDetails}   variant="contained" sx={{ mt: '1rem' }}>
                                            Plasează comandă
                                        </Button>  
                                    )
                                }
                            </div>    

                    </form>     


                    <Dialog open={open} onClose={() => setOpen(false)}>
  {loading ? (
    <DialogTitle sx={{ textAlign: 'center' }}>
        <CircularProgress />
    </DialogTitle>
  ) : (
    <>
      {result && (
        <>
          {formErrors === false && result.IsSuccess === 'true' && result.PrimeSum !== '0' && (
            <DialogTitle sx={{ textAlign: 'center' }}>
              <h1>{result.PrimeSum} Lei</h1>
              Clasa Bonus-Malus: {result.BonusMalusClass}
            </DialogTitle>
          )}
          {(result.IsSuccess === false || formErrors === true || result.PrimeSum === '0') && (
            <DialogTitle sx={{ textAlign: 'center' }}>
              <h1>Eroare</h1>
              <p>IDNP sau numărul certificatului de înmatriculare introdus nu este corect.</p>
            </DialogTitle>
          )}
        </>
      )}
    </>
  )}

  <DialogContent sx={{ textAlign: 'center' }}>
    <DialogContentText>
      {result &&
        result.IsSuccess === 'true' &&
        formErrors === false &&
        certificateNumberChanged !== 'true' &&
        certificateNumberChanged !== '' &&
        idnxChanged !== 'true' &&
        idnxChanged !== '' &&
        unlimitedPersonsChanged !== 'true' &&
        unlimitedPersonsChanged !== '' &&
        idnpFieldsChanged !== 'true' &&
        idnpFieldsChanged !== '' &&
        operatingModesChanged !== 'true' &&
        operatingModesChanged !== '' && (
          <>
            Comandă la telefon
            <div>
              <a href="tel:+37361111101">
                <Button type="button" variant="contained" color="error" size="large">
                  061 111 101
                </Button>
              </a>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton>
                <Link href="viber://chat?number=%2b37361111101">
                  <img src={Vibericon} alt="Viber" style={{ height: '45px' }} />
                </Link>
              </IconButton>
              <IconButton>
                <Link href="https://wa.me/37361111101">
                  <img src={Whatsappicon} alt="WhatsApp" style={{ height: '45px' }} />
                </Link>
              </IconButton>
              <IconButton>
                <Link href="https://t.me/+37361111101">
                  <img src={Telegramicon} alt="Telegram" style={{ height: '45px' }} />
                </Link>
              </IconButton>
              <IconButton>
                <Link href="https://m.me/asig24">
                  <img src={Messengericon} alt="Messenger" style={{ height: '45px' }} />
                </Link>
              </IconButton>
            </div>
            sau
            <div>
              <Button onClick={handleDetails} variant="contained" size="large">
                COMANDĂ ONLINE
              </Button>
            </div>
          </>
        )}
    </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ pr: '8px' }}>
    <Button
      onClick={() => setOpen(false)}
      color="inherit"
      sx={{
        position: 'absolute',
        top: '1px',
        right: '1px',
      }}
    >
      X
    </Button>
  </DialogActions>
</Dialog>

                                          

                    <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                </div>
            </div>
        <Footer />
    </div>
  );
}
