import React, { useState, useEffect } from 'react';   
import { useLocation } from 'react-router-dom';   
import axios from 'axios';   
import Nav from "../../components/navigation/Nav.js";  
import Footer from "../../components/footer/Footer.js";  
import CircularProgress from '@mui/material/CircularProgress';  
import "./style.css";  
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';  
import HighlightOffIcon from '@mui/icons-material/HighlightOff';  
import { Button } from "@mui/material";  
import firebase from 'firebase/compat/app';  
import 'firebase/compat/auth';  
import 'firebase/compat/firestore';  
 
firebase.initializeApp({  
  apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",  
  authDomain: "masig-online.firebaseapp.com",  
  projectId: "masig-online",  
  storageBucket: "masig-online.appspot.com",  
  messagingSenderId: "1064560601004",  
  appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",  
  measurementId: "G-GV5N5FV941"  
}); 
 
const db = firebase.firestore(); 
const auth = firebase.auth(); 
 
const TransactionStatus = () => { 
  const [loading, setLoading] = useState(true); 
  const [transactionData, setTransactionData] = useState(null); 
 
  const location = useLocation(); 
  const searchParams = new URLSearchParams(location.search); 
  const orderId = searchParams.get('orderId'); 
 
  useEffect(() => { 
    const fetchTransactionStatus = async () => { 
      try { 
        const docRef = db.collection('rcarequests2').doc(orderId); 
        const doc = await docRef.get(); 
        if (doc.exists) { 
          setTransactionData(doc.data()); 
          setLoading(false); 
        } else { 
          console.log("No such document!"); 
        } 
      } catch (error) { 
        console.error("Error getting document:", error); 
      } 
    }; 
 
    fetchTransactionStatus(); 
  }, [orderId]); 
 
  return ( 
    <div> 
      <Nav /> 
      <div className="parent"> 
        <div className="forma"> 
          <div> 
            {loading ? ( 
              <div align="center" className="loading"><CircularProgress /></div> 
            ) : ( 
              <div> 
                {transactionData.transactionStatus === "OK" && ( 
                  <div className='paymentStatus'> 
                    <CheckCircleOutlineIcon className='transactionStatusIcon' sx={{ fontSize: 80 }} align="center" /> 
                    <h2 align="center">Plată efectuată cu succes</h2> 
                    <p align="center">Nr. comandă: {orderId}</p> 
                    <div className='transactionStatusDetails'> 
                    <p>Suma: {transactionData.amount} MDL</p>
                    <p>Data: {transactionData.transactionDateTime}</p>                    
                      <p>RRN: {transactionData.rrn}</p> 
                      <p>Card Number: {transactionData.cardNumber}</p> 
                      <div align="center" className='paymentStatusButton'><Button size="small" variant="contained" type="submit" href='/'>Pagina principală</Button></div> 
                    </div> 
                  </div> 
                )} 
 
                {transactionData.transactionStatus !== "OK" && ( 
                  <div className='paymentStatus'> 
                    <HighlightOffIcon className='transactionStatusIconFailed'sx={{ fontSize: 80 }} align="center" /> 
                    <h2 align="center">Plata a eșuat</h2> 
                    <p align="center">Nr. comandă: {orderId}</p> 
                    <div className='transactionStatusDetails'> 
                    <p>Suma: {transactionData.amount}</p>
                    <p>Data: {transactionData.transactionDateTime}</p>   
                      <p>RRN: {transactionData.rrn}</p> 
                      <p>Card Number: {transactionData.cardNumber}</p> 
                      <div align="center" className='paymentStatusButton'><Button size="small" color="error" variant="contained" type="submit" href='/'>Încearcă din nou</Button></div>  
                    </div>  
                  </div> 
                )} 
              </div> 
            )} 
          </div> 
        </div> 
      </div> 
      <Footer /> 
    </div> 
  ); 
}; 
 
export default TransactionStatus;