import React, { useState } from 'react'; 
import axios from 'axios'; 
 
const ReverseTransaction = () => { 
  const [trans_id, setTransId] = useState(''); 
  const [amount, setAmount] = useState(''); 
 
  const handleSubmit = async (e) => { 
    e.preventDefault(); 
 
    try { 
      const response = await axios.post('https://asig24.md/reverse', { trans_id, amount }); 
 
      if (response.data.status === 'OK') { 
        alert('Transaction reversed successfully'); 
      } 
    } catch (error) { 
      console.error(error); 
      alert('Failed to reverse transaction'); 
    } 
  }; 
 
  return ( 
    <form onSubmit={handleSubmit}> 
      <label> 
        Transaction ID: 
        <input type="text" value={trans_id} onChange={(e) => setTransId(e.target.value)} required /> 
      </label> 
      <label> 
        Amount: 
        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} required /> 
      </label> 
      <button type="submit">Reverse Transaction</button> 
    </form> 
  ); 
}; 
 
export default ReverseTransaction;