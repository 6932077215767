import React, { useState, useEffect } from "react";
import './Cards.css';
import { Link } from "react-router-dom";



const Cards = () => {
  return (
    
<div className="cards-container">

<Link to="../RCA" className="card-link">
  <div className="card" id="rca">
  <div className="icon icon-rca"></div>
    <div className="card-content">
      <h2>RCA</h2>
      <p>Asigurare pentru vehicule înmatriculate în Republica Moldova</p>    
      </div>
      <button>Calculează prețul</button>      
  </div>
</Link>

<Link to="../GreenCard" className="card-link">
  <div className="card" id="cartea-verde">
  <div className="icon icon-cartea-verde"></div>
    <div className="card-content">
      <h2>Cartea Verde</h2>
      <p>Asigurare pentru vehicule înmatriculate în Republica Moldova, valabilă peste hotare</p>   
      </div> 
      <button>Calculează prețul</button>      
  </div>
</Link>

<Link to="../CASCO" className="card-link">
  <div className="card" id="casco">
  <div className="icon icon-casco"></div>
    <div className="card-content">
      <h2>CASCO</h2>
      <p>Asigurare pentru vehicule înmatriculate în Republica Moldova, valabilă în țară și peste hotare</p>
      </div>
      <button>Calculează prețul</button>  
    
  </div>
  </Link>

  <Link to="../MedicalaCalatorie" className="card-link">
  <div className="card" id="medicala-pentru-calatorii">
  <div className="icon icon-medicala-pentru-calatorii"></div>
    <div className="card-content">
      <h2>Medicală pentru călătorii</h2>
      <p>Asigurare pentru rezidenți, valabilă peste hotare</p>
      </div>
      <button>Calculează prețul</button>  
      </div>
  </Link>
  
  <Link to="../MedicalaFacultativa" className="card-link">
  <div className="card" id="medicala-facultativa">
  <div className="icon icon-medicala-facultativa"></div>
    <div className="card-content">
      <h2>Medicală facultativă</h2>
      <p>Asigurare pentru nerezidenți, valabilă în Republica Moldova</p>
      </div>
      <button>Calculează prețul</button>  
    
  </div>
  </Link>

  <Link to="../Ipoteca" className="card-link">
  <div className="card" id="ipoteca">
  <div className="icon icon-ipoteca"></div>
    <div className="card-content">
      <h2>Ipotecă</h2>
      <p>Asigurare pentru ipotecă și bunuri imobile gajate</p>
      </div>
      <button>Calculează prețul</button>  
  </div>
  </Link>

</div>

  );
};

export default Cards;
