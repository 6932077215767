import React from "react";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./components/scrolltop/ScrollToTop";
import Home from "./pages/mainpage/Home";
import Contact from "./pages/contact/Contact";
import RCA from "./pages/rcai/RCA";
import RCADetails from "./pages/rcai/RCADetails.js";
import RCAp from "./pages/rcai/pay/RCA.js";
import GreenCard from "./pages/green-card/GreenCard";
import GreenCardDetails from "./pages/green-card/GreenCardDetails.js";
import GreenCardp from "./pages/green-card/pay/GreenCardPay.js";
import Casco from "./pages/casco/Casco";
import MedicalaCalatorie from "./pages/medicala-calatorie/MedicalaCalatorie";
import MedicalaCalatorieDetails from "./pages/medicala-calatorie/MedicalaCalatorieDetails.js";
import MedicalaCalatoriep from "./pages/medicala-calatorie/pay/MedicalaCalatoriePay.js";
import MedicalaFacultativa from "./pages/medicala-facultativa/MedicalaFacultativa";
import MedicalaFacultativaDetails from "./pages/medicala-facultativa/MedicalaFacultativaDetails.js";
import MedicalaFacultativap from "./pages/medicala-facultativa/pay/MedicalaFacultativaPay.js";
import Ipoteca from "./pages/imobil/Ipoteca";
import IpotecaDetails from "./pages/imobil/IpotecaDetails.js";
import Ipotecap from "./pages/imobil/pay/IpotecaPay.js";
import Privacy from "./pages/legal/Privacy";
import Termsofservices from "./pages/legal/Termsofservices.js";
import Termsofdelivery from "./pages/legal/Termsofdelivery.js";
import Affidavit from "./pages/legal/Affidavit.js";
import GreenCardTerms from "./pages/legal/Greencard-terms.js";
import RCATerms from "./pages/legal/RCA-terms.js";
import PaymentStatus from "./pages/payment/PaymentStatus";
import Reverse from "./pages/payment/Reverse";


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  return (
    <HelmetProvider>
    <Router>
      <div className="App">
       {/* <Nav /> */}
       <ScrollToTop />
        <Routes>
        
        <Route path="/" exact element={<Home />} />
          <Route path="/Home" element={<Home />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/RCA" element={<RCA />} />
          <Route path="/RCA/:id" element={<RCADetails />} />
          <Route path="/RCA/order/:id" element={<RCAp />} />
          <Route path="/GreenCard" element={<GreenCard />} />  
          <Route path="/GreenCard/:id" element={<GreenCardDetails />} />
          <Route path="/GreenCard/order/:id" element={<GreenCardp />} />        
          <Route path="/Casco" element={<Casco />} />
          <Route path="/MedicalaCalatorie" element={<MedicalaCalatorie />} />
          <Route path="/MedicalaCalatorie/:id" element={<MedicalaCalatorieDetails />} />
          <Route path="/MedicalaCalatorie/order/:id" element={<MedicalaCalatoriep />} />     
          <Route path="/MedicalaFacultativa" element={<MedicalaFacultativa />} />
          <Route path="/MedicalaFacultativa/:id" element={<MedicalaFacultativaDetails />} />
          <Route path="/MedicalaFacultativa/order/:id" element={<MedicalaFacultativap />} />     
          <Route path="/Ipoteca" element={<Ipoteca />} />
          <Route path="/Ipoteca/:id" element={<IpotecaDetails />} />
          <Route path="/Ipoteca/order/:id" element={<Ipotecap />} />     
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Termsofservices" element={<Termsofservices />} />
          <Route path="/Termsofdelivery" element={<Termsofdelivery />} />
          <Route path="/Affidavit" element={<Affidavit />} />
          <Route path="/GreenCardTerms" element={<GreenCardTerms />} />
          <Route path="/RCATerms" element={<RCATerms />} />
          <Route path="/PaymentStatus" element={<PaymentStatus />} />
          <Route path="/Reverse" element={<Reverse />} />
        </Routes>
      </div>
    </Router>
    </HelmetProvider>

  );
}


