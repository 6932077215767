import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import 'react-datepicker/dist/react-datepicker.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import { Button,  FormControl, InputLabel, MenuItem, Select, FormControlLabel, Checkbox } from "@mui/material";
import Alert from "@mui/material/Alert";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import Vibericon from '../../components/icons/vibericon.png';
import Whatsappicon from '../../components/icons/whatsappicon.png';
import Telegramicon from '../../components/icons/telegramicon.png';
import Messengericon from '../../components/icons/messengericon.png';



firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const generateDocId = () => {
    // Generate 5 random digits
    const randomDigits = Math.floor(1000000 + Math.random() * 9999999);
  
    // Generate 3 random letters
    const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
    const randomLetters = letters.charAt(Math.floor(Math.random() * letters.length)) +
                            letters.charAt(Math.floor(Math.random() * letters.length)) + 
                            letters.charAt(Math.floor(Math.random() * letters.length));
  
    // Combine digits and letters to form docId
    const docId = "MC" + randomDigits + randomLetters;
  
    return docId;
};

export default function MedicalaCalatorie() {

    const [country, setCountry] = useState("");
    const [countryChanged, setCountryChanged] = useState(""); 
    const [insuranceType, setInsuranceType] = useState('');
    const [insuranceTypeChanged, setInsuranceTypeChanged] = useState(""); 
    const [price, setPrice] = useState(0);
    const [birthday, setBirthday] = React.useState(null);
    const [birthdayChanged, setBirthdayChanged] = useState(""); 
    const [travelScope, setTravelScope] = useState("");
    const [travelScopeChanged, setTravelScopeChanged] = useState(""); 
    const [startDate, setStartDate] = React.useState(null);
    const [startDateChanged, setStartDateChanged] = useState(""); 
    const [endDate, setEndDate] = React.useState(null);
    const [endDateChanged, setEndDateChanged] = useState(""); 
    const [includeCovid19, setIncludeCovid19] = useState(false);
    const [includeCovid19Changed, setIncludeCovid19Changed] = useState(""); 
    const [exchangeRate, setExchangeRate] = useState(null);
    const [uid, setUid] = useState("");
    const [open, setOpen] = useState(false);


    useEffect(() => {
        auth.signInAnonymously()
          .then((userCredential) => {
            // Store the user's UID locally
            setUid(userCredential.user?.uid);
          })
          .catch((error) => {
            console.log(error);
          });
    }, []); 


    const zones = {
      CSI: [9, 11, 16, 72, 77, 119, 120, 145, 154, 157, 162],
      allWorldExcept: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 
                        23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 
                        43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 
                        63, 64, 65, 66, 67, 68, 69, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 
                        83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 
                        103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 
                        120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 
                        137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 
                        154, 155, 156, 157, 158, 159, 161, 162, 163, 164, 165, 166, 167, 168, 169],
    };

    const prices = {
      CSI: [
        { days: 5, price: 0.3 },
        { days: 10, price: 0.29 },
        { days: 30, price: 0.27 },
        { days: 60, price: 0.26 },
        { days: 180, price: 0.24 },
        { days: 365, price: 0.23 },
      ],
      allWorldExcept: [
        { days: 15, price: 0.42 },
        { days: 30, price: 0.39 },
        { days: 60, price: 0.34 },
        { days: 180, price: 0.3 },
        { days: 365, price: 0.26 },
      ],
      allWorld: [
        { days: 15, price: 0.7 },
        { days: 30, price: 0.64 },
        { days: 60, price: 0.52 },
        { days: 180, price: 0.44 },
        { days: 365, price: 0.37 },
      ],
    };

    const getAge = (dateOfBirth) => {
      const today = new Date();
      const birthDate = new Date(dateOfBirth);
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    };

    const date = new Date();
    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });

    const handleDateChange = (date) => {
      setBirthday(dayjs(date));
      setBirthdayChanged("true");
    };

    const handleStartDateChange = (date) => {
      setStartDate(dayjs(date));
      setStartDateChanged("true");
    };

    const handleEndDateChange = (date) => {
      setEndDate(dayjs(date));
      setEndDateChanged("true");
    };

    const handleTravelScopeChange = (event) => {
        setTravelScope(event.target.value);
        setTravelScopeChanged("true");
    };

    const handleInsuranceTypeChange = (event) => {
        setInsuranceType(event.target.value);
        setInsuranceTypeChanged("true");
    };

    const handleCovid19Change = (event) => {
        setIncludeCovid19(event.target.checked);
        setIncludeCovid19Changed("true");
      };
      

    const applyAgeMultiplier = (basePrice) => {
      const age = getAge(birthday);
      if (age >= 66 && age <= 70) return basePrice * 2;
      if (age >= 71 && age <= 75) return basePrice * 2.5;
      if (age >= 76 && age <= 80) return basePrice * 4.3;
      if (age >= 81 && age <= 85) return basePrice * 7;
      return basePrice;
    };

    const applyTravelScopeMultiplier = (basePrice) => {
      if (travelScope === 'tourism') return basePrice * 1;
      if (travelScope === 'work') return basePrice * 2;    
      if (travelScope === 'inot') return basePrice * 1.2;
      if (travelScope === 'gimnYachting') return basePrice * 1.5;
      if (travelScope === 'atleticaHaltere') return basePrice * 1.6;
      if (travelScope === 'ciclism') return basePrice * 1.7;
      if (travelScope === 'sarituriAcvatic') return basePrice * 1.8;
      if (travelScope === 'teamSport') return basePrice * 1.9;
      if (travelScope === 'montanEcvestru') return basePrice * 2;
      if (travelScope === 'turismMontan') return basePrice * 2.2;
      if (travelScope === 'planerism') return basePrice * 2.4;
      if (travelScope === 'alpinismSchi') return basePrice * 2.5;
      if (travelScope === 'raftingMartiale') return basePrice * 2.7;
      if (travelScope === 'autoMoto') return basePrice * 2.8;
      return basePrice;
    };

    const applyCovid19Multiplier = (basePrice) => {
        if (includeCovid19) {
          return insuranceType === 30000 ? basePrice * 2.7 : basePrice * 3;
        }
        return basePrice;
      };

    useEffect(() => {
      async function fetchExchangeRate() {
        const response = await fetch('https://asig24.md/exchange-rate-eur');
        const data = await response.json();
        setExchangeRate(data.exchangeRate);
      }
      fetchExchangeRate();
    }, []);   
    
    
    const updateInsuranceType = (selectedCountry) => {
        if (![1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 
            23, 24, 25, 26, 27, 28, 29, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 
            43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 
            63, 64, 65, 66, 67, 68, 69, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 
            83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 
            103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 
            120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 
            137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150, 151, 152, 153, 
            154, 155, 156, 157, 158, 159, 161, 162, 163, 164, 165, 166, 167, 168, 169].includes(selectedCountry) && insuranceType !== 50000) {
          setInsuranceType(50000);
        }
    };

    const getCountryName = (countryId) => {
        const countryMap = {
            1: 'Europe (UE+EEA)',
            2: 'Afghanistan',
            3: 'Albania',
            4: 'Algeria',
            5: 'Andorra',
            6: 'Angola',
            7: 'Antigua and Barbuda',
            8: 'Argentina',
            9: 'Armenia',
            10: 'Australia',
            11: 'Azerbaijan',
            12: 'Bahamas',
            13: 'Bahrain',
            14: 'Bangladesh',
            15: 'Barbados',
            16: 'Belarus',
            17: 'Belize',
            18: 'Benin',
            19: 'Bhutan',
            20: 'Bolivia',
            21: 'Bosnia and Herzegovina',
            22: 'Botswana',
            23: 'Brazil',
            24: 'Brunei',
            25: 'Burkina Faso',
            26: 'Burundi',
            27: 'Cabo Verde',
            28: 'Cambodia',
            29: 'Cameroon',
            30: 'Canada',
            31: 'Central African Republic (CAR)',
            32: 'Chad',
            33: 'Chile',
            34: 'China',
            35: 'Colombia',
            36: 'Comoros',
            37: 'Congo, Democratic Republic of the',
            38: 'Congo, Republic of the',
            39: 'Costa Rica',
            40: "Cote d'Ivoire",
            41: 'Cuba',
            42: 'Djibouti',
            43: 'Dominica',
            44: 'Dominican Republic',
            45: 'Ecuador',
            46: 'Egypt',
            47: 'El Salvador',
            48: 'Equatorial Guinea',
            49: 'Eritrea',
            50: 'Eswatini',
            51: 'Ethiopia',
            52: 'Fiji',
            53: 'Gabon',
            54: 'Gambia',
            55: 'Georgia',
            56: 'Ghana',
            57: 'Grenada',
            58: 'Guatemala',
            59: 'Guinea',
            60: 'Guinea-Bissau',
            61: 'Guyana',
            62: 'Haiti',
            63: 'Honduras',
            64: 'India',
            65: 'Indonesia',
            66: 'Iran',
            67: 'Iraq',
            68: 'Israel',
            69: 'Jamaica',
            70: 'Japan',
            71: 'Jordan',
            72: 'Kazakhstan',
            73: 'Kenya',
            74: 'Kiribati',
            75: 'Kosovo',
            76: 'Kuwait',
            77: 'Kyrgyzstan',
            78: 'Laos',
            79: 'Lebanon',
            80: 'Lesotho',
            81: 'Liberia',
            82: 'Libya',
            83: 'Madagascar',
            84: 'Malawi',
            85: 'Malaysia',
            86: 'Maldives',
            87: 'Mali',
            88: 'Marshall Islands',
            89: 'Mauritania',
            90: 'Mauritius',
            91: 'Mexico',
            92: 'Micronesia',
            93: 'Moldova',
            94: 'Monaco',
            95: 'Mongolia',
            96: 'Montenegro',
            97: 'Morocco',
            98: 'Mozambique',
            99: 'Myanmar',
            100: 'Namibia',
            101: 'Nauru',
            102: 'Nepal',
            103: 'New Zealand',
            104: 'Nicaragua',
            105: 'Niger',
            106: 'Nigeria',
            107: 'North Korea',
            108: 'North Macedonia',
            109: 'Oman',
            110: 'Pakistan',
            111: 'Palau',
            112: 'Palestine',
            113: 'Panama',
            114: 'Papua New Guinea',
            115: 'Paraguay',
            116: 'Peru',
            117: 'Philippines',
            118: 'Qatar',
            119: 'Romania',
            120: 'Russia',
            121: 'Rwanda',
            122: 'Saint Kitts and Nevis',
            123: 'Saint Lucia',
            124: 'Saint Vincent and the Grenadines',
            125: 'Samoa',
            126: 'San Marino',
            127: 'Sao Tome and Principe',
            128: 'Saudi Arabia',
            129: 'Senegal',
            130: 'Serbia',
            131: 'Seychelles',
            132: 'Sierra Leone',
            133: 'Singapore',
            134: 'Solomon Islands',
            135: 'Somalia',
            136: 'South Africa',
            137: 'South Korea',
            138: 'South Sudan',
            139: 'Sri Lanka',
            140: 'Sudan',
            141: 'Suriname',
            142: 'Switzerland',
            143: 'Syria',
            144: 'Taiwan',
            145: 'Tajikistan',
            146: 'Tanzania',
            147: 'Thailand',
            148: 'Timor-Leste',
            149: 'Togo',
            150: 'Tonga',
            151: 'Trinidad and Tobago',
            152: 'Tunisia',
            153: 'Turkey',
            154: 'Turkmenistan',
            155: 'Tuvalu',
            156: 'Uganda',
            157: 'Ukraine',
            158: 'United Arab Emirates (UAE)',
            159: 'United Kingdom (UK)',
            160: 'United States of America (USA)',
            161: 'Uruguay',
            162: 'Uzbekistan',
            163: 'Vanuatu',
            164: 'Vatican City (Holy See)',
            165: 'Venezuela',
            166: 'Vietnam',
            167: 'Yemen',
            168: 'Zambia',
            169: 'Zimbabwe',
            
        };
        return countryMap[countryId];
      };

    const calculatePrice = async (event) => {
        event.preventDefault();
        setOpen(true);
        setBirthdayChanged("false");
        setStartDateChanged("false");
        setEndDateChanged("false");
        setCountryChanged("false");
        setTravelScopeChanged("false");
        setInsuranceTypeChanged("false");
        setIncludeCovid19Changed("false");
        let zone = 'allWorld';
        const duration = endDate.diff(startDate, "day") + 1;
        if (zones.CSI.includes(country)) {
          zone = 'CSI';
        } else if (zones.allWorldExcept.includes(country)) {
          zone = 'allWorldExcept';
        }
      
        if (insuranceType === 50000 && zone !== 'allWorld') {
          zone = 'allWorld';
        }
      
        const priceData = prices[zone].find(
          (priceItem) => duration <= priceItem.days
        );
      
        if (priceData) {
          let basePrice = priceData.price * duration;
          basePrice = applyAgeMultiplier(basePrice);
          basePrice = applyTravelScopeMultiplier(basePrice);
          basePrice = applyCovid19Multiplier(basePrice);
          const finalPrice = (basePrice * exchangeRate).toFixed(2);
          setPrice(finalPrice);
      
          const docId = generateDocId();
      
          try {
            await db.collection("rcarequests2").doc(docId).set({
              startDay: dayjs(startDate).format("DD.MM.YYYY"),
              endDay: dayjs(endDate).format("DD.MM.YYYY"),
              birthDay:  dayjs(birthday).format("DD.MM.YYYY"),
              product_type: "Medical",
              price: finalPrice,
              zone: getCountryName(country),
              travelScope: travelScope,
              insuranceType: insuranceType,
              period: endDate.diff(startDate, "day") + 1,
              datetime: formattedDate,
              includeCovid19: includeCovid19 || "false",
              uid: auth.currentUser.uid,
            });
      
            console.log("Document written with ID: ", docId);
            localStorage.setItem("docId", docId);
            localStorage.setItem("uid", uid);
          } catch (error) {
            console.error("Error adding document: ", error);
          }
        } else {
          setPrice(0);
        }
      };
    
    const handleDetails = async () => {
        try {
          // Get the document ID from the previous submission
          const docId = localStorage.getItem("docId");
      
          // Get the document data from Firestore
          const doc = await db.collection("rcarequests2").doc(docId).get();
      
          // Redirect to the details page, passing the encoded data as a parameter
          window.location.href = `./MedicalaCalatorie/${docId}`;
        } catch (error) {
          console.log(error);
        }
    };

    return (
        <div>
            <Helmet>
                <title>Asigurare medicală pentru călătorii</title>
                <meta name="description" content="Calculează prețul și cumpără asigurare medicală pentru călătorii online. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta name="keywords" content="asigurare medicală, asigurări pentru călătorii, calculator asigurare medicală, cumpără asigurare medicală online, asigurări de sănătate, asigurări online" />
                <meta name="author" content="Asig24.md" />
                <meta property="og:title" content="Asigurare medicală pentru călătorii" />
                <meta property="og:description" content="Calculează prețul și cumpără asigurare medicală pentru călătorii online. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta property="og:url" content="https://www.asig24.md/MedicalaCalatorie" />
                <meta property="og:type" content="website"></meta>
                <link rel="canonical" href="https://www.asig24.md/MedicalaCalatorie" />
            </Helmet>
            <Nav />
            

                <div className="parent">
                    <div className="forma">   

                    <div className="card" id="medicala-pentru-calatorii"  style={{ marginBottom: '1rem' }}>
  <div className="icon icon-medicala-pentru-calatorii"></div>
    <div className="card-content">
      <h2>Medicală pentru călătorii</h2>
      <p>Asigurarea medicală pentru călătorii oferă asistență medicală și acoperire financiară în cazul unor probleme de sănătate sau accidente suferite în timpul călătoriilor în străinătate.</p>
      </div>
  </div>    
       
                        <form onSubmit={(event) => calculatePrice(event)}>

                            <div className="mbfield">
                            <FormControl fullWidth required>
                                <InputLabel>Teritoriul asigurat</InputLabel>
                                    <Select                                 
                                    label="Teritoriul asigurat"value={country}  onChange={(e) => {
                                        const selectedCountry = parseInt(e.target.value);
                                        setCountry(selectedCountry);
                                        setCountryChanged('true');
                                        updateInsuranceType(selectedCountry);
                                    }}>
                                    {Object.entries({
                                      1: 'Europe (UE+EEA)',
                                      2: 'Afghanistan',
                                      3: 'Albania',
                                      4: 'Algeria',
                                      5: 'Andorra',
                                      6: 'Angola',
                                      7: 'Antigua and Barbuda',
                                      8: 'Argentina',
                                      9: 'Armenia',
                                      10: 'Australia',
                                      11: 'Azerbaijan',
                                      12: 'Bahamas',
                                      13: 'Bahrain',
                                      14: 'Bangladesh',
                                      15: 'Barbados',
                                      16: 'Belarus',
                                      17: 'Belize',
                                      18: 'Benin',
                                      19: 'Bhutan',
                                      20: 'Bolivia',
                                      21: 'Bosnia and Herzegovina',
                                      22: 'Botswana',
                                      23: 'Brazil',
                                      24: 'Brunei',
                                      25: 'Burkina Faso',
                                      26: 'Burundi',
                                      27: 'Cabo Verde',
                                      28: 'Cambodia',
                                      29: 'Cameroon',
                                      30: 'Canada',
                                      31: 'Central African Republic (CAR)',
                                      32: 'Chad',
                                      33: 'Chile',
                                      34: 'China',
                                      35: 'Colombia',
                                      36: 'Comoros',
                                      37: 'Congo, Democratic Republic of the',
                                      38: 'Congo, Republic of the',
                                      39: 'Costa Rica',
                                      40: "Cote d'Ivoire",
                                      41: 'Cuba',
                                      42: 'Djibouti',
                                      43: 'Dominica',
                                      44: 'Dominican Republic',
                                      45: 'Ecuador',
                                      46: 'Egypt',
                                      47: 'El Salvador',
                                      48: 'Equatorial Guinea',
                                      49: 'Eritrea',
                                      50: 'Eswatini',
                                      51: 'Ethiopia',
                                      52: 'Fiji',
                                      53: 'Gabon',
                                      54: 'Gambia',
                                      55: 'Georgia',
                                      56: 'Ghana',
                                      57: 'Grenada',
                                      58: 'Guatemala',
                                      59: 'Guinea',
                                      60: 'Guinea-Bissau',
                                      61: 'Guyana',
                                      62: 'Haiti',
                                      63: 'Honduras',
                                      64: 'India',
                                      65: 'Indonesia',
                                      66: 'Iran',
                                      67: 'Iraq',
                                      68: 'Israel',
                                      69: 'Jamaica',
                                      70: 'Japan',
                                      71: 'Jordan',
                                      72: 'Kazakhstan',
                                      73: 'Kenya',
                                      74: 'Kiribati',
                                      75: 'Kosovo',
                                      76: 'Kuwait',
                                      77: 'Kyrgyzstan',
                                      78: 'Laos',
                                      79: 'Lebanon',
                                      80: 'Lesotho',
                                      81: 'Liberia',
                                      82: 'Libya',
                                      83: 'Madagascar',
                                      84: 'Malawi',
                                      85: 'Malaysia',
                                      86: 'Maldives',
                                      87: 'Mali',
                                      88: 'Marshall Islands',
                                      89: 'Mauritania',
                                      90: 'Mauritius',
                                      91: 'Mexico',
                                      92: 'Micronesia',
                                      93: 'Moldova',
                                      94: 'Monaco',
                                      95: 'Mongolia',
                                      96: 'Montenegro',
                                      97: 'Morocco',
                                      98: 'Mozambique',
                                      99: 'Myanmar',
                                      100: 'Namibia',
                                      101: 'Nauru',
                                      102: 'Nepal',
                                      103: 'New Zealand',
                                      104: 'Nicaragua',
                                      105: 'Niger',
                                      106: 'Nigeria',
                                      107: 'North Korea',
                                      108: 'North Macedonia',
                                      109: 'Oman',
                                      110: 'Pakistan',
                                      111: 'Palau',
                                      112: 'Palestine',
                                      113: 'Panama',
                                      114: 'Papua New Guinea',
                                      115: 'Paraguay',
                                      116: 'Peru',
                                      117: 'Philippines',
                                      118: 'Qatar',
                                      119: 'Romania',
                                      120: 'Russia',
                                      121: 'Rwanda',
                                      122: 'Saint Kitts and Nevis',
                                      123: 'Saint Lucia',
                                      124: 'Saint Vincent and the Grenadines',
                                      125: 'Samoa',
                                      126: 'San Marino',
                                      127: 'Sao Tome and Principe',
                                      128: 'Saudi Arabia',
                                      129: 'Senegal',
                                      130: 'Serbia',
                                      131: 'Seychelles',
                                      132: 'Sierra Leone',
                                      133: 'Singapore',
                                      134: 'Solomon Islands',
                                      135: 'Somalia',
                                      136: 'South Africa',
                                      137: 'South Korea',
                                      138: 'South Sudan',
                                      139: 'Sri Lanka',
                                      140: 'Sudan',
                                      141: 'Suriname',
                                      142: 'Switzerland',
                                      143: 'Syria',
                                      144: 'Taiwan',
                                      145: 'Tajikistan',
                                      146: 'Tanzania',
                                      147: 'Thailand',
                                      148: 'Timor-Leste',
                                      149: 'Togo',
                                      150: 'Tonga',
                                      151: 'Trinidad and Tobago',
                                      152: 'Tunisia',
                                      153: 'Turkey',
                                      154: 'Turkmenistan',
                                      155: 'Tuvalu',
                                      156: 'Uganda',
                                      157: 'Ukraine',
                                      158: 'United Arab Emirates (UAE)',
                                      159: 'United Kingdom (UK)',
                                      160: 'United States of America (USA)',
                                      161: 'Uruguay',
                                      162: 'Uzbekistan',
                                      163: 'Vanuatu',
                                      164: 'Vatican City (Holy See)',
                                      165: 'Venezuela',
                                      166: 'Vietnam',
                                      167: 'Yemen',
                                      168: 'Zambia',
                                      169: 'Zimbabwe',
                                    }).map(([key, value]) => (
                                      <MenuItem key={key} value={key}>
                                        {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            </div>

                            <div className="mbfield">
                            <FormControl fullWidth required>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ro"} >
                                    <DatePicker    
                                        label="Începutul călătoriei"
                                        format="DD.MM.YYYY"                                    
                                        value={startDate}
                                        disablePast={true}   
                                        onChange={(newValue) => { handleStartDateChange(newValue); }}
                                        slotProps={{ 
                                            textField: { variant: 'outlined', required: true },
                                            toolbar: {
                                                toolbarTitle: "Începutul călătoriei",
                                                toolbarPlaceholder: "__",
                                                toolbarFormat: "DD.MM.YYYY",
                                                hidden: false,
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            </div>

                            <div className="mbfield">
                            <FormControl fullWidth required>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ro"} >
                                    <DatePicker    
                                        label="Sfârșitul călătoriei"
                                        format="DD.MM.YYYY" 
                                        minDate={startDate ? startDate.add(1, "day") : null}                              
                                        value={endDate}
                                        disablePast={true}      
                                        onChange={(newValue) => { handleEndDateChange(newValue); }}
                                        slotProps={{ 
                                            textField: { variant: 'outlined', required: true },
                                            toolbar: {
                                                toolbarTitle: "Sfârșitul călătoriei",
                                                toolbarPlaceholder: "__",
                                                toolbarFormat: "DD.MM.YYYY",
                                                hidden: false,
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            </div>    

                            <div className="mbfield">
                            <FormControl fullWidth required>
                                <InputLabel>Scopul călătoriei</InputLabel>
                                    <Select 
                                        label="Scopul călătoriei"
                                        value={travelScope} onChange={handleTravelScopeChange}>
                                            <MenuItem value="tourism">Turism</MenuItem>
                                            <MenuItem value="work">Muncă</MenuItem>
                                            <MenuItem disabled>Sport</MenuItem>
                                            <MenuItem value="inot">&nbsp;&nbsp;&nbsp;&nbsp;Înot</MenuItem>
                                            <MenuItem value="gimnYachting">&nbsp;&nbsp;&nbsp;&nbsp;Gimnastică artistică, Yachting</MenuItem>
                                            <MenuItem value="atleticaHaltere">&nbsp;&nbsp;&nbsp;&nbsp;Atletică ușoară, Haltere</MenuItem>
                                            <MenuItem value="ciclism">&nbsp;&nbsp;&nbsp;&nbsp;Ciclism</MenuItem>
                                            <MenuItem value="sarituriAcvatic">&nbsp;&nbsp;&nbsp;&nbsp;Sărituri în apă, Sport subacvatic</MenuItem>
                                            <MenuItem value="teamSport">&nbsp;&nbsp;&nbsp;&nbsp;Sporturi de echipă</MenuItem>
                                            <MenuItem value="montanEcvestru">&nbsp;&nbsp;&nbsp;&nbsp;Schi montan, Sport ecvestru</MenuItem>
                                            <MenuItem value="turismMontan">&nbsp;&nbsp;&nbsp;&nbsp;Turism montan</MenuItem>
                                            <MenuItem value="planerism">&nbsp;&nbsp;&nbsp;&nbsp;Planerism, zbor cu parapanta, parașutism</MenuItem>
                                            <MenuItem value="alpinismSchi">&nbsp;&nbsp;&nbsp;&nbsp;Alpinism, Sărituri cu schiurile</MenuItem>
                                            <MenuItem value="raftingMartiale">&nbsp;&nbsp;&nbsp;&nbsp;Rafting, Arte marțiale</MenuItem>
                                            <MenuItem value="autoMoto">&nbsp;&nbsp;&nbsp;&nbsp;Curse Auto/Moto</MenuItem>
                                    </Select>
                            </FormControl>
                            </div>      

                            <div className="mbfield">
                            <FormControl fullWidth required>
                                <InputLabel>Suma asigurată</InputLabel>
                                <Select
                                  label="Suma asigurata"
                                  value={insuranceType}
                                  onChange={handleInsuranceTypeChange}
                                >
                                  {!(country === 6 || country === 7 || country === 8) && ( <MenuItem value={30000}>30000 €</MenuItem> )}
                                  <MenuItem value={50000}>50000 €</MenuItem>
                                </Select>
                            </FormControl>
                            </div>

                            <div className="mbfield">
                            <FormControl fullWidth required>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ro"} >
                                    <DateField    
                                        required
                                        label="Data nașterii"
                                        format="DD.MM.YYYY"                                    
                                        value={birthday}      
                                        maxDate={dayjs()}
                                        onChange={(newValue) => { handleDateChange(newValue); }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            </div>

                            <FormControl>
                            <FormControlLabel
                                control={<Checkbox onChange={handleCovid19Change} />}
                                label={
                                  <p>Include acoperire risc COVID-19</p>
                                }
                              />
                            </FormControl>

                            {(  birthdayChanged === "true" || 
                            startDateChanged === "true" ||
                            endDateChanged === "true" ||
                            countryChanged === "true" ||
                            travelScopeChanged === "true" ||
                            insuranceTypeChanged === "true" ||
                            includeCovid19Changed === "true" ||
                            price === 0
                            ) && (
                            <div align="center">
                                <Button type="submit" color="error" variant="contained" sx={{ mt: '1rem' }}>
                                    Calculează prețul
                                </Button>
                            </div>
                            )}

                            {price > 0 && (
                                <div className="boxresult">
                                    <Alert severity="success">
                                    <div>Prețul asigurării: <b>{price} LEI</b> ({((price / exchangeRate).toFixed(2))}€) </div>
                                    </Alert>
                                </div>
                            )}

                            {(  birthdayChanged !== "true" && birthdayChanged !== '' && 
                                startDateChanged !== "true" && startDateChanged !== '' &&
                                endDateChanged !== "true" && endDateChanged !== '' &&
                                countryChanged !== "true" && countryChanged !== '' &&
                                travelScopeChanged !== "true" && travelScopeChanged !== '' &&
                                insuranceTypeChanged !== "true" && insuranceTypeChanged !== '' &&
                                includeCovid19Changed !== "true" && includeCovid19Changed !== '' &&
                                price !== ""     
                                ) && (
                                <div align="center">                               
                                    <Button onClick={handleDetails} variant="contained" sx={{ mt: '1rem' }}>
                                        Plasează comandă
                                    </Button>                                     
                                </div>    
                            )}
                        </form>                       

                        <Dialog open={open} onClose={() => setOpen(false)}>
  <DialogTitle  sx={{ textAlign: 'center' }}><h1 >{price} Lei</h1> {((price / exchangeRate).toFixed(2))}€</DialogTitle>
  <DialogContent  sx={{ textAlign: 'center' }}>
    <DialogContentText>
      
      Comandă la telefon
      <div>
      <a href="tel:+37361111101">
  <Button type="button" variant="contained" color="error" size="large">
    061 111 101
  </Button>
</a>
</div>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <IconButton>
    <Link href="viber://chat?number=%2b37361111101">
      <img src={Vibericon} alt="Viber" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://wa.me/37361111101">
      <img src={Whatsappicon} alt="WhatsApp" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://t.me/+37361111101">
      <img src={Telegramicon} alt="Telegram" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://m.me/asig24">
      <img src={Messengericon} alt="Messenger" style={{ height: '45px' }} />
    </Link>
  </IconButton>
</div>
      sau
      <div>
      <Button onClick={handleDetails}   variant="contained" size="large">
                                            COMANDĂ ONLINE
                                        </Button>
                                        </div>
      </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ pr: '8px' }}>
    <Button
      onClick={() => setOpen(false)}
      color="inherit"
      sx={{
        position: 'absolute',
        top: '1px',
        right: '1px',
      }}
    >
      X
    </Button>
  </DialogActions>
</Dialog>



                    </div>
                </div>
            <Footer /> 
       
        </div>
    );
}