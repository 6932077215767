import React from "react";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"

export default function GreenCardTerms() {
  return (
    <div>
      <Nav />
      <div className="parent">
                <div className="condText">
                <h1>Condiţii de asigurare obligatorie de răspundere civilă auto externă</h1>
                    <br></br>

                    <div>
<p><strong>I. OBIECTUL ASIGURĂRII</strong></p>
<p>Asigurătorul, &icirc;n baza prezentului contract, &icirc;ncheiat &icirc;n conformitate cu prevederile Legii nr.414-XVI din 22.12.2006 &bdquo;Cu privire la asigurarea obligatorie de răspundere civilă pentru pagube produse de autovehicule" cu persoane fizice şi juridice (numite &icirc;n continuare Asiguraţi) av&icirc;nd calitatea de posesori ai autovehiculului(elor) indicat(e) &icirc;n cererea de asigurare, se obligă, &icirc;n schimbul primei de asigurare &icirc;ncasate, să plătească persoanelor terţe păgubite, despăgubire de asigurare pentru daune cauzate &icirc;n rezultatul accidentelor de autovehicul produse &icirc;n afara teritoriului Republicii Moldova.</p>
</div>
<div>
<p>&nbsp;</p>
<p><strong>II. RISCURI ASIGURATE</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>2.1 Asigurătorul acordă despăgubiri pentru:</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>a) sumele pe care Asiguratul este obligat să le plătească cu titlu de despăgubire şi cheltuieli de judecată pentru prejudiciile de care răspunde &icirc;n baza legii civile din ţara pe teritoriul căreia s-a produs accidentul de autovehicul faţă de terţe persoane păgubite printr-un accident produs cu autovehiculul pentru care s-a &icirc;ncheiat asigurarea, ca urmare a vătămării corporale sau a decesului, precum şi a avarierii ori a distrugerii unor bunuri;</li>
<li>b) cheltuielile făcute de Asigurat &icirc;n procesul civil, dacă a fost obligat la despăgubire (inclusiv &icirc;n cazul &icirc;n care acţiunea civilă răm&icirc;ne &icirc;n competenţa instanţei penale, de exemplu, &icirc;n cazul aplicării amnistiei): cheltuielile pentru onorariul de avocat, taxele judiciare şi cheltuielile de expertiză, dovedite cu acte.</li>
</ul>
</li>
<li>2.2 Despăgubirile se acordă pentru pagubele produse ca urmare a avarierii ori distrugerii bunurilor care se aflau &icirc;n afara autovehiculului care a produs accidentul, iar pentru bunurile aflate &icirc;n acel autovehicul, numai dacă ele nu erau transportate &icirc;n baza unui raport contractual &icirc;ncheiat cu Asiguratul, precum şi dacă nu aparţineau posesorului sau utilizatorului răspunzător de accident.</li>
<li>2.3 &Icirc;n caz de vătămare corporală sau deces, despăgubirile de asigurare se acordă at&icirc;t pentru persoanele aflate &icirc;n afara autovehiculului, care a produs accidentul, c&icirc;t şi pentru persoanele aflate &icirc;n acel autovehicul, cu excepţia utilizatorului său.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>III. SUMA ASIGURATĂ. PRIMA DE ASIGURARE</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>3.1 Sumele datorate terţilor urmare a cauzării de prejudiciu prin accident de autovehicul se stabilesc de către Birourile Naţionale ale Asigurătorilor de Autovehicule din ţările pe teritoriul cărora s-a produs accidentul, &icirc;n condiţiile şi &icirc;n limitele stabilite prin Legea privind asigurarea obligatorie de răspundere civilă auto &icirc;n vigoare la data accidentului &icirc;n ţara &icirc;n care acesta s-a produs.</li>
<li>3.2 Prima de asigurare reprezintă suma pe care Asiguratul se obligă să o plătească Asigurătorului pentru preluarea de către acesta a riscului &icirc;n asigurare.</li>
<li>3.3 Primele de asigurare se plătesc anticipat şi integral pentru &icirc;ntreaga perioadă de asigurare. Conform prezentului contract, prima de asigurare constituie _________euro/__________lei, care se plăteşte la data de __________</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>IV. &Icirc;NCHEIEREA CONTRACTULUI</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>4.1 Contractul de asigurare se &icirc;ncheie pe baza cererii scrise a Asiguratului, care, &icirc;mpreună cu toate celelalte declaraţii scrise ale acestuia, fac parte integrantă din prezentul contract.</li>
<li>4.2 Contractul de asigurare se consideră &icirc;ncheiat prin plata primelor de asigurare, emiterea poliţei de asigurare Carte Verde şi este valabil exclusiv pentru autovehiculul specificat &icirc;n poliţa de asigurare şi riscurile indicate &icirc;n prezentul contract.</li>
<li>4.3 &Icirc;n raporturile internaţionale, poliţa de asigurare Carte Verde este singurul document, care atestă valabilitatea teritorială a asigurării şi perioada de valabilitate a acoperirii prin asigurare.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>V. &Icirc;NCEPUTUL ŞI DURATA ASIGURĂRII</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>5.1. Contractul de asigurare produce efecte &icirc;n interiorul termenului prevăzut &icirc;n poliţa de asigurare Carte Verde eliberată &icirc;n baza prezentului contract, şi exclusiv după plata integrală a primei de asigurare.</li>
<li>5.2 Răspunderea Asigurătorului &icirc;ncepe &icirc;n momentul ieşirii autovehiculului de pe teritoriul Republicii Moldova şi &icirc;ncetează &icirc;n</li>
<li>5.3 Prezentul contract este &icirc;ncheiat pe termen de ___zile de la data ________ p&acirc;nă la ____________</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>VI. VALABILITATEA TERITORIALĂ</strong></p>
<p>Prezentul contract de asigurare produce efecte &icirc;n afara teritoriului Republicii Moldova, numai &icirc;n ţările membre ale Sistemului Internaţional de Asigurare Carte Verde ale căror coduri internaţionale &icirc;nscrise &icirc;n poliţa de asigurare Carte Verde s&icirc;nt nebarate.</p>
</div>
<div>
<p>&nbsp;</p>
<p><strong>VII. PAGUBE CARE NU SE REPARĂ DE ASIGURĂTOR</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>7.1 Asigurătorul nu acordă despăgubiri pentru:</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>a) amenzile de orice fel şi cheltuielile penale, suportate de asigurat, precum şi cheltuielile de executare a hotăr&icirc;rilor privind plata despăgubirilor;</li>
<li>b) sumele pe care asiguratul ar avea să le plătească drept despăgubiri conducătorului autovehiculului care a produs accidentul;</li>
<li>c) sumele pe care conducătorul autovehiculului care a produs accidentul este obligat să le plătească Asiguratului;</li>
<li>d) cheltuielile făcute &icirc;n procesul penal de Asigurat sau de conducătorul autovehiculului, răspunzători de producerea pagubei, chiar daca odată cu acţiunea penală s-a soluţionat şi latura civilă</li>
<li>e) partea de despăgubire care depăşeşte limitele şi condiţiile stabilite prin legea de asigurare obligatorie de răspundere civilă auto &icirc;n vigoare la data accidentului &icirc;n ţara producerii acestuia;</li>
<li>f) cazurile care nu intră sub incidenţa legii de asigurare obligatorie de răspundere civila auto &icirc;n vigoare la data accidentului &icirc;n ţara &icirc;n care acesta s-a produs;</li>
<li>g) pagubele produse de energia nucleară sau ca urmare a exploziei atomice, radiaţiilor sau infestării radioactive;</li>
<li>h) pagubele produse ca urmare a deţinerii, operării, &icirc;ntreţinerii sau folosirii oricărui autovehicul pentru transportul unor produse periculoase şi anume:</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>- explozibili puternici cum ar fi: nitroglicerina, dinamita sau alte asemenea substanţe explozibile;</li>
<li>- petrolul, benzina sau motorina &icirc;n vrac (pagubele produse ca urmare a transportului de carburanţi cu cisterne / vehicule specializate, proiectate, construite şi destinate unor astfel de transporturi nu s&icirc;nt excluse);</li>
<li>- gaze sau amestecuri de gaze &icirc;n stare lichidă, comprimată sau gazoasă;</li>
</ul>
</li>
<li>i) pagubele produse ca urmare a războiului, războiului civil, grevelor, revoltelor, mişcărilor populare, actelor de terorism;</li>
<li>j) pagubele produse de vehicule ale forţelor armate, altele dec&icirc;t cele folosite pentru transportul personalului militar ne&icirc;narmat;</li>
<li>k) pierderi sau daune produse bunurilor transportate sau tractate cu autovehiculul asigurat, inclusiv in baza unui raport contractual;</li>
<li>l) pagubele produse de vehicule comerciale folosite &icirc;n aeroporturi sau c&icirc;mpuri de zbor;</li>
<li>m) pagubele produse ca urmare a unor concursuri (inclusiv curse), raliuri, probe pentru curse şi teste de siguranţă;</li>
<li>n) pagubele produse de instalaţii, echipamente sau utilaje de construcţii care nu se află pe drumurile publice;</li>
<li>o) pagubele produse de vehicule ce nu s&icirc;nt destinate să circule pe drumurile publice.</li>
</ul>
</li>
<li>7.2 Asigurătorul nu va fi răspunzător faţă de Asigurat, inclusiv prepuşii acestuia, faţă de utilizatorul sau faţă de conducătorul autovehiculului pentru care s-a &icirc;ncheiat prezentul contract, pentru nici o daună, pierdere de profit, beneficii nerealizate, ratarea unor ocazii de afaceri şi de consecinţele păstrării, deteriorării, alterării (modificării), folosirii necorespunzătoare sau pierderii poliţei de asigurare &bdquo;Carte Verde&rdquo;.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>VIII. MODIFICAREA ŞI REZILIEREA CONTRACTULUI</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>8.1 Modificarea contractului de asigurare</li>
<li>Modificarea contractului de asigurare se efectuează &icirc;n orice timp &icirc;n interiorul termenului său de valabilitate. Modificarea contractului de asigurare se perfectează &icirc;n formă scrisă prin &icirc;ntocmirea unor acorduri, contracte, addendumuri adiţionale, care, ulterior semnării de către părţi, fac parte integrantă din contractul de asigurare.</li>
<li>8.2 Rezilierea contractului</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>8.2.1 Contractul de asigurare poate fi reziliat de către părţi prin &icirc;ntocmirea &icirc;n scris a unui acord de reziliere, precum şi &icirc;n baza hotăr&icirc;rii instanţei de judecată.</li>
<li>8.2.2 Părţile pot solicita rezilierea contractului doar respect&icirc;nd un termen de preaviz de o lună p&icirc;nă la data propusă spre reziliere.</li>
<li>8.2.3 Rezilierea contractului de asigurare presupune drept consecinţă &icirc;ncetarea protecţiei din asigurare de la data rezilierii şi restituirea de către Asigurător a primelor de asigurare pentru perioada neexpirată a contractului, cu reţinerea cheltuielilor de gestiune.</li>
<li>8.2.4 Asigurătorul este eliberat de obligaţia restituirii primelor de asigurare &icirc;n cazul &icirc;n care p&icirc;nă la reziliere a efectuat deja plăţi de despăgubire sau a primit un aviz de accident &icirc;n care a fost implicat autovehiculul specificat &icirc;n contractul şi poliţa de asigurare Carte Verde.</li>
</ul>
</li>
<li>8.3 &Icirc;ncetarea contractului de asigurare şi nulitatea lui</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>8.3.1 Contractul de asigurare &icirc;ncetează de fapt şi de drept &icirc;n cazurile prevăzute &icirc;n art.10 alin.(1) din Legea nr.414-XVI din 22.12.2006 &bdquo;Cu privire la asigurarea obligatorie de răspundere civilă pentru pagube produse de autovehicule&rdquo;.</li>
</ul>
</li>
<li>8.3.2 Invocarea şi declararea nulităţii contractului de asigurare, precum şi consecinţele declarării nulităţii, este reglementată prin Capitolul III, Titlul III, Cartea &Icirc;nt&icirc;i din Codul Civil (art.216&ndash; 233) al Republicii Moldova.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>IX. OBLIGAŢIILE ASIGURATULUI</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>9.1 Asiguratul este obligat:</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>a) să verifice corectitudinea datelor &icirc;nscrise de către Asigurător &icirc;n poliţa de asigurare Carte Verde;</li>
<li>b) să nu facă nici o modificare şi nici să altereze &icirc;n vre-un mod poliţa de asigurare Carte Verde.</li>
</ul>
</li>
<li>9.2 &Icirc;n caz de producere a riscului asigurat, Asiguratul este obligat:</li>
<li>
<ul class="lg:ml-2 ml-1 list-inside space-y-2">
<li>a) să ia, potrivit cu &icirc;mprejurările, măsuri pentru limitarea pagubelor;</li>
<li>b) să &icirc;nştiinţeze imediat organele poliţiei sau alte organe de cercetare, cele mai apropiate de locul producerii accidentului, cer&icirc;nd &icirc;ntocmirea de acte cu privire la cauzele şi &icirc;mprejurările producerii accidentului şi la pagubele provocate;</li>
<li>c) să &icirc;nştiinţeze ne&icirc;nt&icirc;rziat Biroul Asigurătorilor de Autovehicule prevăzut pe verso-ul poliţei de asigurare Carte Verde, din ţara &icirc;n care s-a produs accidentul;</li>
<li>d) să &icirc;nştiinţeze, &icirc;n scris (prin fax, telex, e-mail), Asigurătorul despre producerea accidentului, &icirc;mprejurările &icirc;n care acesta a intervenit, despre natura pagubelor produse terţilor şi estimarea mărimii acestora, &icirc;n termen de maxim 3 zile lucrătoare de la data producerii accidentului de autovehicul;</li>
<li>e) să pună la dispoziţia Asigurătorului, &icirc;n termen de maxim 4 zile lucrătoare de la sosirea &icirc;n ţară, dar nu mai t&icirc;rziu de 15 zile de la data producerii accidentului, declaraţia conducătorului autovehiculului şi a eventualilor martori c&icirc;t şi orice alte acte primite &icirc;n legătură cu accidentul (de la poliţie, pompieri, procuratură, judecătorii, etc. din ţara &icirc;n care s-a produs accidentul);</li>
<li>f) să se apere &icirc;n caz de proces, ţin&icirc;nd seama şi de eventualele indicaţii scrise ale Asigurătorului.</li>
</ul>
</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>X. CONSTATAREA ŞI EVALUAREA PAGUBELOR. PLATA DESPĂGUBIRILOR</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>10.1. Constatarea producerii evenimentelor asigurate, evaluarea pagubelor, stabilirea cuantumului şi plata despăgubirilor la asigurarea de răspundere civila auto externă se fac de către Birourile Naţionale Carte Verde sau corespondenţii nominalizaţi &icirc;n condiţiile şi &icirc;n limitele stabilite prin legislaţia &icirc;n domeniu &icirc;n vigoare &icirc;n ţara locului accidentului, potrivit procedurilor stabilite prin Regulamentul General al Consiliului Birourilor Carte Verde.</li>
<li>10.2 &Icirc;n cazul &icirc;n care, potrivit legislaţiei locale, Birourile Naţionale Carte Verde nu pot plăti despăgubirile datorate terţelor persoane păgubite dec&icirc;t pe cale judecătorească, Asigurătorul va da instrucţiuni Asiguratului referitor la susţinerea procesului civil şi va achita daunele prevăzute &icirc;n hotăr&icirc;rea judecătorească, precum şi cheltuielile ocazionate de acesta, prevăzute de Regulamentul General al Consiliului Birourilor, inclusiv onorariul avocatului.</li>
<li>10.3 Rambursarea de către Birourile Naţionale Carte Verde sau corespondenţii nominalizaţi a despăgubirilor plătite terţelor persoane păgubite, inclusiv cheltuielile ocazionate &icirc;n străinătate de activităţile privind constatarea, evaluarea şi stabilirea pagubelor, se plătesc de către Asigurător potrivit modului de lucru stabilit prin Regulamentul General al Consiliului Birourilor, &icirc;n valuta &icirc;n care se solicită plata despăgubirii.</li>
</ul>
</div>
<div>
<p>&nbsp;</p>
<p><strong>XI. DISPOZIŢII FINALE</strong></p>
<ul class="lg:ml-4 ml-2 list-inside space-y-2">
<li>11.1 Toate litigiile care derivă din prezentul contract de asigurare se soluţionează &icirc;n procedură extrajudiciară prin &icirc;ncheierea tranzacţiilor de &icirc;mpăcare sau &icirc;n procedură judiciară, după caz.</li>
<li>11.2 Prezentul contract este valabil din momentul &icirc;ncheierii acestuia la eliberarea poliţei de asigurare cu condiţia achitării primelor de asigurare aferente.</li>
<li>11.3 Respectarea riguroasă a obligaţiilor ce-i revin Asiguratului, precum şi presupunerea că declaraţiile şi răspunsurile acestuia la cerere s&icirc;nt adevărate, s&icirc;nt condiţii ce preced orice răspundere ce revine Asigurătorului.</li>
<li>11.4 Prezentul contract este &icirc;ncheiat &icirc;n 2 exemplare, din care unul a fost &icirc;nm&icirc;nat Asiguratului.</li>
</ul>
</div>
                    

</div>
            </div>


      <Footer /> 
    </div>
  );
}