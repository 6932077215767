import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import Divider from '@mui/material/Divider';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import { Button,  FormControl, InputLabel, ListItemIcon, MenuItem, Select, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import DonarisLogo from '../../components/logos/Donaris.png'; 
import MoldasigLogo from '../../components/logos/Moldasig.png'; 
import AsterraLogo from '../../components/logos/Asterra.png';

 

firebase.initializeApp({
  apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
  authDomain: "masig-online.firebaseapp.com",
  projectId: "masig-online",
  storageBucket: "masig-online.appspot.com",
  messagingSenderId: "1064560601004",
  appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
  measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const InputField = styled(TextField)({
    marginBottom: '1rem',
});

const InputField2 = styled(TextField)({
    marginTop: '1rem',
});

export default function GreenCardDdetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [idnx, setIdnx] = useState("");    
    const [vehicleRegistrationCertificateNumber, setVehicleRegistrationCertificateNumber] = useState("");
    const [personIsJuridical, setPersonIsJuridical] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [period, setPeriod] = useState("");
    const [zone, setZone] = useState("");
    const [vehicleType, setVehicleType] = useState("");
    const [price, setPrice] = useState("");
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [amount, setAmount] = useState("");
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("online");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [adress, setAdress] = useState("");
    const [ownership, setOwnership] = useState("");
    const [inssuranceCompany, setInssuranceCompany] = useState("");
    const [startDay, setStartDay] = useState("");
    const startDate = dayjs(startDay, "DD.MM.YYYY");
    const [endDay, setEndDay] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (price < 500 && deliveryMethod === "deliveryOnAdress") {
          setDeliveryPrice(59);
        } else {
          setDeliveryPrice(0);
        }
      }, [price, deliveryMethod]);

      useEffect(() => {
        const amount = Number(price) + Number(deliveryPrice);
        setAmount(Number(amount.toFixed(2)));
      }, [price, deliveryPrice]);
    
  
    useEffect(() => {
        const fetchData = async () => {
        try {
            const doc = await db.collection("rcarequests2").doc(id).get();
            setIdnx(doc.data().idnx);
            setData(doc.data());
            setVehicleRegistrationCertificateNumber(doc.data().vehicleRegistrationCertificateNumber);
            setPersonIsJuridical(doc.data().personIsJuridical);
            setCompanyName(doc.data().companyName);
            setPeriod(doc.data().period);
            setZone(doc.data().zone);
            setPrice(doc.data().price);
            setDeliveryPrice(doc.data().deliveryPrice);
            setAmount(doc.data().amount);
            setName(doc.data().name);
            setPhoneNumber(doc.data().phoneNumber);
            setDeliveryMethod(doc.data().deliveryMethod);
            setEmail(doc.data().email);
            setVehicleType(doc.data().vehicleType);
            setAdress(doc.data().adress);
            setOwnership(doc.data().ownership);
            setInssuranceCompany(doc.data().inssuranceCompany);
            setStartDay(doc.data().startDay);
            setLoading(false);
            } catch (error) {
            console.log(error);
            
            navigate('/'); 
        }
      };
      fetchData();
    }, [id]);

    const calculateEndDay = () => {
        if (startDay !== "" && period !== "") {
          let endDate = dayjs(startDay);
          if (period === "15") endDate = startDate.add(15, 'day');
          else if (period === "30") endDate = startDate.add(1, 'month');
          else if (period === "60") endDate = startDate.add(2, 'month');
          else if (period === "90") endDate = startDate.add(3, 'month');
          else if (period === "120") endDate = startDate.add(4, 'month');
          else if (period === "150") endDate = startDate.add(5, 'month');
          else if (period === "180") endDate = startDate.add(6, 'month');
          else if (period === "210") endDate = startDate.add(7, 'month');
          else if (period === "240") endDate = startDate.add(8, 'month');
          else if (period === "270") endDate = startDate.add(9, 'month');
          else if (period === "300") endDate = startDate.add(10, 'month');
          else if (period === "330") endDate = startDate.add(11, 'month');
          else if (period === "360") endDate = startDate.add(12, 'month');
          setEndDay(endDate.subtract(1, 'day').format('DD.MM.YYYY'));
        }
      };
      
      useEffect(() => {
        calculateEndDay();
      }, [startDay, period]);


    const handleConfirmOrder = async (e) => {
      e.preventDefault();
      try {
        await db.collection("rcarequests2").doc(id).update({
            name,
            phoneNumber,
            deliveryMethod,
            email: email || '',
            adress: adress || '',
            ownership,
            order_confirmed: 'true',
            inssuranceCompany,
            deliveryPrice: deliveryPrice,
            amount,
        });
        navigate(`/GreenCard/${id}`);
        handlePayment();
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await fetch('https://asig24.md/send-cv', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id, idnx, vehicleRegistrationCertificateNumber, startDay, period, zone, vehicleType, price, deliveryPrice, amount, inssuranceCompany, ownership, personIsJuridical, deliveryMethod, name, companyName, phoneNumber, email, adress }),
        });
    
        if (response.ok) {
          // Handle successful submission
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle fetch error
      }

    };
  
    const handlePayment = () => {
      navigate(`/GreenCard/order/${id}`);
    };

    const nameRegex = /^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u1E00-\u1EFF\s]*$/;

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsEmailValid(inputEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail));
    }; 


  return (    
    <div>
        <Helmet>
            <title>Cartea Verde online</title>
            <meta name="description" content="Calculează prețul și cumpără Cartea Verde online. Rapid și sigur cu Asig24.md obții polița Cartea Verde în câteva minute." />
            <meta name="keywords" content="Cartea Verde, Cartea Verde online, asigurări, calculator Cartea Verde, asigurare auto, Cartea Verde Moldova, asigurări online" />
            <meta name="author" content="Asig24.md" />
            <meta property="og:title" content="Cartea Verde online" />
            <meta property="og:description" content="Calculează prețul și cumpără Cartea Verde online. Rapid și sigur cu Asig24.md obții polița Cartea Verde în câteva minute." />
            <meta property="og:url" content="https://www.asig24.md/GreenCard" />
            <meta property="og:type" content="website"></meta>
            <link rel="canonical" href="https://www.asig24.md/GreenCard" />
        </Helmet>        
        <Nav />

            <> {loading ? ( <div align="center" className="loading"><CircularProgress /></div> ) : ( <>

            <div className="parent">
                <div className="forma">


                  
                    <div className="orderdetails">
                        {personIsJuridical === "true" && ( <div className="orderdetailsrow">Asigurat: <b>{companyName} - {idnx}</b></div> )}
                        {personIsJuridical === "false" && ( <div className="orderdetailsrow">Asigurat: <b>{idnx} - persoană fizică</b></div> )}
                        <div className="orderdetailsrow">Certificat de înmatriculare vehicul: <b>{vehicleRegistrationCertificateNumber}</b></div>
                        {zone === "UE" && ( <div className="orderdetailsrow">Zona de deplasare: <b>UE - toate țările sistemului "CARTEA VERDE"</b></div> )}
                        {zone === "UkrBelRus" && ( <div className="orderdetailsrow">Zona de deplasare: <b>Ucraina, Belarus, Rusia</b></div> )}
                        {zone === "UkrBel" && ( <div className="orderdetailsrow">Zona de deplasare: <b>Ucraina, Belarus</b></div> )}
                        <div className="orderdetailsrow">Perioada asigurată: <b>{startDay} - {endDay}</b> {period === "15" && ('(15 zile)')}
                        {period === "30" && ('(1 lună)')}
                        {period === "60" && ('(2 luni)')}
                        {period === "90" && ('(3 luni)')}
                        {period === "120" && ('(4 luni)')}
                        {period === "150" && ('(5 luni)')}
                        {period === "180" && ('(6 luni)')}
                        {period === "210" && ('(7 luni)')}
                        {period === "240" && ('(8 luni)')}
                        {period === "270" && ('(9 luni)')}
                        {period === "300" && ('(10 luni)')}
                        {period === "330" && ('(11 luni)')}
                        {period === "360" && ('(12 luni)')}
                        </div>

                        <Divider  />
                        <br></br>
                        <div className="orderdetailsrow">Prețul asigurării: <b>{price} Lei</b></div>
                        {deliveryPrice > 0 && (<div className="orderdetailsrow">Prețul livrării: <b>{deliveryPrice} Lei</b></div>)}
                        <Divider textAlign="right"><div className="orderdetailsrow"><b>TOTAL: {amount} LEI</b></div></Divider>
                    </div>
        


                    <form onSubmit={handleConfirmOrder}>    
                        
                        <div className="mbfield">
                        <FormControl fullWidth>
                            <InputLabel>Dreptul de posesie asupra vehiculului</InputLabel>
                                <Select 
                                    required
                                    label="Dreptul de posesie asupra vehiculului"          
                                    value={ownership || ''} onChange={(e) => setOwnership(e.target.value)}>
                                        <MenuItem value="owner">Proprietar</MenuItem>
                                        <MenuItem value="leasing">Leasing</MenuItem>
                                        <MenuItem value="others">Procură și alte titluri</MenuItem>                       
                                </Select>
                        </FormControl>
                        </div>

                        <div className="mbfield">
                        <FormControl fullWidth>
                            <InputLabel>Selectați compania de asigurări</InputLabel>
                                <Select 
                                    required
                                    label="Selectați compania de asigurări"          
                                    value={inssuranceCompany || ''} onChange={(e) => setInssuranceCompany(e.target.value)}>
                                        <MenuItem value="Donaris" >
                                        <ListItemIcon className="inslogo">
                                            <img src={DonarisLogo} alt="Donaris" width="24" height="24" />
                                        </ListItemIcon>
                                        Donaris
                                        </MenuItem>
                                        <MenuItem value="Asterra">
                                        <ListItemIcon className="inslogo">
                                            <img src={AsterraLogo} alt="Asterra" width="24" height="24" />
                                        </ListItemIcon>
                                        Asterra
                                        </MenuItem>
                                        <MenuItem value="Moldasig">
                                        <ListItemIcon className="inslogo">
                                            <img src={MoldasigLogo} alt="Moldasig" width="24" height="24" />
                                        </ListItemIcon>
                                        Moldasig
                                        </MenuItem>
                                </Select>
                        </FormControl> 
                        </div> 

                        <InputField
                            required
                            label={ `Nume Prenume${personIsJuridical === "true" ? " persoană de contact" : ""}` }
                            value={name || ''}
                            inputProps={{ maxLength: 50 }}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={ `Nume Prenume${personIsJuridical === "true" ? " persoană de contact" : ""}` }
                            variant="outlined"
                            error={name !== "" && !nameRegex.test(name)}
                            helperText={name !== "" && !nameRegex.test(name) && "Numele poate fi alcătuit doar din litere și spații"}
                            fullWidth
                        />

                        <InputField
                            required
                            label="Număr de telefon"
                            value={phoneNumber || ''}
                            inputProps={{ 
                                minLength: 8, 
                                maxLength: 15, 
                                pattern: '^\\+?[0-9]{7,14}$',
                                type: 'tel',
                                inputMode: 'tel',                            
                            }}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="+373 69******"
                            variant="outlined" 
                            fullWidth
                        />

                        <FormControl fullWidth>
                            <InputLabel>Metoda de livrare</InputLabel>
                                <Select 
                                    required
                                    label="Metoda de livrare"          
                                    value={deliveryMethod || ''} onChange={(e) => setDeliveryMethod(e.target.value)}>
                                        <MenuItem value={"online"}>On-line (Email, Viber, etc.)</MenuItem>
                                        <MenuItem value={"fromOffice"}>Ridicare din oficiu</MenuItem>
                                        <MenuItem value={"deliveryOnAdress"}>Livrare la domiciliu</MenuItem>                        
                                </Select>
                        </FormControl>  

                        {deliveryMethod === "online" && (
<div className="cvtxt">Atenție! Este important să deții Cartea Verde pe suport de hârtie în original. În unele țări, autoritățile pot solicita prezentarea fizică a poliței. Recomandăm să ridicați polița din oficiul nostru, ori cu livrare la domiciliu.</div>
)}

                        {deliveryMethod === "online" && (
    <InputField2
      label="Email (opțional)"
      value={email || ''}
      inputProps={{ maxLength: 50 }}
      onChange={handleEmailChange}
      placeholder="Email (opțional)"
      variant="outlined"
      error={!isEmailValid}
      helperText={!isEmailValid ? "Email-ul introdus nu este valid" : ""}
      fullWidth
    />
)}


                        {deliveryMethod === "deliveryOnAdress" && (
                        <InputField2
                            label="Adresa livrare"
                            value={adress  || ''}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => setAdress(e.target.value)}
                            placeholder="Adresa livrare"
                            variant="outlined"
                            fullWidth
                            required
                        />
                        )}

                        
                        <br />
                        <br />
                        <div align="center" className="placeorderbutton">
                            <Button variant="contained" type="submit">Confirmă comanda</Button>
                        </div>
                    </form>
                        
                </div>
            </div>
        <Footer /> 
        </> )} </>
    </div>   
  
  );
}