import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import { Button,  FormControl, InputLabel, MenuItem, InputAdornment, OutlinedInput, Popover, ListItemIcon, Select, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alert from "@mui/material/Alert";
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import comertbankLogo from '../../components/logos/comertbank.png'; 
import energbankLogo from '../../components/logos/energbank.png'; 
import eximbankLogo from '../../components/logos/eximbank.png'; 
import fincombankLogo from '../../components/logos/fincom.png'; 
import maibLogo from '../../components/logos/maib.png'; 
import micbLogo from '../../components/logos/micb.png'; 
import otpLogo from '../../components/logos/otp.png'; 
import procreditLogo from '../../components/logos/procredit.png'; 
import vbLogo from '../../components/logos/vb.png'; 
import eurocreditLogo from '../../components/logos/eurocredit.png'; 
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import Vibericon from '../../components/icons/vibericon.png';
import Whatsappicon from '../../components/icons/whatsappicon.png';
import Telegramicon from '../../components/icons/telegramicon.png';
import Messengericon from '../../components/icons/messengericon.png';


firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const generateDocId = () => {
    // Generate 5 random digits
    const randomDigits = Math.floor(1000000 + Math.random() * 9999999);
  
    // Generate 3 random letters
    const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
    const randomLetters = letters.charAt(Math.floor(Math.random() * letters.length)) +
                            letters.charAt(Math.floor(Math.random() * letters.length)) + 
                            letters.charAt(Math.floor(Math.random() * letters.length));
  
    // Combine digits and letters to form docId
    const docId = "IP" + randomDigits + randomLetters;
  
    return docId;
};

export default function Ipoteca() {
    const [imobilType, setImobilType] = useState(""); 
    const [imobilTypeChanged, setImobilTypeChanged] = useState(""); 
    const [insuredBase, setInsuredBase] = useState(""); 
    const [insuredBaseChanged, setInsuredBaseChanged] = useState(""); 
    const [imobilValue, setImobilValue] = useState(""); 
    const [imobilValueChanged, setImobilValueChanged] = useState(""); 
    const [currency, setCurrency] = useState('MDL');
    const [currencyTypeChanged, setCurrencyTypeChanged] = useState(""); 
    const [startDay, setStartDay] = React.useState(null);
    const [beneficiar, setBeneficiar] = useState(""); 
    const [exchangeRate, setExchangeRate] = useState(null);
    const [exchangeRateUsd, setExchangeRateUsd] = useState(null);
    const [price, setPrice] = useState(0);
    const [uid, setUid] = useState("");
    const [open, setOpen] = useState(false);


    useEffect(() => {
        auth.signInAnonymously()
          .then((userCredential) => {
            // Store the user's UID locally
            setUid(userCredential.user?.uid);
          })
          .catch((error) => {
            console.log(error);
          });
    }, []); 

    const date = new Date();
    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });

    const handleCurrencyChange = (event) => {
        setCurrency(event.target.value);
        setCurrencyTypeChanged("true")
      };

    const handleImobilTypeChange = (event) => {
        setImobilType(event.target.value);
        setImobilTypeChanged("true");
    }; 

    const handleInsuredBaseChange = (event) => {
        setInsuredBase(event.target.value);
        setInsuredBaseChanged("true");
    };

    const handleImobilValueChange = (event) => {
        setImobilValue(event.target.value);
        setImobilValueChanged("true");
    };

    const handleDateChange = (date) => {
        setStartDay(date);
    };

    useEffect(() => {
        async function fetchExchangeRate() {
          const response = await fetch('https://asig24.md/exchange-rate-eur');
          const data = await response.json();
          setExchangeRate(data.exchangeRate);
        }
        fetchExchangeRate();
      }, []);

      useEffect(() => {
        async function fetchExchangeRateUsd() {
          const response = await fetch('https://asig24.md/exchange-rate-usd');
          const data = await response.json();
          setExchangeRateUsd(data.exchangeRateUsd);
        }
        fetchExchangeRateUsd();
      }, []);


      const calculatePrice = async (event) => {
        event.preventDefault();
        setOpen(true);
        setCurrencyTypeChanged("false");
        setImobilTypeChanged("false");
        setImobilValueChanged("false");
        if (!imobilValue) return;
      
        let valueInMDL;
      
        if (currency === 'EUR' && exchangeRate) {
          valueInMDL = parseFloat(imobilValue) * exchangeRate;
        } else if (currency === 'USD' && exchangeRateUsd) {
          valueInMDL = parseFloat(imobilValue) * exchangeRateUsd;
        } else {
          valueInMDL = parseFloat(imobilValue);
        }
      
        const price = valueInMDL * 0.001;
        setPrice(price);

        const docId = generateDocId();
      
          try {
            await db.collection("rcarequests2").doc(docId).set({
              product_type: "Ipoteca",
              price: price.toFixed(2),
              datetime: formattedDate,
              startDay: dayjs(startDay).format("DD.MM.YYYY"),
              imobilType,
              insuredBase,
              imobilValue: `${imobilValue} ${currency}`,
              bancBeneficiar: beneficiar,
              uid: auth.currentUser.uid,
            });
      
            console.log("Document written with ID: ", docId);
            localStorage.setItem("docId", docId);
            localStorage.setItem("uid", uid);
          } catch (error) {
            console.error("Error adding document: ", error);
          }
      };

      const handleDetails = async () => {
        try {
          // Get the document ID from the previous submission
          const docId = localStorage.getItem("docId");
      
          // Get the document data from Firestore
          const doc = await db.collection("rcarequests2").doc(docId).get();
      
          // Redirect to the details page, passing the encoded data as a parameter
          window.location.href = `./Ipoteca/${docId}`;
        } catch (error) {
          console.log(error);
        }
    };


    return (
        <div>
          <Helmet>
                <title>Asigurare pentru Ipotecă și bunuri imobile</title>
                <meta name="description" content="Calculează prețul și cumpără online asigurare pentru ipotecă și bunuri imobile. Rapid și sigur cu Asig24.md obții polița de asigurare în câteva minute." />
                <meta name="keywords" content="asigurare ipoteca, asigurare bunuri, calculator asigurare ipoteca, cumpără asigurare imobil ipoteca online, asigurări de sănătate, asigurări online" />
                <meta name="author" content="Asig24.md" />
                <meta property="og:title" content="Asigurare pentru Ipotecă și bunuri imobile" />
                <meta property="og:description" content="Calculează prețul și cumpără online asigurare medicală facultativă. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta property="og:url" content="https://www.asig24.md/Ipoteca" />
                <meta property="og:type" content="website"></meta>
                <link rel="canonical" href="https://www.asig24.md/Ipoteca" />
            </Helmet>
            <Nav />
            <div className="parent">
                <div className="forma">      

                <div className="card" id="ipoteca"  style={{ marginBottom: '1rem' }}>
  <div className="icon icon-ipoteca"></div>
    <div className="card-content">
      <h2>Ipotecă</h2>
      <p>Asigurarea bunului imobil oferă protecție financiară împotriva daunelor cauzate de incendii, inundații, cutremure și alte riscuri, asigurând securitatea și acoperirea costurilor de reparare sau reconstrucție a proprietății tale.</p>
      </div>
  </div>

                    <form onSubmit={calculatePrice}>

                        <div className="mbfield">
                        <FormControl fullWidth required>
                            <InputLabel>Tip imobil</InputLabel>
                                <Select 
                                    label="Tip imobil"
                                    value={imobilType} onChange={handleImobilTypeChange}>
                                        <MenuItem value={"incapere"}>Încăpere (apartament, oficiu)</MenuItem>
                                        <MenuItem value={"constructie"}>Construcție (casă, clădire)</MenuItem>
                                        <MenuItem value={"teren"}>Teren</MenuItem>
                                        <MenuItem value={"comercial"}>Comercial</MenuItem>
                                </Select>
                        </FormControl>
                        </div>

                        <div className="mbfield">
                        <FormControl fullWidth required>
                            <InputLabel>Asigurare în baza la</InputLabel>
                                <Select 
                                    label="Asigurare în baza la"
                                    value={insuredBase} onChange={handleInsuredBaseChange}>
                                        <MenuItem value={"inlocuire"}>Valoarea de înlocuire</MenuItem>
                                        <MenuItem value={"piata"}>Valoarea de piață</MenuItem>
                                </Select>
                        </FormControl>
                        </div>   


                        <div className="mbfield">
                        <TextField
                            id="outlined-adornment-amount"
                            label="Valoarea MDL, EUR, USD"
                            value={imobilValue}
                            onChange={handleImobilValueChange}
                            inputProps={{ minLength: 4, maxLength: 15, pattern: '[0-9]*' }}
                            variant="outlined"
                            fullWidth
                            required
                            SelectProps={{
                              value: currency,
                              onChange: handleCurrencyChange,
                              displayEmpty: true,
                              renderValue: () => '',
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end" style={{ marginRight: '-14px' }}>
                                  <Select
                                    value={currency}
                                    onChange={handleCurrencyChange}
                                    sx={{
                                      border: 'none',
                                      '& .MuiSelect-select': {
                                        paddingRight: 0,
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                      },
                                    }}
                                  >
                                    <MenuItem value="MDL">MDL</MenuItem>
                                    <MenuItem value="EUR">EUR</MenuItem>
                                    <MenuItem value="USD">USD</MenuItem>
                                  </Select>
                                </InputAdornment>
                              ),
                            }}
                        />
                        </div>

                        <div className="mbfield">
                        <FormControl fullWidth required>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ro"} >
                                <DatePicker    
                                    label="Data începerii asigurării"
                                    disablePast={true}
                                    format="DD.MM.YYYY"
                                    value={startDay}      
                                    onChange={(newValue) => { handleDateChange(newValue); }}
                                    slotProps={{ 
                                        textField: { variant: 'outlined', required: true },
                                        toolbar: {
                                            toolbarTitle: "Selectați data",
                                            toolbarPlaceholder: "__",
                                            toolbarFormat: "DD.MM.YYYY",
                                            hidden: false,
                                        }
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl>
                        </div>

                        <div className="mbfield">
                        <FormControl fullWidth required>
                            <InputLabel>Organizația beneficiară</InputLabel>
                                <Select 
                                    label="Organizația beneficiară"
                                    value={beneficiar} onChange={(event) => setBeneficiar(event.target.value)}>
                                        <MenuItem value={"BC COMERTBANK S.A."}><ListItemIcon className="inslogo"><img src={comertbankLogo} alt="" width="24" height="24" /></ListItemIcon>BC COMERTBANK S.A.</MenuItem>
                                        <MenuItem value={"BC ENERGBANK S.A."}><ListItemIcon className="inslogo"><img src={energbankLogo} alt="" width="24" height="24" /></ListItemIcon>BC ENERGBANK S.A.</MenuItem>
                                        <MenuItem value={"BC EUROCREDITBANK S.A."}><ListItemIcon className="inslogo"><img src={eurocreditLogo} alt="" width="24" height="24" /></ListItemIcon>BC EUROCREDITBANK S.A.</MenuItem>
                                        <MenuItem value={"BC EXIMBANK S.A."}><ListItemIcon className="inslogo"><img src={eximbankLogo} alt="" width="24" height="24" /></ListItemIcon>BC EXIMBANK S.A.</MenuItem>
                                        <MenuItem value={"BC MOLDINDCONBANK S.A."}><ListItemIcon className="inslogo"><img src={micbLogo} alt="" width="24" height="24" /></ListItemIcon>BC MOLDINDCONBANK S.A.</MenuItem>
                                        <MenuItem value={"BC MAIB S.A."}><ListItemIcon className="inslogo"><img src={maibLogo} alt="" width="24" height="24" /></ListItemIcon>BC MAIB S.A.</MenuItem>
                                        <MenuItem value={"BC PROCREDIT BANK S.A."}><ListItemIcon className="inslogo"><img src={procreditLogo} alt="" width="24" height="24" /></ListItemIcon>BC PROCREDIT BANK S.A.</MenuItem>
                                        <MenuItem value={"BC VICTORIABANK S.A."}><ListItemIcon className="inslogo"><img src={vbLogo} alt="" width="24" height="24" /></ListItemIcon>BC VICTORIABANK S.A.</MenuItem>
                                        <MenuItem value={"BC FINCOMBANK S.A."}><ListItemIcon className="inslogo"><img src={fincombankLogo} alt="" width="24" height="24" /></ListItemIcon>BC FINCOMBANK S.A.</MenuItem>
                                        <MenuItem value={"BC OTP-BANK S.A."}><ListItemIcon className="inslogo"><img src={otpLogo} alt="" width="24" height="24" /></ListItemIcon>BC OTP-BANK S.A.</MenuItem>
                                        <MenuItem value={"other"}>Alta</MenuItem>
                                </Select>
                        </FormControl>
                        </div>

                        {( currencyTypeChanged === "true" || 
                                imobilTypeChanged === "true" ||
                                imobilValueChanged === "true" ||
                                price === 0
                            ) && (
                            <div align="center">
                                <Button type="submit" color="error" variant="contained" sx={{ mt: '1rem' }}>
                                    Calculează prețul
                                </Button>
                            </div>
                        )}

                        {price > 0 && (
                            <div className="boxresult">
                                <Alert severity="success">
                                    {currency === 'MDL' && (<div>Prețul asigurării: <b>{price.toFixed(2)} MDL</b></div>)}
                                    {currency === 'EUR' && (<div>Prețul asigurării: <b>{price.toFixed(2)} MDL</b> ({(price / exchangeRate).toFixed(2)} EUR)</div>)}
                                    {currency === 'USD' && (<div>Prețul asigurării: <b>{price.toFixed(2)} MDL</b> ({(price / exchangeRateUsd).toFixed(2)} USD)</div>)}
                                </Alert>
                            </div>
                        )}

                        {(  currencyTypeChanged !== "true" && currencyTypeChanged !== '' && 
                            imobilTypeChanged !== "true" && imobilTypeChanged !== '' &&
                            imobilValueChanged !== "true" && imobilValueChanged !== '' &&
                            price !== ""     
                            ) && (
                            <div align="center">                               
                                <Button onClick={handleDetails} variant="contained" sx={{ mt: '1rem' }}>
                                    Plasează comandă
                                </Button>                                     
                            </div>    
                        )}

                    </form>


                    

                    <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle sx={{ textAlign: 'center' }}>
  <h1>{price.toFixed(2)} Lei</h1> 
  {currency === 'EUR' && <span>{(price / exchangeRate).toFixed(2)} €</span>}
  {currency === 'USD' && <span>{(price / exchangeRateUsd).toFixed(2)} $</span>}
</DialogTitle>
  <DialogContent  sx={{ textAlign: 'center' }}>
    <DialogContentText>
      
      Comandă la telefon
      <div>
      <a href="tel:+37361111101">
  <Button type="button" variant="contained" color="error" size="large">
    061 111 101
  </Button>
</a>
</div>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <IconButton>
    <Link href="viber://chat?number=%2b37361111101">
      <img src={Vibericon} alt="Viber" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://wa.me/37361111101">
      <img src={Whatsappicon} alt="WhatsApp" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://t.me/+37361111101">
      <img src={Telegramicon} alt="Telegram" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://m.me/asig24">
      <img src={Messengericon} alt="Messenger" style={{ height: '45px' }} />
    </Link>
  </IconButton>
</div>
      sau
      <div>
      <Button onClick={handleDetails}   variant="contained" size="large">
                                            COMANDĂ ONLINE
                                        </Button>
                                        </div>
      </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ pr: '8px' }}>
    <Button
      onClick={() => setOpen(false)}
      color="inherit"
      sx={{
        position: 'absolute',
        top: '1px',
        right: '1px',
      }}
    >
      X
    </Button>
  </DialogActions>
</Dialog>


                </div>
            </div>

            <Footer />       
        </div>
    );
}