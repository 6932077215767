import React from "react";
import { Helmet } from "react-helmet-async";
import Nav from "../../components/navigation/Nav.js"
import Hero from "../../components/hero/Hero.js";
import Cards from "../../components/servicecards/Cards.js";
import Blog from "../../components/blogs/Blog.js";
import FAQ from "../../components/faq/FAQ.js";
import Footer from "../../components/footer/Footer.js";

export default function Home() {
  return (
    <div >
      <Helmet>
        <title>Asigurări online - RCA, Cartea Verde, CASCO, Călătorie</title>
        <meta name="description" content="Calculează online prețul asigurării RCA, Cartea Verde, CASCO, Asigurare medicală, bunuri imobile. Livrare gratuită +37361111101" />
  <meta name="keywords" content="asigurări, RCA, Cartea Verde, CASCO, asigurare călătorie, asigurări online, calculator RCA, pret RCA, pret asigurare, asigurare Moldova" />
  <meta name="author" content="Asig24.md" />
  <meta property="og:title" content="Asigurări online - RCA, Cartea Verde, CASCO, Călătorie" />
  <meta property="og:description" content="Calculează online prețul asigurării RCA, Cartea Verde, CASCO, Asigurare medicală, bunuri imobile. Livrare gratuită +37361111101" />
  <meta property="og:url" content="https://www.asig24.md" />
  <meta property="og:type" content="website"></meta>
  <link rel="canonical" href="https://www.asig24.md/" />
      </Helmet>

      <Nav />
       
      <Hero />

      <Cards />

      <Blog />

      <FAQ />

      <Footer />
    </div>
  );
}