import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Nav from "../../../components/navigation/Nav.js"
import Footer from "../../../components/footer/Footer.js"
import { useParams, useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import dayjs from 'dayjs';
import { Button,  FormControl, InputLabel, ListItemIcon, MenuItem, Select, TextField } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import CashIcon from '../../../components/icons/cash.png'; 
import CardIcon from '../../../components/icons/card.png'; 
import ATMIcon from '../../../components/icons/atm.png';
import axios from 'axios'; 
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import logo from '../../../components/icons/logo.png';
import { PDFDownloadLink, PDFViewer, Document, Page, Text, StyleSheet } from '@react-pdf/renderer';
import { BlobProvider } from '@react-pdf/renderer';

const getUserIpAddress = async () => { 
    try { 
      const response = await axios.get('https://api.ipify.org/?format=json'); 
      const { ip } = response.data; 
      return ip; 
    } catch (error) { 
      console.error(error); 
      return ''; 
    } 
  }; 


firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

export default function MedicalaCalatoriePay() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [idnx, setIdnx] = useState("");    
    const [price, setPrice] = useState("");
    const [deliveryPrice, setDeliveryPrice] = useState("");
    const [amount, setAmount] = useState("");
    const [period, setPeriod] = useState("");
    const [name, setName] = useState("");
    const [zone, setZone] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [passNumber, setPassNumber] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("online");
    const [email, setEmail] = useState("");
    const [adress, setAdress] = useState("");
    const [startDay, setStartDay] = useState("");
    const startDate = dayjs(startDay, "DD.MM.YYYY");
    const [endDay, setEndDay] = useState("");
    const [birthDay, setBirthDay] = useState("");
    const [insuranceType, setInsuranceType] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [bankLoading, setBankLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [cashPaymentMethod, setCashPaymentMethod] = useState(false);
    const [cashInTerminalPaymentMethod, setCashInTerminalPaymentMethod] = useState(false);
    const [bankCardPaymentMethod, setBankCardPaymentMethod] = useState(false);
    const [language, setLanguage] = useState('ro'); 
    const [currency, setCurrency] = useState('498'); 
    const [status, setStatus] = useState(''); 
    const [transactionStatus, setTransactionStatus] = useState('');



  useEffect(() => {
    const fetchData = async () => {
      try {
        const doc = await db.collection("rcarequests2").doc(id).get();
        setData(doc.data());
        setIdnx(doc.data().idnx);
        setPeriod(doc.data().period);
        setPrice(doc.data().price);
        setDeliveryPrice(doc.data().deliveryPrice);
        setAmount(doc.data().amount);
        setPassNumber(doc.data().passNumber);
        setName(doc.data().name);
        setZone(doc.data().zone);
        setZone(doc.data().zone);
        setInsuranceType(doc.data().insuranceType);
        setPhoneNumber(doc.data().phoneNumber);
        setDeliveryMethod(doc.data().deliveryMethod);
        setEmail(doc.data().email);
        setAdress(doc.data().adress);
        setStartDay(doc.data().startDay);
        setEndDay(doc.data().endDay);
        setBirthDay(doc.data().endDay);
        setTransactionStatus(doc.data().transactionStatus);
        setLoading(false);
      } catch (error) {
        console.log(error);
        navigate('/');
      }
    };
    fetchData();
  }, [id]);


  const handlePaymentChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleConfirmPaymentMethod = async (paymentMethod) => {
    try {
      await db.collection("rcarequests2").doc(id).update({
        paymentMethod,
      });
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    }
  };    


  const handleCardPay = async (e) => { 
    e.preventDefault(); 
    setBankLoading(true);
    const clientIpAddress = await getUserIpAddress(); 
   
    // Convert the decimal amount to an integer without decimal places 
    const amountToSend = Math.round(amount * 100); 
   
    try { 
      const response = await axios.post('https://asig24.md/create-transaction', { 
        description: id, 
        language: language, 
        currency: currency, 
        amount: amountToSend, 
        clientIpAddress, 
      }); 
   
      const { transactionId } = response.data; 
   
      setStatus('Transaction created. Redirecting to the payment page...'); 
      window.location.href = `https://maib.ecommerce.md:443/ecomm01/ClientHandler?trans_id=${transactionId}`; 
    } catch (error) { 
      setStatus('Error creating transaction. Please try again.'); 
    } 
  };


return (
    <div>
        <Helmet>
                <title>{id}</title>
                <meta name="description" content="Calculează prețul și cumpără online asigurare medicală facultativă. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta name="keywords" content="asigurare medicală, asigurare facultativă, calculator asigurare medicală, cumpără asigurare medicală online, asigurări de sănătate, asigurări online" />
                <meta name="author" content="Asig24.md" />
                <meta property="og:title" content="Asigurare medicală facultativă" />
                <meta property="og:description" content="Calculează prețul și cumpără online asigurare medicală facultativă. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta property="og:url" content="https://www.asig24.md/medical-optional" />
                <meta property="og:type" content="website"></meta>
                <link rel="canonical" href="https://www.asig24.md/medical-optional" />
            </Helmet>           
        <Nav />
            <> {loading ? ( <div align="center" className="loading"><CircularProgress /></div> ) : ( <>

        <div className="parent">
            <div className="forma">


                <div className="orderdetails">
                    <div align="center">
                        <b>Vă mulțumim că ați ales Asig24.md
                        <br></br>
                        În scurt timp veți fi contactat de către operatorul nostru.</b><br></br><br></br>
                        <h1>Comanda nr. {id}</h1>
                        {transactionStatus !== "OK" && ( <p>Verificați detaliile comenzii dvs. și selectați metoda de plată.</p> )} 
						            {transactionStatus === "OK" && ( <div><CheckCircleOutlineIcon className='transactionStatusIcon' sx={{ fontSize: 40 }} /><h2>Achitat</h2></div> )}
                        <br></br><br></br>
                    </div>

                    
                    <div className="orderdetailsrow">Asigurat: <b>{name} - {idnx} {passNumber}</b></div>
                    <div className="orderdetailsrow">Data nașterii: <b>{birthDay}</b></div>
                    <div className="orderdetailsrow">Țara de reședință: <b>{zone}</b></div>
                    <div className="orderdetailsrow">Perioada asigurată: <b>{startDay} - {endDay}</b> {period === 90 && '(3 Luni)'}{period === 180 && '(6 Luni)'}{period === 360 && '(12 Luni)'}</div>
                    <div className="orderdetailsrow">Suma asigurată: <b>{insuranceType} MDL</b></div>
                    <div className="orderdetailsrow">Telefon de contact: <b>{phoneNumber}</b></div>
                    {deliveryMethod === "online" && email !== "" && ( <div className="orderdetailsrow">Email: <b>{email}</b></div> )}
                    {deliveryMethod === "deliveryOnAdress" && ( <div className="orderdetailsrow">Adresa de livrare: <b>{adress}</b></div> )}
                    {deliveryMethod === "fromOffice" && ( <div className="orderdetailsrow">Adresa de eliberare: <b>or. Chișinău, str. Miron Costin 14</b></div> )}
                    <Divider  />
                    <br></br>
                    <div className="orderdetailsrow">Prețul asigurării: <b>{price} Lei</b></div>
                    {deliveryPrice > 0 && (<div className="orderdetailsrow">Prețul livrării: <b>{deliveryPrice} Lei</b></div>)}
                    <Divider textAlign="right"><div className="orderdetailsrow"><b>TOTAL: {amount} LEI</b></div></Divider>
                </div>


                {transactionStatus !== "OK" && (
				<div>
                    <Accordion className="paymentMethod" expanded={expanded === 'panel1'} onChange={handlePaymentChange('panel1')}>
                        <AccordionSummary
                            onClick={() => {
                                setCashPaymentMethod(false);
                                setCashInTerminalPaymentMethod(false);
                                setBankCardPaymentMethod(true);
                                handleConfirmPaymentMethod("BankCard");
                            }}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            <img src={CardIcon} alt="Card bancar" height="65" />
                            </Typography>
                            <div  className="paymentMethod">
                                <div align="center"><b>Plată cu cardul bancar</b></div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider  />
                                <Typography>    
                                    Efectuați plăți cu cardul bancar de tip Visa, MasterCard, Maestro în siguranță maximă. Plățile sunt procesate de către MAIB.
                                </Typography>
								{transactionStatus !== "OK" && (
                            <div className="paybutton" align="center"> 
                                    <p style={{ fontSize: '13px' }}>
                                      Prin apăsarea butonului "Plătește" confirm că am citit, am înțeles și sunt de acord cu <a href="https://asig24.md/Termsofservices">Termenii și Condițiile</a>, <a href="https://asig24.md/Privacy">politica de confidențialitate</a>
                                    </p><br></br>
                                <Button onClick={handleCardPay} variant="contained" type="submit">Plătește</Button>
                            </div>)}
                            {bankLoading && (
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                            <CircularProgress />
                        </div>
                    )}
							
                        </AccordionDetails>
                    </Accordion>


                    <Typography component="div" style={{ height: '1rem' }} />

                    <Accordion className="paymentMethod" expanded={expanded === 'panel3'} onChange={handlePaymentChange('panel3')}>
                        <AccordionSummary
                            onClick={() => {
                                setCashPaymentMethod(false);
                                setCashInTerminalPaymentMethod(false);
                                setBankCardPaymentMethod(true);
                                handleConfirmPaymentMethod("Cash");
                            }}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            <img src={CashIcon} alt="Card bancar" height="65" />
                            </Typography>
                            <div  className="paymentMethod">
                                <b>Plată în numerar</b>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider  />
                            <Typography>    
                                Puteți selecta metoda de plată în numerar dacă optați pentru ridicarea asigurării dvs. din oficiu ori achitați la livrare.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div> 
				)}                            
                                 
                        
            </div>
        </div>
        <Footer /> 
        </> )} </>
    </div>

    
  );
}