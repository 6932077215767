import React from "react";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"


export default function Termsofservices() {
  return (
    <div>
        <Nav />
            <div className="parent">
                <div className="condText">
                    <h1>Termeni și condiții</h1>
                    <br></br>

<h3>Generalități</h3>
Pagina <b>www.asig24.md</b>, în continuare <b>asig24.md</b> este deținut și administrat de <b>B.A.R. “PRIMGROUP-ASIG” S.R.L.</b>, cod fiscal: 1012600036968. asig24.md reprezintă o platformă electronică unde sunt amplasate ofertele vânzătorilor parteneri de comerț a produselor de asigurare. 
<br/><br/>

<h3>Folosirea paginii și protejarea informației</h3>
Pagina asig24.md poate fi folosită doar de persoanele care dețin capacitate deplină de exercițiu, respectiv își exercită drepturile și își asumă obligațiile în mod direct, îndeplinind personal acte juridice.<br/><br/>
Folosirea paginii de internet este posibilă dacă în browser-ul de internet al Utilizatorului este activat serviciul javascript și se utilizează cookies pentru o comunicare „inteligentă” cu serverele asig24.md. Pagina de internet este protejată cu ajutorul conectorii cifrate folosind protocolul https. <br/><br/>
Transferul datelor personale precum și procesul de autorizare, dacă sunt utilizate, au loc cu ajutorul protocolului de securitate TLS-criptare 256 biți. <br/><br/>
B.A.R. “PRIMGROUP-ASIG” S.R.L. depune toate eforturile, pentru a asigura securitatea datelor trimise. <br/><br/>
Utilizatorul are posibilitatea de a refuza serviciul comandat în orice moment înainte de intrarea în vigoare a contractului de asigurare.
<br/><br/>

<h3>Aplicarea și acceptarea termenilor</h3>
Pagina asig24.md prezintă ofertele vânzătorilor parteneri a produselor de asigurare.<br/><br/>
Pagina, serviciile şi conținutul asig24.md vă sunt oferite condiționat prin acceptarea Dvs fără nici o modificare a condițiilor din prezentul contract. Dacă nu acceptați termenii şi condițiile stipulate în acest document, nu folosiți site-ul asig24.md şi serviciile oferite prin intermediul lui.
<br/><br/>

<h3>Descrierea serviciilor</h3>
asig24.md oferă utilizatorilor acces la o bază de date care conține oferte de prețuri la produsele de asigurare, oferă posibilitatea de a studia condițiile de asigurare a vânzătorilor parteneri. <br/><br/>
<b>Încheierea contractului de asigurare prin intermediul platformei asig24.md se va efectua în baza următoarelor:</b><br/>
-	Prezentului document „Termeni și condiții”,<br/>
-	Cererii de ofertă a Utilizatorului,<br/>
-	Ofertelor comunicate Utilizatorului, de către vânzătorii parteneri ai asig24.md<br/>
-	Condițiilor de asigurare ai vânzătorilor parteneri.<br/><br/>

După analizarea informațiilor conținute în prezentul document și optarea pentru un anumit produs de asigurare, utilizatorul are obligația de a analiza cu atenție și în totalitate cuprinsul condițiilor de asigurare:<br/>
-	Utilizatorul va completa (prin introducerea datelor) formularul pentru Cererea de ofertă prin alegerea opțiunii „Comanda”. În baza informațiilor oferite de Utilizator, sistemul informatic efectuează calculul primei de asigurare.<br/>
-	Prima de asigurare calculată prin intermediul calculatorului de asigurare de către utilizator, va fi verificată și confirmată de către persoana autorizata a vânzătorului partener asig24.md în decursul programului de lucru. Prima poate varia în dependență de corectitudinea datelor introduse de utilizator și de cursul valutar (pentru produsele a căror primă este calculată în valută).<br/><br/>

Dacă Utilizatorul este de acord cu valoarea primei de asigurare calculată de sistemul informatic, va avea acces la etapele ulterioare. Prin introducerea datelor necesare obținerii ofertei, Utilizatorul confirmă implicit următoarele:<br/>
-	este de acord cu termenii și condițiile conținute în prezentul document.<br/>
-	a luat cunoștință și este de acord cu condițiile de asigurare generale și specifice aferente tipului de asigurare ales.<br/>
-	datele și informațiile furnizate sunt reale la momentul introducerii datelor; utilizatorul trebuie să se asigure că datele introduse sunt corecte, având posibilitatea de a le modifica dacă observă erori.<br/>
-	își exprimă acordul pentru prelucrarea datelor sale cu caracter personal. Utilizatorul își poate exprima acordul pentru procesarea datelor personale pentru a fi folosite în activități de marketing. Această declarație nu este obligatorie pentru încheierea contractului de asigurare.<br/><br/>

Sistemul informatic analizează Cererea utilizatorului și va salva automat oferta de asigurare, ce include date detaliate despre contractul de asigurare precum și informații privind valoarea și modalitățile de plată a primei de asigurare.<br/><br/>
În cazul în care utilizatorul este de acord cu oferta companiei de asigurare sau a brokerului de asigurare, va alege modalitatea de plată a primei de asigurare. Prima de asigurare este achitată direct în contul companiei de asigurare sau a mandatarului acesteia.<br/><br/>
Atragem suplimentar atenția asupra importanței corectitudinii datelor de contact ale utilizatorului, dacă prima de asigurare a fost achitată online. Responsabilitatea introducerii datelor de contact precum: număr de telefon, email, aparține în totalitate utilizatorului site-ului.<br/><br/>
Încheierea contractului de asigurare electronic are loc la momentul intrării în contul bancar indicat, a primei de asigurare sau a primei rate, ori în momentul confirmării plății (pentru plata online). Polița de asigurare va intra în vigoare la data stabilită prin contract, dar nu mai devreme de data achitării primei de asigurare/ ratei 1 de prima și încasarea primei în contul companiei de asigurare.<br/><br/>
<b>Polițele de asigurare emise prin mijloace electronice:</b><br/>
<b>Medicală electronică</b><br/>
-	Este accesibilă Asiguratului numai în versiune electronica<br/>
-	După achitarea comenzii, timp de 10 minute polița este înregistrată.<br/>
-	În cazul în care după efectuarea cu succes a plății, polița nu a fost recepționată pe adresa de email/Viber/WhatsApp/Telegram indicat, utilizatorul este obligat să contacteze la nr. de telefon <b>+37361111101</b> sau email: <b>support@asig24.md</b><br/>
-	Polița de asigurare emisă on-line (prin intermediul mijloacelor electronice la distanță) intră sub incidența reglementărilor legale speciale în vigoare privind comercializarea prin mijloace electronice iar semnarea acesteia de către Asigurat/Contractant nu este necesară. B.A.R. “PRIMGROUP-ASIG” S.R.L remite Clientului contractul și polița de asigurare, ultima fiind semnată de vânzătorul partener. Clientul poate solicita de la B.A.R. “PRIMGROUP-ASIG” S.R.L contractul de asigurare cu semnătura olografă a vânzătorului partener și cu ștampila umedă aplicată. În acest caz, clientul va trebuie să se prezinte la adresa <b>mun. Chişinău, str. Miron Costin 14,</b> luni-vineri intre 09:00-18:00.<br/>
<br/>
<b>Produsele RCA și Carte Verde emise prin mijloace electronice</b><br/>
-	După achitarea produsului, cererea va fi procesată. Clientul va primi un mesaj de confirmare prin email, după care unul din managerii noștri va face legătura cu clientul pentru a confirma totul.<br/>
-	Verificarea valabilității poliței de asigurare poate fi efectuată pe site-ul oficial al Comisiei Naționale a Pieței Financiare <a href="https://rca.cnpf.md/" target="_blank">www.cnpf.md</a>.
<br/><br/>


<h3>Înregistrarea și achitarea comenzii</h3>

Achitarea comenzilor este posibilă cu cardul de plată – la primirea comenzii veți primi bonul fiscal ce va confirma plata. <br/><br/>
Plata online se face în condiții de siguranța maxima folosind ca instrument de plată cardul de plată, care permite efectuarea de tranzacții online. După efectuarea plății veți primi o confirmare de plata pe adresa dvs. de email.<br/><br/>
Procesatorul de plata folosește un sistem securizat, bazat pe ultima versiunea a standardului de securitate 3D-Secure ceea ce înseamnă o nouă abordare globală a autentificării cumpărătorilor în tranzacții sigure online. Aceasta măsura de siguranța presupune redirecționarea utilizatorului în momentul efectuării plații pe o pagina securizata maib unde autentificarea fiecărui deținător de card se face prin atribuirea unui cod de unică folosințe pentru fiecare tranzacție online.<br/><br/>
Rambursarea mijloacelor bănești se efectuează doar pe cardul de plată care a fost folosit pentru achiziție.<br/><br/>
Pentru a efectua o plată, va trebui să introduceți detaliile cardului dvs. bancar. Transferul acestor informații se face în conformitate cu toate măsurile de securitate necesare. Informațiile sunt transmise în formă criptată și sunt stocate numai pe un server specializat al sistemului de plăți.<br/><br/>

Pentru efectuarea plății vor fi solicitate următoarele date:<br/>
-	Numărul cardului (16 cifre)<br/>
-	Data expirării (luna și anul)<br/>
-	Codul CVC sau CVV (3 cifre)<br/>
-	Numele și prenumele de pe cardul de plată<br/>
Toate achitările se procesează in moneda naționala - MDL (lei moldovenești). În cazul În care valuta operațiu-nii diferă de valuta achitării, convertirea sumei operațiunii se efectuează conform cursului la operațiunile cu carduri din ziua decontării, al băncii emitente.
<br/><br/>

<h3>Rezilierea contractului de asigurare.</h3>
Rezilierea poate fi efectuată doar până la intrarea în vigoare a contractului, în caz contrar, rezilierea poate avea loc doar la sediul central al companiei de asigurare şi doar în condițiile prevăzute de legislația în vigoare. Cadrul normativ-juridic al poliței de asigurare emisă on-line (prin intermediul mijloacelor electronice la distanță) este identic asigurării medicale clasice, constituie legislația în vigoare a Republicii Moldova cu privire la sistemul asigurărilor ( Legea nr. 407 cu privire la asigurări), Codul Civil și condițiile de asigurare generale și specifice aferente tipului de asigurare ales. De asemenea, regimul online al acestui produs este reglementat de Legea 284 din 22.07.2004 cu privind comerțul electronic, după puterea juridică, contractul electronic se echivalează cu contractul întocmit în formă scrisă, semnat de părți și autentificat cu ştampilele părților.
<br/><br/>
Conform legislaţiei în vigoare (Legea nr. 414-XVI din 22.12.2006) şi potrivit pct. 8.3.3 din Anexa nr. 3 și pct. 8.2.2 din Anexa nr. 4 la Hotărârea CNPF nr. 20/5 din 16.05.2008 se interzice anularea/rezilierea contractului de asigurare de răspundere civilă auto externă eliberat pe termenul de 15 zile sau 1 lună odată cu intrarea în vigoare a termenului de valabilitate a acestuia, în special în prima zi de valabilitate, fără prezentarea documentelor oficiale confirmative (interdicția depășirii frontierei de stat, defectarea autovehiculului, anularea călătoriei din diferite motive justificate (boală, deces a membrului familiei)).
<br/><br/>

<h3>Protecția datelor cu caracter personal.</h3>
-	Prelucrarea datelor cu caracter personal se efectuează cu respectarea prevederilor legale din Republica Moldova.<br/>
-	În conformitate cu dispozițiile LEGII Nr. 133 din 08.07.2011 privind protecția datelor cu caracter personal, B.A.R. “PRIMGROUP-ASIG” S.R.L are obligația de a administra în condiții de siguranță datele personale care îi sunt furnizate și ne obligăm să păstrăm confidențialitatea datelor persoanelor furnizate prin intermediul site-ului asig24.md, aplicații de mesagerie instantă, telefon, așa cum prevăd dispozițiile legislației cu modificările ulterioare privind protecția datelor personale.
<br/><br/>

<h3>Reclamație.</h3>
Vă rugăm să ne adresați orice nemulțumire în legătură cu funcționarea acestui site, apelând la numărul de telefon +37361111101 sau adresându-vă în scris la adresa de email support@asig24.md  sau la adresa oficiului din mun. Chişinău, str. Miron Costin, 14
<br/><br/>

<h3>Dreptul la proprietate intelectuală.</h3>
Dreptul de autor pentru informațiile și materialele incluse în acest site sunt proprietatea B.A.R. “PRIMGROUP-ASIG” S.R.L. Este interzisă folosirea de către terți a informațiilor conținute în acest site fără a avea acordul prealabil al companiei B.A.R. “PRIMGROUP-ASIG” S.R.L.
<br/><br/>

<h3>Exonerarea de răspundere.</h3>
asig24.md nu poartă răspundere pentru prejudiciile, conflictele/disputele dintre utilizatorii site-ului și vânzătorii parteneri. În cazul în care utilizatorul a introdus datele eronat, pentru modificarea/corectarea lor, este necesar ca utilizatorul să se apropie la sediul și să solicite înlăturarea erorilor. Persoana autorizata a vânzătorului partener va efectua modificările necesare, dacă aceasta este posibil și dacă eroarea nu intră în contradicție cu condițiile punctului „Rezilierea contractului de asigurare”. asig24.md nu va livra suplimentar polița de asigurare dacă eroarea a fost produsă de utilizatorul site-ului.
<br/><br/>

<h3>Modificarea informației din prezentul document</h3>
Prin utilizarea site-ului, indicați că acceptați și respectați “Termenii şi condițiile“ noastre. asig24.md poate revizui acest document periodic, prin actualizarea acestei pagini. Vă recomandăm să vizitați această pagină periodic pentru a citi “Termenii şi condițiile”, deoarece acest document vă obligă legal. Folosirea în continuare a serviciilor site-ului asig24.md presupune acceptarea modificărilor aduse prezentului contract.



                </div>
            </div>
      

      <Footer />
    </div>
  );
}