import React from "react";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"

export default function Privacy() {
  return (
    <div>
      <Nav />
      
      <div className="parent">
                <div className="condText">
                <h1>Declarație pe propria răspundere</h1>
                    <br></br>
                    <h3>Declarație pe propria răspundere – Carte Verde</h3>
                    Prin prezenta, declar că am luat la cunoştinţă că, în temeiul legislaţiei în vigoare (Legea nr. 414-XVI din 22.12.2006) şi potrivit pct. 8.3.3 din Anexa nr. 3 și pct. 8.2.2 din Anexa nr. 4 la Hotărârea CNPF nr. 20/5 din 16.05.2008 se interzice <b>anularea/rezilierea</b> contractului de asigurare de răspundere civilă auto externă eliberat pe termenul de 15 zile sau 1 lună odată cu intrarea în vigoare a termenului de valabilitate a acestuia, în special în prima zi de valabilitate, fără prezentarea documentelor oficiale confirmative (interdicția depășirii frontierei de stat, defectarea autovehiculului, anularea călătoriei din diferite motive justificate (boală, deces a membrului familiei)).
                    <br/><br/>
                    <b>Am fost informat şi accept condiţiile de asigurare că în caz de solicitare a rezilierii contractului RCAE (Carte Verde) nu voi avea dreptul de a rezilia contractul şi de a mi se returna prima de asigurare achitată, cât şi nu voi înainta careva pretenţii faţă de asigurător referitor la acest contract.
                    </b>
</div>
            </div>

      <Footer /> 
    </div>
  );
}