import React from "react";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"

export default function Termsofservices() {
  return (
    <div>
      <Nav />
      <div className="parent">
                <div className="condText">
                <h1>Condiții de Livrare</h1>
                    <br></br>

                    Pentru a asigura o experiență plăcută și eficientă, vă prezentăm condițiile de livrare pentru comenzile efectuate pe platforma noastră.
                    <br/><br/>

<h3>Costul livrării:</h3>
Pentru serviciile și produsele cu prețul mai mic sau egal cu 500 lei, tariful pentru livrare este de 59 lei.<br/>
Livrarea este gratuită pentru produsele și serviciile cu prețul mai mare de 500 lei.
<br/><br/>

<h3>Termenul de livrare:</h3>
Livrarea se efectuează în termen de 24 de ore de la momentul efectuării plății, în intervalul orar 09:00 - 18:00.
Comenzile plasate și achitate în zilele de vineri, sâmbătă și duminică vor fi livrate începând cu ziua de luni.
<br/><br/>

<h3>Zona de livrare:</h3>
Livrarea se efectuează doar pe teritoriul municipiului Chișinău și suburbiile acestuia.
<br/><br/>

Vă rugăm să rețineți că livrarea serviciilor și produselor de asigurare se va realiza în conformitate cu aceste condiții de livrare. Ne rezervăm dreptul de a modifica aceste condiții fără notificare prealabilă. Vă recomandăm să verificați această pagină periodic pentru a vă asigura că sunteți la curent cu cele mai recente modificări.




</div>
            </div>
      <Footer />
    </div>
  );
}