import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import "./FAQ.css"
import { Link } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    
    <div className='accordion-container'>
<div className='faq-title' align="center"><h2>Întrebări frecvente și răspunsuri.</h2></div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography><b>Cine suntem și unde ne aflăm?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Suntem Broker de asigurare BAR "PrimgroupAsig" S.R.L., IDNO: 1012600036968 și ne puteți găsi pe adresa: mun. Chișinău, str. Miron Costin 14
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography><b>Cum pot achita o comandă?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Orice comandă poate fi achitată online cu cardul bancar, la terminale de plată, ghișeu bancar sau la poștă prin intermediul mPay ori în numerar la livrare.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography><b>Este valabilă asigurarea în format electronic?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Da. Asigurările în format electronic sunt valide și legale. Valabilitatea RCA și Carte Verde poate fi verificată pe  <a href="https://rca.cnpf.md/" target="_blank">www.cnpf.md</a> <br></br>
Polița și contractul de asigurare sunt semnate electronic, valabilitatea semnăturii poate fi verificată pe  <a href="https://msign.gov.md/" target="_blank">www.msign.gov.md</a>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography><b>Am achitat, cum primesc asigurarea?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Imediat ce plata este confirmată, operatorul nostru va perfecta polița de asigurare și o va expedia pe e-mailul/Viber indicat de către Dvs. Dacă ați optat pentru livrarea asigurării, atunci aceasta va fi livrată conform <Link to="../Termsofdelivery">condițiilor de livrare.</Link>
          </Typography>
        </AccordionDetails>
      </Accordion>


    </div>
  );
}