import React, { useState, useEffect } from "react";
import "./Blog.css";
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

firebase.initializeApp({
  apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
  authDomain: "masig-online.firebaseapp.com",
  projectId: "masig-online",
  storageBucket: "masig-online.appspot.com",
  messagingSenderId: "1064560601004",
  appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
  measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const generateDocId = () => {
  // Generate 5 random digits
  const randomDigits = Math.floor(1000000 + Math.random() * 9999999);

  // Generate 3 random letters
  const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
  const randomLetters = letters.charAt(Math.floor(Math.random() * letters.length)) +
                          letters.charAt(Math.floor(Math.random() * letters.length)) + 
                          letters.charAt(Math.floor(Math.random() * letters.length));

  // Combine digits and letters to form docId
  const docId = "CTA" + randomDigits + randomLetters;

  return docId;
};


export default function Blog() {  
  const [name, setName] = useState(""); 
  const [phoneNumber, setPhoneNumber] = useState(""); 
  const [productType, setProductType] = useState(""); 
  const [submitted, setSubmitted] = useState(false);
  const [uid, setUid] = useState("");

  const date = new Date();
    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  

  useEffect(() => {
    auth.signInAnonymously()
      .then((userCredential) => {
        // Store the user's UID locally
        setUid(userCredential.user?.uid);
      })
      .catch((error) => {
        console.log(error);
      });
}, []); 

const handleSubmit = async (event) => {
  event.preventDefault();
  const docId = generateDocId();
        
  await db.collection("rcarequests2").doc(docId).set({
    name: name,
    phoneNumber: phoneNumber,
    product_type: productType,
    datetime: formattedDate,
    uid: auth.currentUser.uid,
  })
  .then((docRef) => {
    console.log("Document written with ID: ", docId);
    localStorage.setItem("docId", docId);
    localStorage.setItem("uid", uid);
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
  });
  setSubmitted(true);


  try {
    const response = await fetch('https://asig.cloud/send-cta', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ docId, name, phoneNumber, productType }),
    });

    if (response.ok) {
      // Handle successful submission
    } else {
      // Handle error
    }
  } catch (error) {
    // Handle fetch error
  }


};

  return (     
    <div className="cta-form-container">
      {submitted ? (
        <div className="ctatext">
          <h1>Solicitarea dumnevoastră a fost recepționată, vă mulțumim.</h1>
          <p>În scurt timp veți fi contactat de către un reprezentant Asig24.md</p>
        </div>
      ) : (
      <form className="cta-form" onSubmit={handleSubmit}>
        <div className="ctatext">
          <h1>Nu ai timp pentru a crea comandă online?</h1>
          <p>Lasă un număr de telefon și te vom contacta în 5 minute, ori sună acum: <b>061 111 101</b></p>
        </div>
        <div className="input-row">
          <div className="divinpunt">
            <TextField
              required
              label="Nume Prenume"
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
              inputProps={{ maxLength: 50 }}
              placeholder="Nume Prenume"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="divinpunt">
            <TextField
              required
              label="Numar de telefon"
              value={phoneNumber || ''}
              onChange={(e) => setPhoneNumber(e.target.value)}
              inputProps={{
                minLength: 8,
                maxLength: 15,
                pattern: '^\\+?[0-9]{7,14}$',
                type: 'tel',
                inputMode: 'tel',
              }}
              placeholder="+373 69******"
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="divinpunt">
            <FormControl fullWidth>
              <InputLabel>Tip asigurare</InputLabel>
              <Select required label="Tip asigurare"
              value={productType || ''}
              onChange={(e) => setProductType(e.target.value)}>
                <MenuItem value="RCA">Asigurare RCA</MenuItem>
                <MenuItem value="Green Card">Cartea Verde</MenuItem>
                <MenuItem value="CASCO">CASCO</MenuItem>
                <MenuItem value="Medical">Medicală pentru călătorii</MenuItem>
                <MenuItem value="Medicala Facultativa">Medicală facultativă</MenuItem>
                <MenuItem value="Ipoteca">Ipoteca</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <button type="submit" className="cta-button">Solicită apel</button>
        </form>
      )}
    </div>
  );
}